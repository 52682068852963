import React from "react";
import { useTranslation } from 'react-i18next';



export const GameReconnectingModal: React.FunctionComponent<{ text: string }> = ({ text }) => {
    const { t } = useTranslation();
    const Css = `
        #myModal{
            /*max-width:600px;*/
            width:100%;
            position:absolute;
            height:100%
        }
        .modal-image{
            overflow:hidden;
            background-color: rgba(0, 0, 0, 0.5)
        }
        `
    return <>
        <style>{Css}</style>
        <div id="myModal" className="modal-image" style={{ zIndex: 100 }}>
            <div className="d-flex align-items-center justify-content-center flex-column h-100">
                <img src="/images/game_loader.gif" height="100px" onError={(e: any) => { e.target.style.display = 'none'; }} />
                <div className="text-center text-white font-weight-bold">
                    {t(text)}
                </div>
            </div>
        </div>
    </>
}