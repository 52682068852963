import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { setCookie } from '@Services/Cookie';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { useTranslation } from "react-i18next";
import { PersistActions } from '@Actions/PersistActions';

export const ServiceWorkerPrompt: React.FunctionComponent<{ showPrompt: (a: boolean) => void, onSuccess: (closePrompt?: boolean) => void }>
    = ({ showPrompt, onSuccess }) => {
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const [installing, setInstalling] = useState(false);
        const [isIos, setIsIos] = useState(false);

        const onInstallSuccess = (config: any, closePrompt?: boolean) => {
            setInstalling(false);
            onSuccess(closePrompt);
        }

        const installServiceWorker = () => {
            setInstalling(true);
            serviceWorkerRegistration.register({
                onSuccess: onInstallSuccess,
                closePrompt: true
            });
        }
        useEffect(() => {
            if (["iPad Simulator, iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform)) {
                setIsIos(true);
                serviceWorkerRegistration.register({
                    onSuccess: onInstallSuccess,
                    closePrompt: false
                });
            }
            else {
                setIsIos(false);
                // serviceWorkerRegistration.register({
                //     onSuccess: onInstallSuccess
                // });
            }

        }, []);
        return <div className="position-absolute pwa-width bg-white d-flex flex-column justify-content-between px-3 py-3"
            style={{
                top: "0%",
                left: "0",
                right: "0",
                bottom: "0",
                height: isIos ? "310px" : "120px", zIndex: 4,
                margin: "auto",
                borderRadius: "28px"
            }}>
            {isIos ?
                <>
                    <div className="d-flex justify-content-center align-items-center flex-column">

                        <img src="/images/icon/favicon.png" style={{ width: '75px', position: "absolute", top: "-25px", borderRadius: "24px", backgroundColor: "White", backgroundRepeat: "no-repeat" }} />
                        <h3 className="mt-5 mb-0" style={{ color: "#A4D007", fontWeight: "bold", fontStyle: "normal", fontSize: "36px" }}>{t("ADD_ICON_TO")}</h3>
                        <h3 style={{ color: "#A4D007", fontWeight: "bold", fontStyle: "normal", fontSize: "36px" }}>{t("HOME_SCREEN")}</h3>

                        <div  >
                            <img src="/images/installIcon.png" style={{ width: '32px' }} />
                            <span style={{ fontWeight: "bold", fontStyle: "normal", color: " #818181", verticalAlign: "bottom", fontSize: "24px" }}> &nbsp;&nbsp;&nbsp; {t("TAP_THE_ICON")}</span>
                        </div>
                        <div style={{ color: "#818181", marginTop: "10px", fontSize: "20px" }}>
                            {t("SELECT_ADD")} <span style={{ fontWeight: "bold" }}>{t("SELECT_ADD_TO_HOME_SCREEN")}</span>
                        </div>
                        <div style={{ color: "#818181", marginTop: "10px", fontSize: "20px" }}>
                            {t("FROM_POP_UP_MENU")}
                        </div>
                        <button className="py-1 px-3 border-0 mt-2" style={{ borderRadius: "27px", backgroundColor: "#EFEFEF" }} onClick={() => {
                            showPrompt(false);
                            // setCookie("install-pwa", "0");
                            // setCookie("install-pwa", "0");
                            dispatch(PersistActions.SetInstallPWAModal(false));

                        }}>
                            {t("CLOSE")}
                        </button>
                    </div>
                </>
                :
                <>
                    <div className="position-absolute" style={{
                        backgroundImage: "url('/images/white_round_bg.png')",
                        top: "-40px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: '75px',
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",

                    }}>
                        <img src="/images/launcher_icon.png" style={{ width: '75px', borderRadius: "50%" }} />
                    </div>
                    <div style={{ textAlign: "center", fontWeight: "bold", margin: "20px 0px", color: "#818181" }}>{t("WOULD_YOU_LIKE_TO_INSTALL_THIS_APP")}</div>
                    <div className="d-flex justify-content-center align-items-center">
                        <button style={{ opacity: installing ? 0.8 : 1, borderRadius: "27px", backgroundColor: "#A4D007" }} className="mr-1 py-1 px-3 d-flex justify-content-between align-items-center text-white mr-2 border-0"
                            onClick={() => { installServiceWorker() }}>
                            {/* .loader-small need to be a style component */}
                            {t("YES")} {installing && <div className="ml-1 loader-small"></div>}
                        </button>
                        <button className="py-1 px-3 border-0" style={{ borderRadius: "27px", backgroundColor: "#EFEFEF" }} onClick={() => {
                            showPrompt(false);
                            // setCookie("install-pwa", "0");
                            dispatch(PersistActions.SetInstallPWAModal(false));
                        }}>
                            {t("NO")}
                        </button>
                    </div>
                </>}
        </div>
    }