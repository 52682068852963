// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import {GAME_CONFIG, GAME_ITEM, MATCH_MAKING_TEMPLATE} from "@Interfaces";
// #endregion Interface Imports


export const MultiGamePlayAction = {
    SetSelectedGameConfig: (payload: GAME_CONFIG[]) => async (
        dispatch: Dispatch
    ) => {
        // await dispatch(InviteActions.CreateRoom({ userId1: user._id, userId2: suggestion._id }));

        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetSelectedGameConfig
        })
    },
    SetSelectedGameToPlay: (payload: GAME_ITEM | {}) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetSelectedGameToPlay
        })
    },
    SetSpecificSelectedConfiguration: (payload: MATCH_MAKING_TEMPLATE) => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetSpecificSelectedConfiguration
        })
    },
    SetMatchObject: (payload: any) => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetMatchObject
        })
    },
    SetIsHost: (payload: boolean) => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetIsHost
        })
    },
    SetShowGameTypeView: (payload: boolean) => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.MultiPlayerGame.SetShowGameTypeView
        })
    },
    ResetGameConfigAndMatchObject: () => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            type: ActionConsts.MultiPlayerGame.ResetGameConfigAndMatchObject
        })
    }
}