import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IStore } from "@Redux/IStore";
import { StaticImage } from "@Styled";
import { USER_SESSION } from "@Interfaces";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { MessagesActions } from "@Actions/Messages";
import { AuthenticationActions } from "@Actions/Authentication";
import { isPaidContentAllowed } from "@HelperFunctions";
import { AccessResponseStatus } from "@Constants";
import chatManager from "@Services/ChatManager/ChatManager";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

export const FooterContainer: React.FunctionComponent<{
  selected: string;
  setShowLowBalanceModal: (a: boolean) => void;
  user: USER_SESSION;
  setShowNoNameModal: (a: boolean) => void;
  userHasNoName: boolean;
  setShowPaywall: (a: boolean) => void;
}> = ({
  selected,
  user,
  setShowLowBalanceModal,
  setShowNoNameModal,
  userHasNoName,
  setShowPaywall,
}) => {
  const messagesState = useSelector((state: IStore) => state.messages);
  const homeState = useSelector((state: IStore) => state.home);
  const { showPaywall, session, persistFirebaseConfiguration } = useSelector(
    (state: IStore) => state.persistState
  );
  const { newFriendRequest } = useSelector((state: IStore) => state.messages);
  // const { userHasLowBalance } = homeState;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { accessAllowed, accessBlockReason } = isPaidContentAllowed(user);
  const unreadMessage = chatManager.showBadgeOnAnyUnreadMessage();
  const [newRequest, setNewRequest] = useState(false);
  const [firebaseFriendIds, setFirebaseFriendIds] = useState<string[]>([]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between w-100 px-4 py-1"
        style={{
          height: "65px",
          minHeight: "65px",
          maxHeight: "65px",
          background: "#320664",
        }}
      >
        {FooterConfig.map((config, i) => {
          return (
            <Link
              onClick={(e) => {
                if (!session._id && config.name !== "Home") {
                  e.preventDefault();
                  history.push("/login");
                  return;
                }

                if (config.name === "Profile") {
                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.PROFILE_VIEW);
                } else if (config.name === "Messages") {
                  if (userHasNoName) {
                    setShowNoNameModal(true);
                    return;
                  }

                  if (accessAllowed) {
                    dispatch(MessagesActions.UpdateUnseenMessageInFotter(0));
                  } else if (
                    accessBlockReason === AccessResponseStatus.LOW_BALANCE
                  ) {
                    e.preventDefault();
                    setShowLowBalanceModal(true);
                    return;
                  } else if (
                    accessBlockReason === AccessResponseStatus.NO_SUBSCRIPTION
                  ) {
                    setShowPaywall(true);
                    return;
                  }
                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.USE_CHAT);
                } else if (config.name === "Home") {
                  // Analytics
                  localStorage.removeItem("fromChat");
                  SendAnalyticsToFirebase(AnalyticsEventsName.TAB_HOME_PRESSED);
                } else if (config.name === "LeaderBoard") {
                  SendAnalyticsToFirebase(
                    AnalyticsEventsName.TAB_LEADER_BOARD_PRESSED
                  );
                } else if (config.name === "TournamentHome") {
                  localStorage.removeItem("fromChat");
                  SendAnalyticsToFirebase(
                    AnalyticsEventsName.TAB_TOURNAMENTS_PRESSED
                  );
                }
              }}
              to={config.routeName}
              key={i}
              className={`text-center menuItemLink position-relative`}
              style={{ width: `${100 / FooterConfig?.length}%` }}
            >
              {selected === config.name && (
                <div
                  className={styles.menu__border}
                  // style={{
                  //     left: config.name === 'Home' ? '-80%' : config.name === 'Messages' ? '-170%' : config.name === 'TournamentHome' ? '-45%' : config.name === 'LeaderBoard' ? '-180%' : config.name === 'Profile' ? '-128%' : '0',
                  //     width: config.name === 'Home' || config.name === 'Profile' ? '100px' : ''
                  // }}
                >
                  <svg viewBox="0 0 202.9 45.5">
                    <clipPath
                      id="menu"
                      clipPathUnits="objectBoundingBox"
                      transform="scale(0.0049285362247413 0.021978021978022)"
                    >
                      <path
                        d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
                                c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
                                c9.2,3.6,17.6,4.2,23.3,4H6.7z"
                      />
                    </clipPath>
                  </svg>
                </div>
              )}

              {/*{messagesState.unseenMessagesCount !== 0 && config.name === 'Messages' && <div className="position-absolute"*/}
              <div
                style={{
                  transform:
                    selected === config.name ? "translate3d(0, -.3em , 0)" : "",
                  transition: "transform 0.7s",
                  position: "inherit",
                }}
              >
                <StaticImage
                  className="position-relative"
                  src={
                    selected === config.name
                      ? config.imageSelected.src
                      : config.image.src
                  }
                  height={
                    selected === config.name
                      ? config.imageSelected.height
                      : config.image.height
                  }
                  width={
                    selected === config.name
                      ? config.imageSelected.width
                      : config.image.width
                  }
                />
                <div
                  className="mt-2 font-11px titillium-semibold"
                  style={{
                    color: selected === config.name ? "white" : "#B094D4",
                  }}
                >
                  {t(config.tabName)}
                </div>
                {(unreadMessage || newFriendRequest) &&
                  config.name === "Messages" && (
                    <div
                      className={`${styles.chat_notif} position-absolute`}
                      style={{
                        top: "-5px",
                        height: "15px",
                        width: "15px",
                        background: "red",
                        borderRadius: "50px",
                      }}
                    ></div>
                  )}
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

const FooterConfig = [
  {
    name: "Home",
    routeName: "/",
    image: {
      src: "/images/Ico_Home_UnSelected.png",
      height: "22px",
      width: "36px",
    },
    imageSelected: {
      src: "/images/ico_Home@2x.png",
      height: "22px",
      width: "36px",
    },
    tabName: "GAME",
  },
  {
    name: "Messages",
    routeName: "/messages",
    image: {
      src: "/images/ico_Chat@2x.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/Ico_Chat_Selected.png",
      height: "22px",
      width: "22px",
    },
    tabName: "CHAT",
  },
  {
    name: "TournamentHome",
    routeName: "/tournament",
    image: {
      src: "/images/tournament.png",
      height: "23px",
      width: "23px",
    },
    imageSelected: {
      src: "/images/tournament-selected.png",
      height: "23px",
      width: "23px",
    },
    tabName: "TOURNAMENT",
  },
  {
    name: "LeaderBoard",
    routeName: "/leaderboard",
    image: {
      src: "/images/leaderboard.png",
      height: "26px",
      width: "17px",
    },
    imageSelected: {
      src: "/images/leaderboard-selected.png",
      height: "26px",
      width: "17px",
    },
    tabName: "RANK",
  },
  {
    name: "Profile",
    routeName: "/profile",
    image: {
      src: "/images/ico_Friends@2x.png",
      height: "24px",
      width: "23px",
    },
    imageSelected: {
      src: "/images/Ico_Friends_white.png",
      height: "24px",
      width: "23px",
    },
    tabName: "PROFILE",
  },
];
