// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import { InviteModel } from "@Interfaces";
import { getQueryParams } from "@Services/Cookie";
import { userErrorDummyResponse } from "@Constants";
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;
const apiBaseUrlV4 = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL_V4 : process.env.REACT_APP_STAGING_API_URL_V4;

export const InviteService = {
    SearchUser: async (
        payload: InviteModel.GetGETSearchUserPayload
    ): Promise<InviteModel.GetGETSearchUserResponse> => {
        let response: InviteModel.GetGETSearchUserResponse;

        const { searchStr, userId } = payload;
        try {
            response = await Http.Request<InviteModel.GetGETSearchUserResponse>(
              "GET",
              apiBaseUrl + "/friends/search",
              {
                  searchString: searchStr,
                  userId: userId
              }
            );
        } catch (error) {
            response = {
                success: false,
                message: "NO",
                response: [userErrorDummyResponse]
            }
        }
        return response;
    },
    CreateRoom: async (
        payload: InviteModel.GetGETCreateRoomPayload
    ): Promise<InviteModel.GetGETCreateRoomResponse> => {
        let response: InviteModel.GetGETCreateRoomResponse;

        const { userId1, userId2 } = payload;
        try {
            response = await Http.Request<InviteModel.GetGETCreateRoomResponse>(
                "GET",
                apiBaseUrlV4 + "/user/createRoom/" + userId1 +
                '/' +
                userId2,
                undefined
            );
        } catch (error) {
            response = {
                success: false,
                message: "NO",
                response: {
                    createdAt: "",
                    opponent: userErrorDummyResponse,
                    users: [],
                    __v: "",
                    _id: ""
                },
            }
        }
        return response;
    },
    UpdateFacebookFriends: async (
        payload: InviteModel.GetPOSTFacebookFriendsPayload
    ): Promise<InviteModel.GetPOSTFacebookFriendsResponse> => {
        let response: InviteModel.GetPOSTFacebookFriendsResponse;
        // apiBaseUrl + "/friends",
        try {
            response = await Http.Request<InviteModel.GetPOSTFacebookFriendsResponse>(
                "POST",
                apiBaseUrlV4 + "/getUsersBySocialIds",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                success: false,
                message: "YES",
                response: []
            }
        }
        return response;
    },
    // GetFacebookFriends: async (
    //     payload: InviteModel.GetGETFacebookFriendsPayload
    // ): Promise<InviteModel.GetGETFacebookFriendsResponse> => {
    //     let response: InviteModel.GetGETFacebookFriendsResponse;

    //     try {
    //         response = await Http.Request<InviteModel.GetGETFacebookFriendsResponse>(
    //             "GET",
    //             apiBaseUrl + "/friends/" + payload.userId + "/facebook/1/50",
    //             undefined
    //         );
    //     } catch (error) {
    //         response = {
    //             success: false,
    //             message: "YES",
    //             response: []
    //         }
    //     }
    //     return response;
    // },
    SendMatchInvite: async (
      payload: InviteModel.GetPOSTMatchInvitePayload
    ): Promise<InviteModel.GetPOSTSendMatchInviteResponse> => {
        let response: InviteModel.GetPOSTSendMatchInviteResponse;
        try {
            response = await Http.Request<InviteModel.GetPOSTSendMatchInviteResponse>(
              "POST",
              apiBaseUrlV4 + "/game/invite",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                message: "",
                response: {}
            }
        }
        return response;
    },
    AcceptMatchInvite: async (
      payload: InviteModel.GetPOSTAcceptRejectMatchInvitePayload
    ): Promise<InviteModel.GetPOSTAcceptRejectMatchInviteResponse> => {
        let response: InviteModel.GetPOSTAcceptRejectMatchInviteResponse;
        try {
            response = await Http.Request<InviteModel.GetPOSTAcceptRejectMatchInviteResponse>(
              "POST",
              apiBaseUrlV4 + "/game/acceptGameInvite",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                message: "",
                response: {}
            }
        }
        return response;
    },
    RejectMatchInvite: async (
      payload: InviteModel.GetPOSTAcceptRejectMatchInvitePayload
    ): Promise<InviteModel.GetPOSTAcceptRejectMatchInviteResponse> => {
        let response: InviteModel.GetPOSTAcceptRejectMatchInviteResponse;
        try {
            response = await Http.Request<InviteModel.GetPOSTAcceptRejectMatchInviteResponse>(
              "POST",
              apiBaseUrlV4 + "/game/rejectGameInvite",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                message: "",
                response: {}
            }
        }
        return response;
    },
    UpdateGameBoardView: async (
      payload: InviteModel.GetPOSTUpdateGameBoardViewPayload
    ): Promise<InviteModel.GetPOSTAcceptRejectMatchInviteResponse> => {
        let response: InviteModel.GetPOSTAcceptRejectMatchInviteResponse;
        try {
            response = await Http.Request<InviteModel.GetPOSTAcceptRejectMatchInviteResponse>(
              "POST",
              apiBaseUrlV4 + "/match/updateSettings",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                message: "",
                response: {}
            }
        }
        return response;
    }
}