// #region Global Imports
import React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { IMessageEditorInput } from "./MessageEditorInput";
// #endregion Local Imports

const _Input = styled.input<IMessageEditorInput.IProps>`
    border-radius: ${({ borderRadius }) => { return borderRadius ? borderRadius : "100px" }};
    width: 100%;
    background: ${({ theme, backgroundColor }) => { return backgroundColor ? backgroundColor : theme.colors.grey600 }};
    border: ${({ theme, border}) => { return "1px solid " + border ? border : theme.colors.grey700 }};
    padding: ${({ padding }) => { return padding ? padding : "10px 10px 10px 55px" }};
    outline: none;
    height: ${({ height }) => { return height ? height : "initial" }};
    &:focus {
        outline: none !important;
    };
    &::placeholder {
        color: ${({placeholderColor}) => {return placeholderColor && placeholderColor}};
        opacity: 1; /* Firefox */
    };
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${({placeholderColor}) => {return placeholderColor && placeholderColor}};
    };
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${({placeholderColor}) => {return placeholderColor && placeholderColor}};
    }
`;

export const MessageEditorInput: React.FunctionComponent<IMessageEditorInput.IProps> = props => {
    return <_Input {...props} disabled={props.disabled} />;
};
