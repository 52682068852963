// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { ConversationService } from "@Services/API/Conversation";
// #endregion Local Imports

// #region Interface Imports
import { IConversationPage, UploadMediaFilesModel, USER_SESSION } from "@Interfaces";
// #endregion Interface Imports


export const ConversationActions = {
    GetConversation: (payload: IConversationPage.Actions.IGetGETConversationPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.GetConversation(payload);

        dispatch({
            payload: { chatMessages: result.response && result.status ? result.response : [], page: payload.page },
            type: result.response && result.status ? ActionConsts.Conversation.GetConversationSuccess : ActionConsts.Conversation.GetConversationError
        });
    },
    SetConversation: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.SetConversationSuccess
        });
    },
    updateLastMessageConversation: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.UpdateConversationSuccess
        });
    },
    UploadMediaOnStorage: (payload: UploadMediaFilesModel.GetUploadMediaFilesPayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.UploadMediaOnStorage(payload);
        if (result.success && result.response) {
            return result.response;
        } else {
            return "";
        }
    },
    FetchEmojis: () => async (
        dispatch: Dispatch
    ) => {
        const result = await ConversationService.FetchEmojis();
        return result;
    },
    ClearConversation: () => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: null,
            type: ActionConsts.Conversation.ClearConversation
        });
    },
    SetUserFirebaseConnectivityStatus: (payload: boolean) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.UserFirebaseConnectivityStatus
        });
    },
    SendFirebaseOfflineNotification: (payload: IConversationPage.Actions.IGetPOSTSendFirebaseOfflineMessagePayload) => async (
        dispatch: Dispatch
    ) => {
        ConversationService.SendFirebaseOfflineMessage(payload);
    },
    inviteReceived: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "inviteReceived"
        });
    },
    // MessageReceived: (payload: any) => async (
    //     dispatch: Dispatch
    // ) => {
    //     dispatch({
    //         payload: payload,
    //         type: "messageReceived"
    //     });
    // },
    offlineChatMmessage: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "offlineChatMmessage"
        });
    },
    offlineGameInviteAccepted: (payload: any) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: "offlineGameInviteAccepted"
        });
    },
    SetConversationUser: (payload: { otherUser: USER_SESSION }) => async (
        dispatch: Dispatch
    ) => {
        // await dispatch(InviteActions.CreateRoom({ userId1: user._id, userId2: suggestion._id }));

        dispatch({
            payload: { otherUser: payload.otherUser },
            type: ActionConsts.Conversation.SetConversationOtherUser
        })
    },
    SendMessageOnServer: (payload: IConversationPage.Actions.IGetPOSTSendMessagePayload) => async (
      dispatch: Dispatch
    ) => {
        const result = await ConversationService.SendMessageOnServer(payload);
        return result;
    },
    ReceiveAllMessageFromServer: (payload: IConversationPage.Actions.IGetGETReceiveAllMessagePayload) => async (
      dispatch: Dispatch
    ) => {
        const result = await ConversationService.ReceiveAllMessageFromServer(payload);
        return result;
    },

    CheckTyping: (payload: IConversationPage.Actions.IGetPOSTCheckTypingPayload) => async (
      dispatch: Dispatch
    ) => {
        const result = await ConversationService.CheckTyping(payload);
        console.log('resulttttttttttttt typing', result);
        return result;
    },
    ReceiveMessageFromServer: (payload: IConversationPage.Actions.IGetGETReceiveMessageByIdPayload) => async (
        dispatch: Dispatch
      ) => {
         // console.log("payload**",payload)
          const result = await ConversationService.ReceiveMessageFromServer(payload);
          // console.log('ressssssssssssssssss**', result)
          return result;
    },
    MarkMessageAsRead: (payload: IConversationPage.Actions.IGetPOSTMarkMessageAsReadPayload) => async (
      dispatch: Dispatch
    ) => {
        const result = await ConversationService.MarkMessageAsRead(payload);
        // console.log('resulttttttttttttt MarkMessageAsRead', result);
        return result;
    },

    UpdateChatRoomList: (payload: object) => async (
      dispatch: Dispatch
    ) => {
        dispatch({
            payload: payload,
            type: ActionConsts.Conversation.UpdateChatRoomList
        });
    },


}