import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ParagraphText, PrimaryButton } from "@Styled";
import { theme } from "@Definitions/Styled";
import { CanelTermsWarning } from "./CancelTermsWarning";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { useTranslation } from 'react-i18next';


export const TermsConditionsModal: React.FunctionComponent<{ onRejectClick: () => void, onAcceptClick: () => void }>
    = ({ onRejectClick, onAcceptClick }) => {

        const [showIgnoreWarning, setShowIgnoreWarning] = useState(false);
        const { t } = useTranslation();

        const viewPort = document.getElementById('view-port-container');

        return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={1}>
            {showIgnoreWarning && <CanelTermsWarning
                onYesClick={() => {
                    setShowIgnoreWarning(false);
                    onRejectClick();
                }}
                onNoClick={() => {
                    setShowIgnoreWarning(false);
                }} />}
            <div className="d-flex flex-column w-100 mx-5 py-4 px-3 bg-primary"
                style={{ borderRadius: "6px" }}>
                {/* <div className="d-flex justify-content-end align-items-center cursor-pointer"
                    onClick={() => {
                        setShowIgnoreWarning(true);
                    }}>
                    <img height="18px" width="18px" src="/images/cancel.png" />
                </div> */}
                <div className="pt-4" style={{
                    borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)"
                }}>
                    <ParagraphText className="d-flex text-white font-24px">
                        {t("TERMS")} {t("CONDITIONS")}</ParagraphText>
                </div>
                <br />
                <div className="d-flex flex-column text-white font-12px terms-conditions">
                    <div className="d-flex">{t("TERMS_1")}</div>
                    <div className="d-flex">{t("TERMS_2")}</div>
                    <div className="d-flex">{t("TERMS_3")}</div>
                    <div className="d-flex">{t("TERMS_4")}</div>
                    <div className="d-flex">{t("TERMS_5")}</div>
                    <div className="d-flex">{t("TERMS_6")}</div>
                </div>
                <br />
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <PrimaryButton
                        className="font-20px titillium-semibold text-primary w-100"
                        backgroundColor={theme.colors.yellow}
                        borderRadius="30px"
                        onClick={() => {
                            onAcceptClick();

                            // Analytics
                            SendAnalyticsToFirebase(AnalyticsEventsName.ON_TERMS_N_CONDITION_ACCEPTED);
                        }}>
                        {t("ACCEPT")}
                    </PrimaryButton>
                    <br />
                    {/* <div className="cursor-pointer font-16px" style={{ color: "#B9B9B9" }}
                        onClick={() => {
                            setShowIgnoreWarning(true);

                            // Analytics
                            SendAnalyticsToFirebase(AnalyticsEventsName.ON_TERMS_N_CONDITION_DECLINE);
                        }}>
                        {t("DECLINE")}
                    </div> */}
                </div>
            </div>
        </AnimatePopup>, viewPort ? viewPort : document.body)
    }