// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { AuthenticationService } from "@Services/API/Authentication";
// #endregion Local Imports

// #region Interface Imports
import { ILoginPage, FacebookGraphLoginPayload } from "@Interfaces";
import {
  getCheckStatusCallParams,
  getName,
  getServiceofRegionIfAny,
} from "@HelperFunctions";
import { removeCookie } from "@Services/Cookie";
import { firebaseObject } from "@Services/FirebaseEvents";
import { encode } from "base-64";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { VerificationScreen } from "@Services/API/VerificationScreens/VerificationScreen";
// #endregion Interface Imports

export const AuthenticationActions = {
  UpdateBlockKeysInSession:
    (payload: { key: string; val: string[] }) => async (dispatch: Dispatch) => {
      dispatch({
        payload: payload,
        type: ActionConsts.Authentication.UpdateBlockKeysInSession,
      });
    },
  SetRedirectToAfterLogin: (payload: string) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.Authentication.SetAfterLoginRoute,
    });
  },
  SetFirebaseConfig:
    (payload: ILoginPage.Actions.IGetGETFirebaseAppConfigurationPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.GetFirebaseConfiguration(
        payload
      );

      //   try {
      //     if (result.response && result.response.webLatestVersion) {
      //       const latestWebVersion = result.response.webLatestVersion;
      //       const currentWebVersion =
      //         store.getState().persistState.currentWebVersion;
      //       console.log("Latest Version From Config", latestWebVersion);
      //       console.log("Latest Version From State", currentWebVersion);
      //       if (currentWebVersion !== latestWebVersion) {
      //         dispatch({
      //           type: ActionConsts.Authentication.SetWebVersion,
      //           payload: latestWebVersion,
      //         });
      //         //   donot reload state if it is new user + old user who doesnot have key
      //         if (currentWebVersion !== undefined && currentWebVersion !== -1) {
      //           console.log("Latest Version From State", "Reloading called");
      //           window.location.href = window.location.href;
      //         }
      //       }
      //     }
      //   } catch (error) {}
      // if (result?.response?.country?.country_code) {
      //   result.response.country.country_code = "ma";
      // }

      //Invi Payment Method Integration
      // if (result?.response?.country?.country_code =='ma'){
      //   if(result?.response?.regions){
      //     const service:any=getServiceofRegionIfAny('ma',result?.response?.regions)
      //     if(service){
      //       const result:any = await AuthenticationService.getInwiRedirectionUrl(
      //         {serviceId:service.serviceId}
      //       );
      //       if(result){
      //         if(result.data.redirectionUrl)
      //         dispatch({
      //           payload:result.data.redirectionUrl,
      //           type: ActionConsts.Authentication.SetInviRedirectionUrl
      //         })
      //         // window.open(result.data.redirectionUrl,'_self');
      //       }
      //     }
      //   }

      // }
      if (result.response && result.success) {
        localStorage.setItem("configNo", result.response.v + "");
      }

      dispatch({
        payload: result.response && result.success ? result.response : null,
        type:
          result.response && result.success
            ? ActionConsts.Authentication.SetFirebaseConfigSuccess
            : ActionConsts.Authentication.SetFirebaseConfigError,
      });
      return result;
    },
  setForceFirebaseConfigFlag:
    (payload: boolean) => async (dispatch: Dispatch) => {
      dispatch({
        type: ActionConsts.Authentication.UpdateFirebaseConfig,
        payload: payload,
      });
    },
  Login:
    (payload: ILoginPage.Actions.IGetGETLoginPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.Login(payload);
      dispatch({
        payload:
          result.sucess && result.data
            ? { session: result.data }
            : { error: result.error },
        type:
          result.sucess && result.data
            ? ActionConsts.Authentication.UserLoginSuccess
            : ActionConsts.Authentication.UserLoginError,
      });
      return result;
    },
  UnSub:
    (payload: ILoginPage.Actions.IGetPOSTUnSubPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.UnSubscribe(payload);
      return result;
    },
  SendPin:
    (
      payload: ILoginPage.Actions.IGetGETSendPinPayload,
      selectedLanguage: string
    ) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.SendPin(
        payload,
        selectedLanguage
      );
      return result;
    },
  ConfirmPin:
    (
      payload: ILoginPage.Actions.IGetGETConfirmPinPayload,
      selectedLanguage: string
    ) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.ConfirmPin(
        payload,
        selectedLanguage
      );
      return result;
    },
  UTMCall:
    (payload: ILoginPage.Actions.IGetUTMPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.UtmApi(payload);

      return result;
    },
  GetFacebookGraphData:
    (payload: FacebookGraphLoginPayload) => async (dispatch: Dispatch) => {
      const result = await AuthenticationService.FacebookGraphLogin(payload);

      return result;
    },
  UpdateUser:
    (payload: ILoginPage.Actions.IGetPATCHUserPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.PatchUser(payload);
      (window as any).USER_ID_PLAYSTORE = encode(result.response._id);
      dispatch({
        payload:
          result.success && result.response
            ? { session: result.response }
            : { error: result.response },
        type:
          result.success && result.response
            ? ActionConsts.Authentication.UserLoginSuccess
            : ActionConsts.Authentication.UserLoginError,
      });

      return result;
    },
  CreateDirectLoginUser:
    (payload: ILoginPage.Actions.IGetPATCHUserPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.PatchUser(payload);
      return result;
    },
  LogoutUser: () => async (dispatch: Dispatch) => {
    // when user is logging Out
    (window as any).isFirebaseInitilized = false;
    (window as any).gameSocket?.disconnect();
    (window as any).gameSocket?.close();
    (window as any).gameSocket = null;
    removeCookie("config");
    removeCookie("api-token");
    localStorage.removeItem("api-token");
    localStorage.removeItem("isUserAuthenticated");
    let firebase = firebaseObject();
    if (firebase && firebase.auth)
      console.log(await firebase?.auth()?.signOut());
    try {
      (window as any).FB?.logout((logoutResponse: any) => {
        if (logoutResponse.status === "connected") {
          console.log("loggedIn");
        } else console.log("notLoggedIn");
      });
    } catch (error) {
      console.log("Logging Out error", error);
    }
    dispatch({
      payload: null,
      type: ActionConsts.Authentication.LogoutUserSuccess,
    });
  },
  CheckSubscriptionStatus:
    (payload: ILoginPage.Actions.IGetGETSubscriptionStatusPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.GetSubscriptionStatus(payload);
      if (result.response_code !== -1) {
        dispatch({
          payload: { purchaseStatus: result },
          type: ActionConsts.Authentication.UpdateCheckStatusResponse,
        });
      }
      return result;
      // dispatch({
      //     payload: null,
      //     type: ActionConsts.Authentication.LogoutUserSuccess
      // });
    },
  // GetFirebaseConfig: (payload: ILoginPage.Actions.IGetGETFirebaseAppConfigurationPayload) => async (
  //     dispatch: Dispatch
  // ) => {
  //     const result = await AuthenticationService.GetFirebaseConfiguration(payload);
  //     return result;
  // },
  GetGeoLocationFromIp: () => async (dispatch: Dispatch) => {
    const result = await AuthenticationService.GetGeoLocationFromIp();
    dispatch({
      payload: result,
      type: ActionConsts.Settings.UserGeoLocation,
    });
    return result;
  },
  UnBlockUser:
    (payload: ILoginPage.Actions.IGetPATCHUnblockUserPayload) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.UnBlockUser(payload);
      // dispatch({
      //     payload: result.success && result.response ? { session: result.response } : { error: result.response },
      //     type: result.success && result.response ? ActionConsts.Authentication.UserLoginSuccess : ActionConsts.Authentication.UserLoginError
      // });
      return result;
    },
  GetUser:
    (id: string, configVersion: string, actionDispatcher: any) =>
    async (dispatch: Dispatch) => {
      const result = await AuthenticationService.GetUser(id, configVersion);
      dispatch({
        payload:
          result.success && result.response
            ? { session: result.response }
            : { error: result.response },
        type:
          result.success && result.response
            ? ActionConsts.Authentication.UserLoginSuccess
            : ActionConsts.Authentication.UserLoginError,
      });
      if (result.success && result.response && !result.response.name) {
        // set user name if user name is empty

        actionDispatcher(
          AuthenticationActions.UpdateUser({
            _id: result.response._id as string,
            name: getName(result.response),
            isWeb: true,
          })
        );
      }
    },
  addPaymentMethod:
    (payload: any, actionDispatcher: any) => async (dispatch: Dispatch) => {
      const result = await AuthenticationService.addPaymentMethodCall(payload);
      if (result.success && result.response) {
        dispatch({
          payload: { session: result.response },
          type: ActionConsts.Authentication.UserLoginSuccess,
        });

        // actionDispatcher(AuthenticationActions.CheckSubscriptionStatus(getCheckStatusCallParams(session,'')))
      } else {
        setTimeout(() => {
          actionDispatcher(
            AuthenticationActions.addPaymentMethod(payload, actionDispatcher)
          );
        }, 10000);
      }
      return result;
    },

  PackageDetail: (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.Authentication.SetUserPackageDetails,
    });
  },
  GetUserInterests: () => async (dispatch: Dispatch) => {
    return AuthenticationService.GetUserInterests();
  },
  setWebVersion: (payload: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Authentication.SetWebVersion,
      payload: payload,
    });
  },
  setUtmParams: (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Authentication.SetUTMParams,
      payload: payload,
    });
  },
  SetPaywallSkipStatus: (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Authentication.SetPaywallSkipStatus,
      payload: payload,
    });
  },
  postURLParams: (payload: any) => async (dispatch: Dispatch) => {
    AuthenticationService.postURLParams(payload);
  },
  SetLogoutUser: (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Authentication.SetLogoutUser,
      payload: payload,
    });
  },
  GetPaywallPackages: (payload: string) => async (dispatch: Dispatch) => {
    const result = await AuthenticationService.GetPaywallPackages(payload);
    if (result.success) {
      let packageArray: any = [];
      let paymentObject = new Array<Array<SUB_BUNDLES>>();
      Object.keys(result.bundles).forEach((key: string) => {
        if (result.bundles[key].data && result.bundles[key].data.length > 0) {
          packageArray.push(result.bundles[key].titleEng);
          let paymentTypes: SUB_BUNDLES[] = [];
          let service: SUB_BUNDLES;
          result.bundles[key].data.forEach((item: any) => {
            const {
              services,
              operatorTitle,
              numLimit,
              operatorCode,
              operatorImage,
            } = item;
            service = services;
            service.operatorTitle = operatorTitle.toUpperCase();
            service.numLimit = numLimit;
            service.operatorCode = operatorCode;
            service.operatorImage = service.operatorImage || operatorImage;
            service.titleAr = result.bundles[key].titleAr;
            service.titleEng = result.bundles[key].titleEng;
            service.paymentType = service.paymentType.toLowerCase();
            paymentTypes.push(service);
          });
          paymentObject.push(paymentTypes);
        }
      });
      dispatch({
        type: ActionConsts.Authentication.SetPaywallData,
        payload: { tabs: packageArray, paymentTypes: paymentObject },
      });
    }
  },
  SetUserHasSkippedPaywall:
    (payload: boolean) => async (dispatch: Dispatch) => {
      dispatch({
        type: ActionConsts.Authentication.UserHasSkippedPaywall,
        payload: payload,
      });
    },
  GetServiceById: (payload: number) => async (dispatch: Dispatch) => {
    const result = await AuthenticationService.getServiceByIdCall(payload);
    if (result.success) {
      // console.log("Resulttt",result);
      dispatch({
        type: ActionConsts.Authentication.SetSingleServiceData,
        payload: result.response,
      });
    }
  },
  SetPaymentMethodOnLogin: (payload: number) => async (dispatch: Dispatch) => {
    const result = await AuthenticationService.getServiceByIdCall(payload);
    if (result.success) {
      if (result.response.data) {
        dispatch({
          type: ActionConsts.Authentication.SetLoginPaymentMethod,
          payload: result.response,
        });
      } else {
        dispatch({
          type: ActionConsts.Authentication.SetLoginPaymentMethod,
          payload: "",
        });
      }
    }
  },
  getVerificationScreenConfiguration:
    (url: string) => async (dispatch: Dispatch) => {
      const result =
        await AuthenticationService.getVerificationScreenConfigurationCall(url);
      dispatch({
        type: ActionConsts.Authentication.SetVerificationConfigData,
        payload: result,
      });
    },
};
