// #region Global Imports
import React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { IInputTransparent } from "./InputTransparent";
// #endregion Local Imports

const _InputTransparent = styled.input<IInputTransparent.IProps>`
    margin: 0 5px;
    text-align: ${({ textAlign }) => { return textAlign ? textAlign : "center"}};
    line-height: ${({ lineHeight }) => { return lineHeight ? lineHeight : "60px"}};
    font-size: 18px;
    outline: none;
    width: ${({ width }) => { return width ? width : "10%"}};
    transition: all .2s ease-in-out;
    border: 0px solid transparent;
    border-bottom: ${({ borderColor }) => { return '1px solid ' + (borderColor ? borderColor : "rgba(255, 255, 255, 0.6)")}};
    z-index: 1;
    opacity: 1;
    padding: 0px;
    background: transparent;
    color: ${({ color }) => { return color ? color : "white"}};
`;

export const InputTransparent: React.FunctionComponent<IInputTransparent.IProps> = props => {
    return <_InputTransparent {...props} />;
};
