// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports

import {
    IAction,
    ITournamentPage,
    TOURNAMENT_HOME,
    BANNERS,
    HISTORY,
    CLAIM_HISTORY,
    TOURNAMENT_LEADERBOARD_USER, TOURNAMENT_NOTIFICATION_DATA
} from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: ITournamentPage.IStateProps = {
        activeTournaments: new Array<TOURNAMENT_HOME>(),
        banners: new Array<BANNERS>(),
        tournamentHistory: new Array<HISTORY>(),
        wallet: new Array<CLAIM_HISTORY>(),
        tournamentLeaderboardData: {
            currentUser: <TOURNAMENT_LEADERBOARD_USER>{},
            users: []
        },
        selectedTournament: <TOURNAMENT_HOME>{},
        isTournamentMatch: false,
        tournamentNotificationData: <TOURNAMENT_NOTIFICATION_DATA>{},
};


export const TournamentReducer = (
    state = INITIAL_STATE,
    action: IAction<ITournamentPage.Actions.IMapTournament & ITournamentPage.Actions.IClaimedRewardResponse & ITournamentPage.Actions.IUserSession>
) => {
    switch (action.type) {
        case ActionConsts.Tournament.GetTournamentError: {

            return Object.assign({}, state, {
                activeTournaments: [],
                banners: []
            });
        }
        case ActionConsts.Tournament.GetTournamentSuccess: {
            const { activeTournaments, banners } = action.payload!;
            return Object.assign({}, state, {
                activeTournaments: activeTournaments,
                banners: banners
            });
        }

        case ActionConsts.Tournament.GetTournamentHistorySuccess: {

            const tournamentHistory = action.payload!;

            return Object.assign({}, state, {
                tournamentHistory: tournamentHistory
            });
        }

        case ActionConsts.Tournament.GetTournamentHistoryError: {
            return Object.assign({}, state, {
                tournamentHistory: []
            });
        }

        case ActionConsts.Tournament.GetTournamentClaimHistorySuccess: {

            const tournamentClaimHistory = action.payload!;

            return Object.assign({}, state, {
                wallet: tournamentClaimHistory
            });
        }

        case ActionConsts.Tournament.GetTournamentClaimHistoryError: {
            return Object.assign({}, state, {
                wallet: []
            });
        }

        case ActionConsts.Authentication.UserLoginSuccess: {
            let { session } = action.payload!;

            // console.log("wallet",wallet)
            return Object.assign({}, state, {
                wallet : session.wallet
            });
          }

        case ActionConsts.Tournament.GetTournamentLeaderboardSuccess: {

            const { tournamentLeaderboardData } = action.payload!;

            return Object.assign({}, state, {
                tournamentLeaderboardData: tournamentLeaderboardData
            });
        }
        case ActionConsts.Tournament.GetTournamentLeaderboardFail: {

            return Object.assign({}, state, {
                tournamentLeaderboardData: {}
            });
        }

        case ActionConsts.Tournament.ClaimRewardSuccess: {

            const { claimedVoucher } = action.payload!;
            let wallet = state.wallet;
            if(claimedVoucher?.voucherCode) {
                wallet.filter(walletEntry => walletEntry.voucherCode === claimedVoucher.voucherCode)[0].claimed = true;
            }
            return Object.assign({}, state, {
                wallet
            });
        }

        case ActionConsts.Tournament.TournamentExpireSuccess: {

            const expired: any = action.payload!;
            let activeTournaments = state.activeTournaments;
            if (expired?.tournamentId) {
                const expiredTournament = activeTournaments.filter(tournamentExpire => tournamentExpire._id === expired.tournamentId);
                if (expiredTournament.length) expiredTournament[0].isActive = expired?.isActive;
            }
            return Object.assign({}, state, {
                activeTournaments
            });
        }
        case ActionConsts.Tournament.TournamentExpireFail: {

            return Object.assign({}, state, {
                // activeTournaments: []
            });
        }

        case ActionConsts.Tournament.SetSelectedTournament: {

            const {selectedTournament, tournamentLeaderboardData} = action.payload!;

            return Object.assign({}, state, {
                selectedTournament: selectedTournament,
                tournamentLeaderboardData: tournamentLeaderboardData
            });
        }
        case ActionConsts.Tournament.SetIsTournamentMatch: {

            const isTournamentMatch = action.payload!;

            return Object.assign({}, state, {
                isTournamentMatch: isTournamentMatch,
            });
        }
        case ActionConsts.Tournament.SetTournamentNotificationData: {

            const tournamentNotificationData = action.payload!;

            return Object.assign({}, state, {
                tournamentNotificationData: tournamentNotificationData,
            });
        }
        default:
            return state;
    }
}

