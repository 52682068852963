// #region Global Imports
import { DefaultTheme } from "styled-components";
// #endregion Global Imports

const theme: DefaultTheme = {
    colors: {
        primary: "#1D0141",
        yellow: "#FEC144",
        yellow100: "#FFC700",
        yellow200: "#FFA234",
        yellow300: "#FFCC02",
        grey100: "#B0B0B0",
        grey200: "#CECECE",
        grey300: "#A7A7A7",
        grey400: "#646464",
        grey500: "#B2B2B2",
        grey600: "#FAFAFA",
        grey700: "#C8C7CC",
        green: "#A3CD3A",
        orange: "#FF5115",
        blue100:"#4B0B95"
    },
};

export { theme };
