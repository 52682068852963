import React from "react";
import ReactDOM from "react-dom";
import {IStore, PLAYERS, USER} from '@Interfaces';

import {AnimatePopup, CircularImage, ModalContainer, ParagraphText} from "@Styled";
import {getProfileImage} from "@HelperFunctions";
import {useTranslation} from "react-i18next";
import styles from "./style.module.scss";
import {theme} from "@Definitions/Styled";
import {useSelector} from "react-redux";

const InviteButton: React.FunctionComponent<{disabled: boolean, onClick: () => void}> = ({disabled, onClick}) => {
  return (
    <div className="position-relative">
      <div className={`text-white titillium-semibold text-center ${!disabled ? 'cursor-pointer' : ''} ${styles.addButtonText}`}
           style={{background: disabled ? theme.colors.grey400 : 'linear-gradient(180deg, #FECF0F 0%, #E37130 100%)',
             pointerEvents: disabled ? 'none' : 'auto'}} onClick={onClick}>
        Add
      </div>
      <img src="/images/newDesign/ellipse.png" width="7" alt="ellipse" className={styles.imageEllipse}/>
    </div>)
}
export const InviteModal: React.FunctionComponent<{ usersArray: USER[], open: boolean, setOpen: (a: boolean) => void, onFriendInvite: (user?: USER) => void }>
  = ({onFriendInvite, usersArray, open, setOpen}) => {
  const {matchObject: {allPlayers}} = useSelector((state: IStore) => state.multiPlayState);
  const { selectedLanguage } = useSelector((state: IStore) => state.persistState);
  const {t} = useTranslation();
  const viewPort = document.getElementById('view-port-container');

  const checkIsDisabled = (user: USER) => {
    let disabled: boolean = false
    allPlayers.map((player: PLAYERS) => {
      if(player?._id === user._id) {
        return disabled = true
      }
    });
    return disabled;
  }
  // return ReactDOM.createPortal(<AnimatePopup animateIn={false} background="rgb(0,0,0,0.7)" zIndex={3}
  //                                            alignItems="flex-end">
  //   <ModalContainer backgroundColor='#430676' borderRadius={"30px 30px 0px 0px"} margin={'0px'} maxHeight="70%"
  //                   overflowY="auto" alignItems="flex-start" onClick={(e) => {
  //     e.stopPropagation()
  //   }}>
  //     <div className="d-flex flex-column w-100" style={{height: '90%', overflowY: 'visible'}}>
  //       {usersArray.length && usersArray.map((user: USER) => {
  //         return <>
  //           {user && <div key={user._id}>
  //             <div className="d-flex align-items-center justify-content-between">
  //               <div className="d-flex align-items-center">
  //                 <CircularImage src={[getProfileImage(user, true)]} height="42px" width="42px"/>
  //                 <span className="ml-3 text-white">{user.name}</span>
  //               </div>
  //               <img className="cursor-pointer" src="/images/newDesign/popup-button.png" width='20' alt="invite" onClick={() => onFriendInvite(user)}/>
  //             </div>
  //             <div
  //               style={{
  //                 borderTop: "1px solid rgba(255, 255, 255, 0.3)",
  //                 margin: "10px 0",
  //               }}
  //             />
  //           </div>}
  //         </>
  //       })}
  //       {usersArray.length === 0 &&  <ParagraphText className="text-white text-center titillium-semibold">{t('NO_FRIENDS')}</ParagraphText>}
  //     </div>
  //   </ModalContainer>
  // </AnimatePopup>, viewPort ? viewPort : document.body)
  return ReactDOM.createPortal(<div className={styles.inviteContainer + " hide-y-scroller"} style={{overflowY: "scroll", height: "100%"}} onClick={() => setOpen(!open)}>
    <div className={styles.container} onClick={(e) => {
      e.stopPropagation();
    }}>
      <div className={styles.randomPlayers}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="text-white">Random Players</span>
          </div>
          {/*<div style={{position: "relative"}}>*/}
          {/*  <img className="cursor-pointer" src="/images/newDesign/popup-button.png" width='80' alt="invite"/>*/}
          {/*  <span className={`titillium-semibold ${styles.addButtonText}`}>Add</span>*/}
          {/*</div>*/}
          <InviteButton disabled={false} onClick={onFriendInvite} />
        </div>
      </div>
      <div className={`d-flex flex-column w-100 ${styles.modalStyles}`}>
        {usersArray.length && usersArray.map((user: USER) => {
          return <>
            {user && <div key={user._id}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <CircularImage src={[getProfileImage(user, true)]} height="42px" width="42px"/>
                  <span className={"text-white " + (selectedLanguage === "ar"? "mr-3" : "ml-3")}>{user.name}</span>
                </div>
                {/*<div style={{position: "relative"}}>*/}
                {/*  <img className="cursor-pointer" src="/images/newDesign/popup-button.png" width='80' alt="invite" onClick={() => onFriendInvite(user)}/>*/}
                {/*  <span className={`titillium-semibold ${styles.addButtonText}`}>Add</span>*/}
                {/*</div>*/}

                <InviteButton disabled={checkIsDisabled(user)} onClick={() => onFriendInvite(user)} />
              </div>
              <div
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                  margin: "10px 0",
                }}
              />
            </div>}
          </>
        })}
        {usersArray.length === 0 &&
          <ParagraphText className="text-white text-center titillium-semibold">{t('NO_FRIENDS')}</ParagraphText>}
      </div>
    </div>
  </div>, viewPort ? viewPort : document.body)
}