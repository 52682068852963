import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";
import ReactPlayer from "react-player";


export const VideoPopupModal: React.FunctionComponent<{
  description?: string;
  width?: string;
  url: string;
}> = (props) => {
  const description = props.description ? props.description : "";
  let infoImage = "/images/ico_alert.png";

  const viewPort = document.getElementById("view-port-container");
  return ReactDOM.createPortal(
    <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
      <ModalContainer
        headerProps={{ backgroundColor: "#430676" }}
        width={props.width}
        backgroundColor="#430676"
        headerChild={
          <img
            src={infoImage}
            style={{ color: "#430676", width: "50px", height: "50px" }}
          />
        }
      >
        <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
          {description.length > 0 && (
            <ParagraphText
              className="text-center font-16px"
              style={{ color: "white" }}
            >
              {description}
            </ParagraphText>
          )}

          <div
            className="d-flex align-items-center justify-content-center text-white"
            style={{ marginTop: "10px" }}
          >
            <div
              style={{
                borderRadius: "27px",
                width: "219px",
                height: "128px",
                backgroundColor: "#C4C4C4",
                overflow: "hidden",
              }}
              className="cursor-pointer d-flex align-items-center justify-content-center"
              onClick={() => {}}
            >
              <ReactPlayer
                width="219px"
                height="128px"
                playing={true}
                loop={true}
                controls={false}
                url={props.url}
              />
            </div>
          </div>
        </div>
      </ModalContainer>
    </AnimatePopup>,
    viewPort ? viewPort : document.body
  );
};
