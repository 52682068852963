import React, { useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { USER_SESSION } from "@Interfaces";
import { AuthenticationActions } from '@Actions/Authentication';


export const Authenticated: React.FunctionComponent<{ session: USER_SESSION, userGeoLocation: any, onScroll?: () => void, mobileDeviceUserId?: string | null }>
    = ({ children, session, onScroll, userGeoLocation, mobileDeviceUserId }) => {

        const dispatch = useDispatch();
        const location = useLocation();
        const history = useHistory();
        const useQuery = () => {
          return new URLSearchParams(useLocation().search);
        }
        const query = useQuery();
        const queryString = query.get("isMobile") as string;

        const isMessagesWindow = window.location.href.indexOf("/messages") > 0
        const backgroundImage = isMessagesWindow ? "bg-message-image" : "bg-main-image";

  useEffect(() => {
            if (!queryString ? !session || !('_id' in session) : !mobileDeviceUserId) {
                //dispatch(AuthenticationActions.SetRedirectToAfterLogin(location.pathname));
                history.push("/login");
                // if (!userGeoLocation || userGeoLocation === null)
                //     window.location.reload();
            }
        }, [session]);

        return <div className={`hide-y-scroller px-3 w-100 ${backgroundImage}`}
            style={{ overflowY: "scroll", overflowX: 'hidden', height: "100%" }}
            onScroll={(e: any) => {
                onScroll && onScroll();
            }}>
            {children}
        </div>
    }
