import { ScriptLoader } from '@Services/UI';

let mesiboApiInstance: any = null;

export const MesiboApi = () => {

    function init() {
        return new Promise((resolve, reject) => {
            if ((window as any).Mesibo) resolve((window as any).Mesibo);

            const scriptLoader: any = ScriptLoader(function() {
                resolve((window as any).Mesibo);
            }, function() {
                reject(null);
                alert("Failed to load mesibo script");
            });
    
            scriptLoader.require(["https://api.mesibo.com/mesibo.js"]);
        });
    };

    return {
        // Get the Singleton instance if one exists
        // or create one if it doesn't
        getInstance: async function () {
            if (!mesiboApiInstance) {
                const mesiboApi: any = await init();
                mesiboApiInstance = new mesiboApi();
            }
            return mesiboApiInstance;
        }
    };
}
