// #region Global Imports
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { useMemo } from "react";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// #endregion Global Imports
import { encryptTransform } from "redux-persist-transform-encrypt";

// #region Local Imports
import Reducers from "./Reducers";
import { clearAppCache, lockOppener } from "@HelperFunctions";
import { ActionConsts } from "@Definitions/ActionConsts";
// #endregion Local Imports

const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: lockOppener,
      onError: function (error) {
        clearAppCache();
      },
    }),
  ],
  key: "root",
  storage,
  whitelist: ["persistState"],
  blacklist: [],
};
const appReducer = Reducers;
const rootReducer = (state: any, action: any) => {
  // if (action.type === ActionConsts.Authentication.LogoutUserSuccess) {
  //   console.log("on logout**")
  //   // localStorage.removeItem('persist:root');

  //   return appReducer(undefined, action)
  // }
  return appReducer(state, action);
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
export const makeStore = (initialState: {}) => {
  return store;
};
