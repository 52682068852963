import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup } from "@Styled";

export const ImageModal: React.FunctionComponent<{
  onClose: () => void;
  src: string;
  type?: string;
}> = ({ onClose, src, type = "image" }) => {
  const viewPort = document.getElementById("view-port-container");
  useEffect(() => {
    document.body.addEventListener("keyup", function (e) {
      if (e.key === "Escape") {
        onClose();
      }
    });
    return () => {
      document
        .getElementById("myModal")
        ?.removeEventListener("keyup", () => {});
    };
  });

  return ReactDOM.createPortal(
    <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
      <div id="myModal" className="modal-image">
        <span
          className="image-close"
          style={{zIndex:10}}
          onClick={() => {
              console.log("close the video")
            onClose();
          }}
        >
          &times;
        </span>
        {type === "image" ? (
          <img className="modal-image-content" id="img01" src={src} />
        ) : (
          <video src={src} width="100%" height="100%" controls></video>
        )}
        {/* <div id="caption" className="modal-image-caption"></div> */}
      </div>
    </AnimatePopup>,
    document.body
  );
};
