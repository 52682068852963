import React, { useState, useEffect } from "react";
import SvgRadialMenu from "./SvgRadialMenu";
import { useSelector, useDispatch } from "react-redux";
import { IStore } from "@Interfaces";
import { PersistActions } from "@Actions/PersistActions";
import JitsiMeetComponent from "@Components/JitsiMeetComponent";
import classes from "./Menu.module.css";

const emojis: any = { shocked: "1F632", love: "1F60D", laugh: "1F602" };
const messges: any = {
  en: ["Hi There", "Nice Try", "OH WOW!", "Well Done"],
  ar: ["أهلًا بك", "محاولة جيدة", "أوه، عظيم", "أحسنت"],
};
interface RadialMenuProps {
  gameMusicStatus?: boolean;
  setGameMusicStatus?: (a: boolean) => void;
  sendMessageToOpponent?: (a: any) => void;
  selectedLanguage: string;
  matchId: string;
  orientation: string;
}
const callOnImage = (new Image().src = "/images/radial_menu/callOn.png");
const callOffImage = (new Image().src = "/images/radial_menu/callOff.png");
const emojiOn = (new Image().src = "/images/radial_menu/emojiOn.png");
const emojiOff = (new Image().src = "/images/radial_menu/emojiOff.png");
const shockedEmoji = (new Image().src = "/images/radial_menu/shocked.png");
const loveEmoji = (new Image().src = "/images/radial_menu/love.png");
const laughEmoji = (new Image().src = "/images/radial_menu/laugh.png");
const messageImage = (new Image().src = "/images/radial_menu/messages.png");
const musicOnImage = (new Image().src = "/images/radial_menu/musicOn.png");
const musicOffImage = (new Image().src = "/images/radial_menu/musicOff.png");
const micOffImage = (new Image().src = "/images/radial_menu/micOff.png");
const micOnImage = (new Image().src = "/images/radial_menu/micOnNew.png");
const micNotConnectedImage = (new Image().src = "/images/radial_menu/micNotConnected.png");
const micOnnImage = (new Image().src = "/images/radial_menu/micOn.png");
const callCutImage = (new Image().src = "/images/radial_menu/OpenMenu_Joined.gif");
const callJoinImage = (new Image().src = "/images/radial_menu/callOff.png");
const callDialingImage = (new Image().src =
  "/images/radial_menu/OpenMenu_Joining.gif");
const GamesSvgRadialMenu: React.FC<RadialMenuProps> = ({
  sendMessageToOpponent,
  gameMusicStatus,
  setGameMusicStatus,
  matchId,
  selectedLanguage,
  orientation
}) => {
  const [showMessages, setShowMessages] = useState(false);
  const [showGameMenu, setShowGameMenu] = useState(false);
  const [call, setCall] = useState(false);
  const [style, setStyle] = useState({
    fontSize: "1em",
    width: "14em",
    height: "14em",
    position: "absolute",
    top: "-10px",
    right: "-5px",
    zIndex: 1,
    transform: "translate(100%,-100%)",
    transition: "all 1s ease-in-out",
  });
  const [joinCall, setJoinCall] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [mic, setMic] = useState(true);
  const [music, setMusic] = useState(false);
  const [selfSetMusic, setSelfSetMusic] = useState(false);
  const [menuImage, setMenuImage] = useState("G_animations.gif");
  const persistState = useSelector((state: IStore) => state.persistState);
  const { session, showedCallFunctionality } = persistState;
  const dispatch = useDispatch();
  const iframeFocus = () => {
    var myIframe = document.getElementById("iFrame");
    if (document.activeElement == myIframe) {
      setShowGameMenu(false);
      setShowMessages(false);
      setStyle({
        ...style,
        //@ts-ignore
        transform: "translate(100%,-100%)",
        transition: "all .4s ease-in-out",
      });

      // setItems(3);
    }
  };

  useEffect(() => {
    setJoinCall(true);
    if (callStarted) {
      setCall(true);
      setMic(!mic);
      setIsMute(!mic);
      // setIsMute(!mic);
    } else setCall(false);
    // if (isMute) setMic(true);
    // else setMic(false);
  }, [callStarted])
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!showedCallFunctionality) {
  //       setShowGameMenu(true);
  //       setStyle({
  //         ...style,
  //         //@ts-ignore
  //         transform: "translate(-5px,9px)",
  //         transition: "all .6s ease-in-out",
  //       });
  //       dispatch(PersistActions.SetCallFunctionalityShowed(true));
  //       setTimeout(() => {
  //         setShowGameMenu(false);
  //         setStyle({
  //           ...style,
  //           //@ts-ignore
  //           transform: "translate(100%,-100%)",
  //           transition: "all .4s ease-in-out",
  //         });
  //       }, 5500);
  //     }
  //   }, 2500);
  // }, []);

  // useEffect(() => {
  //   if (gameMusicStatus) setMusic(true);
  //   else setMusic(false);
  //   (window as any).$(document).ready(function () {
  //     (window as any).$("iframe").load(function () {
  //       (window as any)
  //         .$(this)
  //         .contents()
  //         .on("touchstart", function () {
  //           setShowGameMenu(false);
  //           setShowMessages(false);
  //           // setItems(3);
  //         });
  //     });
  //   });
  //
  //   document.getElementById("view-port-container")!.style.overflow = "hidden";
  //
  //   window.focus();
  //   window.addEventListener("blur", iframeFocus);
  //   return () => {
  //     document.getElementById("view-port-container")!.style.overflow = "unset";
  //     window.removeEventListener("blur", iframeFocus);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (callStarted) {
  //     setCall(true);
  //   } else setCall(false);
  //   if (isMute) setMic(true);
  //   else setMic(false);
  //
  //   if (callStarted) {
  //     setMenuImage("joined_anim.gif");
  //   } else if(!call) {
  //     setMenuImage("G_animations.gif");
  //   }
  // }, [callStarted, isMute]);

  useEffect(()=>{
    if (gameMusicStatus) setMusic(true);
    else setMusic(false);
  },[gameMusicStatus])


  const handleEmojiClick = (item: string) => {
    if (showMessages) {
      setShowMessages(false);
    }
    if (item === "laugh" || item === "love" || item === "shocked") {
      let message: any = {
        m: emojis[item],
        s: "",
        t: "e",
      };
      sendMessageToOpponent!(message);
    }
  };
  const handleMessageItemClick = () => {
    if (showMessages) {
      setShowMessages(false);
    } else {
      setShowMessages(true);
    }
  };
  const messageClicked = (item: any) => {
    sendMessageToOpponent!({
      m: item,
      s: "",
      t: "t",
    });
  };
  const handleCallItemsClick = (item: string) => {
    if (showMessages) {
      setShowMessages(false);
    }
    if(item === "reconnect") {
      if(!callStarted) {
        setJoinCall(true)
      }
      if (!call) {
        setJoinCall(true);
      }
    }
    if (item === "join") {
      setMenuImage("joining_anim.gif");
      if (!call) {
        setJoinCall(true);
        setShowGameMenu(false);
        setStyle({
          ...style,
          //@ts-ignore
          transform: "translate(100%,-100%)",
          transition: "all .4s ease-in-out",
        });
        setShowMessages(false);
        if (gameMusicStatus) {
          setGameMusicStatus!(false);
        }
      } else {
        setCall(false);
        setCallStarted(false);
        setJoinCall(false);
        //mute mic on call close
        // setMic(true);
        // setIsMute(true);

        // unmute mic on call close
        if (mic && isMute) {
          setMic(false);
          setIsMute(false);
        }
        if (!gameMusicStatus) {
          setGameMusicStatus!(true);
        }
      }
    }
    //to enable mic, call must be started
    if (item === "mic" && call) {
      if (!selfSetMusic && !mic) {
        setGameMusicStatus!(!gameMusicStatus)
      } else if (!selfSetMusic && mic) {
        if(gameMusicStatus) setSelfSetMusic(false);
        setGameMusicStatus!(true)
      }
      setMic(!mic);
      setIsMute(!mic);
    }
    if (item === "music" && !joinCall) {
      // if(!call)
      setGameMusicStatus!(!gameMusicStatus);
    }
    if (item === "music") {
      if(gameMusicStatus) {
        setSelfSetMusic(true);
      } else setSelfSetMusic(false);
      // if(!call)
      setGameMusicStatus!(!gameMusicStatus);
    }
  };
  const menuSwitchHandler = (num: number) => {
    setItems(num);
    if (showMessages) {
      setShowMessages(false);
    }
  };
  const getConfigForCallGifs=()=>{
    return call
    ? "110,230,90px,90px"
    : joinCall && !callStarted
    ? "110,230,90px,90px"
    : "141,260,28px,28px"
  }
  const [items, setItems] = useState(3);
  // const data: any = {
  //   MenuContainerStyling: style,
  //   menuItems: [
  //     {
  //       action: () => {
  //         menuSwitchHandler(3);
  //       },
  //       active: items === 3 ? true : false,
  //       subItemsCount: 3,
  //       imageUrl: items === 3 ? callOnImage : callOffImage,
  //       fillColor: items === 3 ? "#A0CC3B" : "white",
  //       strokeColor: "#E6E6E6",
  //       subItems: [
  //         {
  //           imageUrl: mic ? micOffImage : micOnImage,
  //
  //           action: () => {
  //             handleCallItemsClick("mic");
  //           },
  //         },
  //         {
  //           imageUrl: music ? musicOnImage : musicOffImage,
  //
  //           action: () => {
  //             handleCallItemsClick("music");
  //           },
  //         },
  //         {
  //           imageUrl: call
  //             ? callCutImage
  //             : joinCall && !callStarted
  //             ? callDialingImage
  //             : callJoinImage,
  //             x:parseInt(getConfigForCallGifs().split(',')[0]),
  //             y:parseInt(getConfigForCallGifs().split(',')[1]),
  //             imageWidth:getConfigForCallGifs().split(',')[2],
  //             imageHeight:getConfigForCallGifs().split(',')[3],
  //           action: () => {
  //             handleCallItemsClick("join");
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       subItemsCount: 4,
  //       action: () => {
  //         menuSwitchHandler(4);
  //       },
  //       active: items === 4 ? true : false,
  //       imageUrl: items === 4 ? emojiOn : emojiOff,
  //       fillColor: items === 4 ? "#A0CC3B" : "white",
  //       strokeColor: "#E6E6E6",
  //       subItems: [
  //         {
  //           imageUrl: shockedEmoji,
  //           action: () => {
  //             handleEmojiClick("shocked");
  //           },
  //         },
  //         {
  //           imageUrl: loveEmoji,
  //           action: () => {
  //             handleEmojiClick("love");
  //           },
  //         },
  //         {
  //           imageUrl: laughEmoji,
  //           action: () => {
  //             handleEmojiClick("laugh");
  //           },
  //         },
  //         {
  //           imageUrl: messageImage,
  //           action: handleMessageItemClick,
  //         },
  //       ],
  //     },
  //   ],
  // };
  return (
    <>
      {!callStarted && (
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "48px",
            zIndex: 2,
            cursor: "pointer",
            height: "35px",
            width: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '50%',
            backgroundColor: "white",
            marginTop: "4px"
          }}
          onClick={() => {
            setStyle({
              ...style,
              //@ts-ignore
              transform: "translate(-5px,9px)",
              transition: "all .6s ease-in-out",
            });
            // setShowGameMenu(true);
            handleCallItemsClick("reconnect");
          }}
        >
          <img className={classes.zoomInOutBox} src={micNotConnectedImage} width={20}/>
        </div>
      )}
      {callStarted && (
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "48px",
            zIndex: 2,
            cursor: "pointer",
            height: "35px",
            width: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '50%',
            backgroundColor: "white",
            marginTop: "4px"
          }}
          onClick={() => {
            setStyle({
              ...style,
              //@ts-ignore
              transform: "translate(-5px,9px)",
              transition: "all .6s ease-in-out",
            });
            // setShowGameMenu(true);
            handleCallItemsClick("mic");
          }}
        >
          <img src={mic ? micOnImage : micOffImage} width={20}/>
        </div>
      )}
       <div
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            zIndex: 2,
            cursor: "pointer",
            height: "35px",
            width: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: '50%',
            backgroundColor: "white",
            marginTop: "4px"
          }}
          onClick={() => {
            setStyle({
              ...style,
              //@ts-ignore
              transform: "translate(-5px,9px)",
              transition: "all .6s ease-in-out",
            });
            // setShowGameMenu(true);
            handleCallItemsClick("music");
          }}
        >
          <img src={music ? musicOnImage : musicOffImage} width={20}/>
        </div>
      {/*<SvgRadialMenu data={data} />*/}
{/*      {showMessages && (
        <div
          className={classes.message_parent}
          id="message-parent"
          style={{ zIndex: 1, right: 0, top: "14.5em" }}
        >
          {messges[selectedLanguage].map((item: any, index: any) => {
            return (
              <div
                key={index}
                onClick={() => {
                  messageClicked(item);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}*/}
      {joinCall && (
        <div style={{ display: "contents" }}>
          <JitsiMeetComponent
            matchId={matchId}
            callStarted={(call: boolean) => {
              setCallStarted(true);
            }}
            isMute={isMute}
            setIsMute={setIsMute}
            orientation={orientation}
          />
        </div>
      )}
    </>
  );
};

export default GamesSvgRadialMenu;
