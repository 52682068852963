import { CircularImage } from '@Styled/CircularImage'
import React from 'react'

export const GameMessageReceivedModal: React.FunctionComponent<any> = ({ messages }) => {
    return (
        <div style={{ position: "absolute", top: "2%", left: "0", right: "0" }}>
            {messages.map((item: any, index: number) => {
                return <div key={index} className="gameMessage">
                    <CircularImage
                        src={[item.profileImage]}
                        height="32px"
                        width="32px" 
                        border="1px solid #C4C4C4" />
                    <div style={{ color: "#999999", marginLeft: "10px", fontSize: "1.6em", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {
                            item.t === 'e' ?
                                String.fromCodePoint(parseInt(item.m, 16))
                                : item.m
                        }</div>

                </div>
            })
            }
        </div>
    )
}
