import React, { useState } from 'react';
import { UserService } from '../../Services/API/ResetUser';
import { UserModel } from '../../Services/API/ResetUser/UserModel';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from "@Interfaces";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from '@Styled/PrimaryButton';
import { GenericPopupModal } from '@Components/Modals/GenericPopupModal';
import { LanguageSelection } from '@Components/LanguageSelection/LanguageSelection';
import { useHistory } from 'react-router-dom';
import { RemoveSessionIdListner, SetOnlineStatus } from '@Services/FirebaseEvents';
import { AuthenticationActions } from '@Actions/Authentication';

interface DeleteAccountProps {
    userId: string;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ userId }) => {
    const [showModal, setShowModal] = useState(false);
    const [showMicPopup, setShowMicPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedLanguage, sessionID, session } = useSelector((state: IStore) => state.persistState);

    const handleResetUser = async () => {
        try {
            const payload: UserModel.ResetUserPayload = { _id: userId };
            const response = await UserService.ResetUser(payload);
            if (response.success) {
                setShowMicPopup(true);
                setErrorMessage(null);
            } else {
                setErrorMessage(response.message || t('FAILED_TO_RESET_USER'));
            }
        } catch {
            setErrorMessage(t('AN_ERROR_OCCURRED'));
        }
    };

    const handleModal = async () => {
        setShowModal(false);

        await SetOnlineStatus(
            session?._id || "",
            false,
            false,
            (param) => dispatch(AuthenticationActions.UpdateBlockKeysInSession(param)),
            sessionID
        );

        RemoveSessionIdListner(session?._id);
        dispatch(AuthenticationActions.LogoutUser());
        setTimeout(() => history.push("/"), 100);
    };

    return (
        <div className="d-flex flex-column h-100 top-padding">
            <div className="d-flex flex-column justify-content-center px-3 w-100">
                <LanguageSelection selectedLanguage={selectedLanguage} isFromLogin={true} />

                <div className="text-center">
                    <img
                        src="/images/logo_green2.png"
                        width="30%"
                        alt="Logo"
                        className="logo-image"
                    />
                </div>

                <div className="text-white text-center font-size-1-2 pb-5 mt-3">
                    {t("ARE_YOU_SURE_WANT_TO_DELETE_ALL_DATA")}
                    <br />
                    {t("ASSOCIATED_WITH_THIS_ACCOUNT_THIS_CAN_NOT_BE_UNDONE")}
                </div>

                <div className="primaryBtn d-flex flex-column align-items-center justify-content-center my-3 px-3 position-relative">
                    <PrimaryButton
                        className="font-20px titillium-semibold position-relative font-size-2-5 ltr"
                        background="linear-gradient(180deg, #F8C019 2.11%, #FF9E00 128.3%)"
                        borderRadius="30px"
                        color="black"

                        onClick={handleResetUser}
                    >
                        <>
                            <img
                                src="/images/Pointer_LR.gif"
                                style={{ width: "50px", height: "30px" }}
                            />
                            {t("CONTINUE")}
                            <img
                                src="/images/Pointer_RL.gif"
                                style={{ width: "50px", height: "30px" }}
                            />
                        </>
                    </PrimaryButton>
                </div>

                {errorMessage && (
                    <div className="d-flex justify-content-center mt-3 text-danger font-size-1">
                        {errorMessage}
                    </div>
                )}
            </div>

            {showMicPopup && (
                <GenericPopupModal
                    title={t("RESET_USER")}
                    description={t("YOUR_ACCOUNT_DATA_IS_SUCCESSFULLY_DELETED")}
                    showSingleButton
                    firstButtonText={t("CONFIRM")}
                    firstButtonCallback={handleModal}
                />
            )}
        </div>
    );
};

export default DeleteAccount;
