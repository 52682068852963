export enum CONTENT_TYPE {
    IMAGE = 1,
    VIDEO = 2,
    Text = 3
}

export enum MessageStatus  {
    PENDING = "pending",     // message is pending to be sent
    SENT = "sent",           // message is sent to server
    DELIVERED = "delivered", // message is delivered to next user
    READ = "read",           // Message is read by other user
    UNREAD =  "unread"
};

export enum MessageType {
    TEXT = 'text', // text mesage
    IMAGE = 'image', // image message
    AUDIO = 'audio', // audio message
    INVITE = 'invite', // invite message
    GIF = 'gif', // gif message
};

export enum InviteStatusEnums {
    PENDING = "pending",
    SUCCESS = "success"
};

export enum InviteActionsEnum {
    CANCELED = "canceled",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    DEFAULT = "default",
    INVITED="invited"
};

export enum TournamentsEnum {
    EXPIRED = "tournamentExpired",
}

export enum SubscriptionResponseCode {
    API_SUCCESS = 0,
    AUTHORIZATION_ERROR = 100,
    USER_ALREADY_SUBSCRIBED = 102,
    INTERNAL_SERVER_ERROR = 101,
    INVALID_OTP = 103,
    OTP_EXPIRED = 104,
    OPERATOR_UNAVILABLE = 105,
    INVALID_PARAMETERS = 107,
    LAST_OTP_SENT_WAIT = 108,
    LOW_BALANCE = 110,
    MSISDN_BLACKLISTED = 111,
    ACTIVE_SUB = 114,
    INACTIVE_SUB = 115,
    INTERNAL_PIN_FLOW = 130,
  }

export enum AccessResponseStatus {
    NO_STATUS = 0,
    LOW_BALANCE = 1,
    NO_SUBSCRIPTION = 2,
    USER_NOT_FOUND = 3
}

export enum PaymentMethodType {
    EASYPAISA = 'easypaisa',
    MOBILE = 'mobile',
    GPLAY = 'gplay',
}

    export enum MatchStatusType {
    WAITING = 'waiting',
    STARTED = 'started',
    COMPLETED = 'completed',
    PENDING = 'pending',
    CANCELLED = 'cancelled'
}

export enum PlayerStatusType {
    PENDING = 'pending',
    JOINED = 'joined'
}

export const userErrorDummyResponse = {
    createdAt: "",
    fcmKey: "",
    images: [],
    hasBlocked: [],
    isBlockedBy: [],
    isBot: false,
    language: "",
    mesiboToken: "",
    mesiboTokenExpiry: "",
    name: "",
    phone: "",
    userImageUrl: "",
    socailId: "",
    loginType: [],
    paymentMethods: [],
    udid: "",
    __v: 0,
    _id: "",
    sessionId: "",
    interests: [],
    wallet: [],
    recentPlayed: []
}