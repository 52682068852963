import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EnglishLanguage from "./en.json";
import ArabicLanguage from "./ar.json"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: EnglishLanguage
            },
            ar: {
                translation: ArabicLanguage
            }
        },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true
        }
    });

export default i18n;
