import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";

export const CanelTermsWarning: React.FunctionComponent<{onYesClick: ()=>void, onNoClick: ()=>void}> 
    = ({ onYesClick, onNoClick }) => {

    const viewPort = document.getElementById('view-port-container');

    return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={2}>
        <ModalContainer headerChild={<img src="/images/warning.png" />}>
            <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
                <ParagraphText className="text-black text-center font-16px">
                    Your app will exit. Are you sure you want to decline ?
                </ParagraphText>
                <div className="d-flex align-items-center justify-content-center text-white">
                    <div style={{ borderRadius: "27px", width: "70px", height: "32px" }} 
                        className="cursor-pointer bg-green d-flex align-items-center justify-content-center"
                        onClick={() => { onYesClick(); }}>
                        Yes
                    </div>
                    <div className="cursor-pointer d-flex align-items-center justify-content-center text-grey400" 
                        style={{ borderRadius: "27px", width: "70px", height: "32px" }}
                        onClick={() => { onNoClick(); }}>
                        No
                    </div>
                </div>
            </div>
        </ModalContainer>
    </AnimatePopup>,  viewPort ? viewPort : document.body)
}