// #region Local Imports
import { ActionConsts } from "@Definitions";
import { MessageType } from "@Constants";
// #endregion Local Imports

// #region Interface Imports
import {
  IAction,
  IConversationPage,
  CONVERSATION_MESSAGE,
  USER_SESSION,
} from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: IConversationPage.IStateProps = {
  userFirebaseConnectivityStatus: false,
  errors: [],
  conversationOtherUser: <USER_SESSION>{},
  typingUserList: {},
  updatedRoomList: {},
};

export const ConversationReducer = (
  state = INITIAL_STATE,
  action: IAction<IConversationPage.Actions.IMapPlayerVSInfo & any[]>
) => {
  switch (action.type) {
    case ActionConsts.Conversation.UserFirebaseConnectivityStatus: {
      return Object.assign({}, state, {
        userFirebaseConnectivityStatus: action.payload,
      });
    }
 
    case ActionConsts.Conversation.GetConversationError: {
      return Object.assign({}, state, {
        errors: ["Error getting friends list from server"],
      });
    }
    case "inviteReceived": {
      return Object.assign({}, state, {
        inviteReceived: action.payload,
      });
    }
    case "offlineChatMmessage": {
      return Object.assign({}, state, {
        offlineChatMmessage: action.payload,
      });
    }
    case "offlineGameInviteAccepted": {
      return Object.assign({}, state, {
        offlineGameInviteAccepted: action.payload,
      });
    }
    case ActionConsts.Conversation.SetConversationOtherUser: {
      let user: any = action.payload!;
      let otherUser = user.otherUser;
      return Object.assign({}, state, {
        conversationOtherUser: otherUser,
      });
    }
    case ActionConsts.Conversation.UpdateTypingUserList: {
      let updatedList: {} = state.typingUserList;
      let updatedKey = Object.keys(action.payload!);
      let updatedValue = Object.values(action.payload!);
      const typingList = updatedValue.flat();

    //   if (!updatedValue[0]== null) {
    //     //@ts-ignore
    //     delete updatedList[updatedKey];
    //   } else {
        //@ts-ignore
        updatedList[updatedKey] = typingList;
    //   }
    //   console.log("updated List in reducer", updatedList);
      return Object.assign({}, state, {
        typingUserList: updatedList,
      });
    }
    case ActionConsts.Conversation.UpdateChatRoomList:{
      return Object.assign({}, state, {
        updatedRoomList: action.payload
      });
    }

    default:
      return state;
  }
};
