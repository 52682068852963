import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';

import { AnimatePopup, ModalContainer, ParagraphText, InputTransparent, PrimaryButton } from "@Styled";
import { useDispatch, useSelector } from "react-redux";
import { USER_SESSION } from "@Interfaces";
import { AuthenticationActions } from "@Actions/Authentication";
import { useHistory } from "react-router-dom";
import { IStore } from "@Redux/IStore";
import { PersistActions } from "@Actions/PersistActions";
import { SendAnalyticsToFirebase, AnalyticsEventsName } from "@Services/FirebaseEvents";
import './style.scss';
export const LanguageSelection: React.FunctionComponent<{ selectedLanguage: string, isNotAbsolute?: boolean,isFromLogin?: boolean}>
    = ({ selectedLanguage, isNotAbsolute,isFromLogin }) => {
        const dispatch = useDispatch();
        const { i18n } = useTranslation();


        return <div style={{margin:'0 1rem'}} className={"d-flex  language-selection " + (isNotAbsolute ? "" : " position-absolute")}>
            <div className={"language-english " + (selectedLanguage === "en" ? "language-selected" : "")}
                onClick={() => {
                    i18n.changeLanguage('en');
                    dispatch(PersistActions.SetLanguage({ language: 'en', userSelectedLanguage: true }));
                    SendAnalyticsToFirebase(AnalyticsEventsName.LAN_EN);
                    let styleSheet = document.getElementById("ls")
                    if (styleSheet)
                        (styleSheet as any).disabled = true;
                        if(isFromLogin)
                        SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_LANGUAGE_CHANGE);

                }}
            >En</div>
            <div className={"language-ar " + (selectedLanguage === "ar" ? "language-selected" : "")} onClick={() => {
                i18n.changeLanguage('ar');
                dispatch(PersistActions.SetLanguage({ language: 'ar', userSelectedLanguage: true }));
                SendAnalyticsToFirebase(AnalyticsEventsName.LAN_AR);
                let styleSheet = document.getElementById("ls")
                if (styleSheet)
                    (styleSheet as any).disabled = false;
                    if(isFromLogin)
                        SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_LANGUAGE_CHANGE);
            }}>
                العربية
                {/* Ar */}
                </div>
        </div>
    }