// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import {AuthenticationModel, TournamentModel} from "@Interfaces";
import {userErrorDummyResponse} from "@Constants";
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;

export const TournamentHomeService = {
    GetTournamentData: async (
      payload: TournamentModel.GetGETTournamentHistoryPayload
    ): Promise<TournamentModel.GetGETTournamentHomeResponse> => {
        let response: TournamentModel.GetGETTournamentHomeResponse;
        const { userId } = payload;
        try {
            response = await Http.Request<TournamentModel.GetGETTournamentHomeResponse>(
                "GET",
                apiBaseUrl + "/tournaments",
              {userId},
            );
        } catch (error) {
            response = {
                response: {
                    activeTournaments: [],
                    upComing: []
                },
                success: false,
                message: '',
            }
        }
        return response;
    },

    GetTournamentLeaderboard: async (
        payload: TournamentModel.GetGETTournamentLeaderboardPayload
      ): Promise<TournamentModel.GetGETTournamentLeaderboardResponse> => {
          let response: TournamentModel.GetGETTournamentLeaderboardResponse;
          const {tournamentId, userId} = payload;
          try {
              response = await Http.Request<TournamentModel.GetGETTournamentLeaderboardResponse>(
                  "GET",
                  apiBaseUrl + "/tournamentLeaderboard/" + tournamentId + "/" + userId,
                  undefined,
                  );
              } catch (error) {
                  response = {
                      response: {},
                      success: false,
                      message: '',
                  }
              }
              return response;
            },
    GetTournamentHistoryData: async (
        payload: TournamentModel.GetGETTournamentHistoryPayload
    ): Promise<TournamentModel.GetGETTournamentHistoryResponse> => {
        let response: TournamentModel.GetGETTournamentHistoryResponse;
        const { userId } = payload;
        try {
            response = await Http.Request<TournamentModel.GetGETTournamentHistoryResponse>(
                "GET",
                apiBaseUrl + "/userTournamentsHistory/" + userId,
                undefined,
            );
        } catch (error) {
            response = {
                response: {},
                success: false,
                message: '',
            }
        }
        return response;
    },

    GetTournamentClaimHistoryData: async (
        payload: TournamentModel.GetGETTournamentHistoryPayload
    ): Promise<TournamentModel.GetGETTournamentClaimHistoryResponse> => {
        let response: TournamentModel.GetGETTournamentClaimHistoryResponse;
        const { userId } = payload;
        try {
            response = await Http.Request<TournamentModel.GetGETTournamentClaimHistoryResponse>(
                "GET",
                apiBaseUrl + "/wallet/" + userId,
                undefined,
            );
        } catch (error) {
            response = {
                response: [],
                success: false,
                message: '',
            }
        }
        return response;
    },

    ClaimTournamentReward: async (
      payload: TournamentModel.GetPOSTClaimTournamentRewardPayload
    ): Promise<TournamentModel.GetPOSTClaimTournamentRewardResponse> => {
        let response: TournamentModel.GetPOSTClaimTournamentRewardResponse;
        try {
            response = await Http.Request<TournamentModel.GetPOSTClaimTournamentRewardResponse>(
              "POST",
              apiBaseUrl + "/claimRewards",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                response: {},
                success: false,
                message: '',
            }
        }
        return response;
    },

    JoinTournament: async (
      payload: TournamentModel.GetGETTournamentLeaderboardPayload
    ): Promise<AuthenticationModel.GetPATCHUserResponse> => {
        let response: AuthenticationModel.GetPATCHUserResponse;
        try {
            response = await Http.Request<AuthenticationModel.GetPATCHUserResponse>(
              "POST",
              apiBaseUrl + "/tournament/join",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                message: "",
                response: userErrorDummyResponse,
                success: false
            }
        }
        return response;
    },

    TournamentExpire: async (
      payload: TournamentModel.GetPOSTTournamentExpirePayload
    ): Promise<TournamentModel.GetPOSTTournamentExpireResponse> => {
        let response: TournamentModel.GetPOSTTournamentExpireResponse;
        try {
            response = await Http.Request<TournamentModel.GetPOSTTournamentExpireResponse>(
              "POST",
              apiBaseUrl + "/tournament/expire",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                response: {},
                success: false,
                message: '',
            }
        }
        return response;
    },
}