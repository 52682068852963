// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { InviteService } from "@Services/API/Invite";
// #endregion Local Imports

// #region Interface Imports
import { IInvitePage } from "@Interfaces";
// #endregion Interface Imports

export const InviteActions = {
  SearchUser:
    (payload: IInvitePage.Actions.IGetGETSearchUserPayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.SearchUser(payload);

      dispatch({
        payload: {
          users: result.response && result.success ? result.response : [],
        },
        type:
          result.response && result.success
            ? ActionConsts.Invite.GetSearchUsersSuccess
            : ActionConsts.Invite.GetSearchUsersError,
      });
    },
  ClearSearchUser: () => async (dispatch: Dispatch) => {
    dispatch({
      payload: null,
      type: ActionConsts.Invite.ClearSearchUser,
    });
  },
  CreateRoom:
    (payload: IInvitePage.Actions.IGetGETCreateRoomPayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.CreateRoom(payload);
      return result;
    },
  UpdateFacebookFriends:
    (payload: IInvitePage.Actions.IGetPOSTFacebookFriendsPayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.UpdateFacebookFriends(payload);
      return result;
    },
  // GetFacebookFriends: (payload: IInvitePage.Actions.IGetGETFacebookFriendsPayload) => async (
  //     dispatch: Dispatch
  // ) => {
  //     const result = await InviteService.GetFacebookFriends(payload);
  //     return result;
  // },
  SendMatchInvite:
    (payload: IInvitePage.Actions.IGetPOSTMatchInvitePayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.SendMatchInvite(payload);
      return result;
    },
  AcceptMatchInvite:
    (payload: IInvitePage.Actions.IGetPOSTAcceptRejectMatchInvitePayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.AcceptMatchInvite(payload);
      return result;
    },
  RejectMatchInvite:
    (payload: IInvitePage.Actions.IGetPOSTAcceptRejectMatchInvitePayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.RejectMatchInvite(payload);
      return result;
    },
  UpdateGameBoardView:
    (payload: IInvitePage.Actions.IGetPOSTUpdateGameBoardViewPayload) =>
    async (dispatch: Dispatch) => {
      const result = await InviteService.UpdateGameBoardView(payload);
      return result;
    },
};
