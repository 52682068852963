import React from 'react';
// import ReactDOM from 'react-dom';
// import {render} from 'react-snapshot'
import { hydrate, render } from "react-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { makeStore } from "@Redux";
// import withRedux from "next-redux-wrapper";

import { theme } from "@Definitions/Styled";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const store = makeStore({});
const persistor = persistStore(store);

const rootElement: HTMLElement | null = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App  />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App  />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>, rootElement);
}

// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <PersistGate persistor={persistor}>
//           <App  />
//         </PersistGate>
//       </Provider>
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
