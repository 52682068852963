let eventEmitterSingleton: any = null;

export const getBgImageUrl = (_src: string | string[]) => {
    if (typeof _src === 'string') {
        return 'url(' + _src + ')';
    } else {
        let urlStr = '';
        _src.forEach((src, i) => {
            urlStr += 'url(' + (src ? src : 'unknown') + ')' + ( i === _src.length - 1 ? '' : ',' )
        });
        return urlStr;
    }
}
export const ScriptLoader = (callback: any, onError: any) => {
    let loadCount = 0,
    totalRequired = 0;
    // callback: any = callback,
    // onError: any = null;

    function require(scripts: any) {
        loadCount      = 0;
        totalRequired  = scripts.length;
        // callback       = callback;
        // onError        = onError;

        for (var i = 0; i < scripts.length; i++) {
            writeScript(scripts[i]);
        }
    }
    function loaded(evt: any) {
        loadCount++;

        if (loadCount == totalRequired && typeof callback == 'function') callback.call();
    }
    function error(evt: any) {
        if (typeof onError == 'function') onError.call();
    }
    function writeScript(src: any) {
        var s = document.createElement('script');
        s.type = "text/javascript";
        // s.async = false;
        s.src = src;
        s.addEventListener('load', function (e) { loaded(e); }, false);
        s.addEventListener('error', function(e) { error(e); }, false);
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
    }

    return {
        require: require
    }
}

type Listener = (...args: any[]) => void;
interface IEvents { [event: string]: Listener[]; }

class EventEmitter {
    private readonly events: IEvents = {};

    public on(event: string, listener: Listener): () => void {
        if (typeof this.events[event] !== "object") {
            this.events[event] = [];
        }

        this.events[event].push(listener);
        return () => this.removeListener(event, listener);
    }

    public removeListener(event: string, listener: Listener): void {
        if (typeof this.events[event] !== "object") {
            return;
        }

        const idx: number = this.events[event].indexOf(listener);
        if (idx > -1) {
            this.events[event].splice(idx, 1);
        }
    }

    public removeAllListeners(): void {
        Object.keys(this.events).forEach((event: string) =>
            this.events[event].splice(0, this.events[event].length),
        );
    }

    public emit(event: string, ...args: any[]): void {
        if (typeof this.events[event] !== "object") {
            return;
        }

        [...this.events[event]].forEach((listener) => listener.apply(this, args));
    }

    public once(event: string, listener: Listener): () => void {
        const remove: (() => void) = this.on(event, (...args: any[]) => {
            remove();
            listener.apply(this, args);
        });

        return remove;
    }
}

export const EventEmitterSingleton: any = () => {
    if (eventEmitterSingleton) {
        return eventEmitterSingleton;
    } else {
        eventEmitterSingleton = new EventEmitter();
        return eventEmitterSingleton;
    }
}
