// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { DiscoverPeopleService } from "@Services/API/DiscoverPeople";
// #endregion Local Imports

// #region Interface Imports
import { IDiscoverPeoplePage } from "@Interfaces";
// #endregion Interface Imports


export const DiscoverPeopleActions = {
    DiscoverPeople: (payload: IDiscoverPeoplePage.Actions.IGetGETDiscoverPeoplePayload) => async (
        dispatch: Dispatch
    ) => {
        const result = await DiscoverPeopleService.DiscoverPeople(payload);
        return result;        
        // dispatch({
        //     payload: { chatMessages: result.response && result.status ? result.response : [], page: payload.page},
        //     type: result.response && result.status ? ActionConsts.Conversation.GetConversationSuccess : ActionConsts.Conversation.GetConversationError
        // });
    },
    GetUserFriends: (payload: IDiscoverPeoplePage.Actions.IGetGETFriendsPayload) => async (
      dispatch: Dispatch
    ) => {
        const result = await DiscoverPeopleService.GetUserFriends(payload);
        dispatch({
            payload: result.success ? result.response : [],
            type: ActionConsts.Authentication.SetUserFriendsData
        });
        return result;
    }

}