import React, { useEffect, useState } from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export const RouteTransition: React.FunctionComponent<{}> 
    = ({ children }) => {
    
    return (
        <ReactCSSTransitionGroup
            component="div"
            className="d-flex align-items-center justify-content-center w-100 h-100"
            transitionAppear={true}
            transitionAppearTimeout={600}
            transitionEnterTimeout={600}
            transitionLeaveTimeout={300}
            transitionName={'SlideIn'}
        >
                { children }
        </ReactCSSTransitionGroup>
    )
}
