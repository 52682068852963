import React from "react";
import styled, { keyframes } from "styled-components";
import {string} from "prop-types";

const slideInFromTop = keyframes`
    0% { transform: translate3d(0, -500px, 0) }
    100% { transform: translate3d(0, 0, 0) }
`

const slideInToTop = keyframes`
    0% { transform: translate3d(0, 0, 0) }
    10% { transform: translate3d(0, -20px, 0) }
    20% { transform: translate3d(0, -40px, 0) }
    100% { transform: translate3d(0, -200px, 0) }
`

const Container = styled.div<{ animateIn: boolean, background?: string, zIndex?: number, width?: string, alignItems?: string }>`
    background: ${({ background }) => { return background ? background : 'black' }};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    width:  ${({ width }) => { return width ? width : '100%' }};
    align-items: ${({ alignItems }) => { return alignItems ? alignItems : 'center' }};
    justify-content: center;
    animation-name: ${({ animateIn }) => { return animateIn ? slideInFromTop : slideInToTop }};
    transform: translate3d(0, 0, 0);
    animation-duration: .15s;
    animation-timing-function: ease-in;
    z-index: ${({ zIndex }) => { return zIndex ? zIndex : 'initial' }};
`;

export const AnimatePopup: React.FunctionComponent<{ animateIn: boolean, background?: string, zIndex?: number, width?: string, alignItems?: string}> = ({ children, ...props }) => {
    return <Container {...props}>
        {children}
    </Container>
}