// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import {GAME_CONFIG, GAME_ITEM, IAction, PLAYERS, IMultiGamePlayState, MATCH_MAKING_TEMPLATE} from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: IMultiGamePlayState.IStateProps = {
    selectedGameConfig: new Array<GAME_CONFIG>(),
    selectedGameToPlay: <GAME_ITEM>{},
    specificSelectedConfiguration: <MATCH_MAKING_TEMPLATE>{},
    isHost: true,
    showGameTypeView: false,
    matchObject: {
        matchId: "",
        roomId: "",
        gameId: -1,
        players: new Array<PLAYERS>(),
        allPlayers: new Array<PLAYERS>()
    }
};

export const MultiGameReducer = (
    state = INITIAL_STATE,
    action: IAction<
      IMultiGamePlayState.Actions.ISetSelectedGameConfig &
      IMultiGamePlayState.Actions.ISetSelectedGameToPlay &
      IMultiGamePlayState.Actions.ISetSpecificSelectedGameConfiguration &
      IMultiGamePlayState.Actions.ISetMatchObject &
      IMultiGamePlayState.Actions.ISetShowGameTypeView &
      IMultiGamePlayState.Actions.ISetIsHost>
) => {
    switch (action.type) {
        case ActionConsts.MultiPlayerGame.SetSelectedGameConfig: {
            const selectedGameConfig = action.payload!;

            return Object.assign({}, state, {
                selectedGameConfig: selectedGameConfig
            });
        }
        case ActionConsts.MultiPlayerGame.SetSelectedGameToPlay: {
            const selectedGameToPlay = action.payload!;            

            return Object.assign({}, state, {
                selectedGameToPlay: selectedGameToPlay
            });
        }
        case ActionConsts.MultiPlayerGame.SetSpecificSelectedConfiguration: {
            const specificSelectedConfiguration = action.payload!;

            return Object.assign({}, state, {
                specificSelectedConfiguration: specificSelectedConfiguration
            });
        }
        case ActionConsts.MultiPlayerGame.SetMatchObject: {
            const matchObject = action.payload!;

            return Object.assign({}, state, {
                matchObject: {...state.matchObject, ...matchObject},
        });
        }
        case ActionConsts.MultiPlayerGame.SetIsHost: {
            const isHost = action.payload;

            return Object.assign({}, state, {
                isHost: isHost,
            });
        }
        case ActionConsts.MultiPlayerGame.SetShowGameTypeView: {
            const showGameTypeView = action.payload;

            return Object.assign({}, state, {
                showGameTypeView: showGameTypeView,
            });
        }
        case ActionConsts.MultiPlayerGame.ResetGameConfigAndMatchObject: {
          const matchObj = {matchId: "", players: [], allPlayers: []}
            return Object.assign({}, state, {
                isHost: true,
                showGameTypeView: false,
                selectedGameToPlay: {},
                selectedGameConfig: [],
                specificSelectedConfiguration: {},
                matchObject: {...state.matchObject, ...matchObj},
            })
        }
        default:
            return state;
    }
}
