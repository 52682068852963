import { LanguageSelection } from "@Components/LanguageSelection/LanguageSelection";
import { LoginWithFacebook } from "@Components/LoginWithFacebook";
import { IStore } from "@Interfaces";
import React, { lazy, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AnalyticsEventsName,
  firebaseExternalLogin,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import { AuthenticationActions } from "@Actions/Authentication";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { getServiceofRegionIfAny } from "@HelperFunctions";
import { AuthenticationService } from "@Services/API/Authentication";
import { ActionConsts } from "@Definitions/ActionConsts";
// import LoginContainer from '@Containers/Log';

const LoginContainer: any = lazy(
  () => import("../../Containers/Login" /* webpackChunkName: "Login" */)
);

const MultiLogin: React.FunctionComponent<{
  setLoginBackground:(a:string)=>void
  fcmToken: string;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
  isFirebaseInitilized: boolean;
  loginWithMobile:boolean;
  setLoginWithMobile:(a: boolean)=>void
}> = ({ fcmToken, setShowSubscriptionSuccessfull, isFirebaseInitilized,setLoginBackground,setLoginWithMobile,loginWithMobile }) => {
  // const [loginWithMobile, setLoginWithMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginOptionsSmall, setLoginOptionsSmall] = useState([
    {
      type: "TWITTER",
      icon: "twitter_icon",
      text: "CONTINUE_WITH_TWITTER",
      backgroundColor: "#1877F2",
    },
    {
      type: "PHONE",
      icon: "mobile_icon",
      text: "CONTINUE_WITH_PHONE",
      backgroundColor: "#FFC700",
    },
  ]);

  const [loginOptionsBig, setLoginOptionsBig] = useState([
    {
      type: "FACEBOOK",
      icon: "",
      text: "CONTINUE_WITH_FACEBOOK",
      backgroundColor: "#1877F2",
    },
    {
      type: "GOOGLE",
      icon: "google_icon",
      text: "CONTINUE_WITH_GOOGLE",
      backgroundColor: "#fff",
    },
  ]);

  const history: any = useHistory();
  const persistState = useSelector((state: IStore) => state.persistState);
  const {inviRedirectionUrl}=useSelector((state: IStore) => state.persistState)
  const {session, selectedLanguage, loginSequence, persistFirebaseConfiguration } =
    persistState;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSessionOutModal, setShowSessionOutModal] = useState(
    (window as any).userSessionTokenChanged
      ? (window as any).userSessionTokenChanged
      : true
  );
  let loginOrder=loginSequence;
  if(!loginOrder){
    loginOrder='PGFT';
  }
  const isCountryMorocco=persistFirebaseConfiguration.country.country_code == "ma";
  if(isCountryMorocco)
  loginOrder ='p'
  useEffect(() => {

    if (session._id) {
      history.push("/");
      return;
    }

    if (isFirebaseInitilized) {
      setIsLoading(false);
      if (loginOrder) {
        if (
          loginOrder.length === 1 &&
          loginOrder[0].toLocaleLowerCase() === "p"
        ) {
          if(!isCountryMorocco)
          setLoginWithMobile(true);
        }
        let options: any = [];
        loginOrder.split("").forEach((character: string) => {
          if (character.toLocaleLowerCase() === "f")
            options.push({
              type: "FACEBOOK",
              icon: "",
              text: "CONTINUE_WITH_FACEBOOK",
              backgroundColor: "#1877F2",
            });
          if (character.toLocaleLowerCase() === "g")
            options.push({
              type: "GOOGLE",
              icon: "google_icon",
              text: "CONTINUE_WITH_GOOGLE",
              backgroundColor: "#fff",
            });
          if (character.toLocaleLowerCase() === "t")
            options.push({
              type: "TWITTER",
              icon: "twitter_icon",
              text: "CONTINUE_WITH_TWITTER",
              backgroundColor: "#1877F2",
            });
          if (character.toLocaleLowerCase() === "p")
            options.push({
              type: "PHONE",
              icon: "mobile_icon",
              text:
              isCountryMorocco
                  ? "SUBSCRIBED_WITH_PHONE"
                  : "CONTINUE_WITH_PHONE",
              backgroundColor: "#FFC700",
            });
        });
        if (options.length === 0) {
          setLoginOptionsBig([
            {
              type: "PHONE",
              icon: "mobile_icon",
              text: "CONTINUE_WITH_PHONE",
              backgroundColor: "#FFC700",
            },
          ]);
          setLoginOptionsSmall([]);
        } else {
          setLoginOptionsBig(options.slice(0, 2));
          setLoginOptionsSmall(options.slice(2, 4));
        }
      }
    }
  }, [isFirebaseInitilized, loginOrder]);
//  useEffect(()=>{
  
//   if(isCountryMorocco){
   
//     const fetchInviUrl=async()=>{
//       if(persistFirebaseConfiguration?.regions){
//         const service:any=getServiceofRegionIfAny('ma',persistFirebaseConfiguration?.regions)
//         if(service){
//           const result:any = await AuthenticationService.getInwiRedirectionUrl(
//             {serviceId:service.serviceId}
//           );
//           if(result){
//             if(result.data.redirectionUrl)
//             dispatch({
//               payload:result.data.redirectionUrl,
//               type: ActionConsts.Authentication.SetInviRedirectionUrl
//             })
//           }
//         }
//       }
//     }
//     fetchInviUrl()
//   }
 
// },[])
  const getMarkUp = (
    index: number,
    type: string,
    text: string,
    icon: string,
    backgroundColor: string
  ) => {
    if (type === "PHONE") {
      if (index < 2)
        return (
          <div
            key={index}
            className="d-flex flex-column align-items-center mt-3 w-100"
            onClick={() => {
              SendAnalyticsToFirebase(
                AnalyticsEventsName.LOGIN_WITH_PHONE_PRESSED
              );
              // if(inviRedirectionUrl !=''){
              //   window.open(inviRedirectionUrl,'_self');
              //   return;
              // }
              setLoginWithMobile(true);            
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center px-4 py-2 cursor-pointer social-login-button-width"
              style={{ borderRadius: "25px", background: backgroundColor }}
            >
              <img height="28px" src={`/images/${icon}.png`} />
              <div
                style={{ minWidth: "200px", color: "#1D0141" }}
                className="text-center text-white font-20px titillium-semibold ml-2"
              >
                {t(text)}
              </div>
            </div>
          </div>
        );
      else
        return (
          <div
            key={index}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <img
              className="px-3 cursor-pointer mt-3 mx-2"
              onClick={() => {
                SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_PHONE_PRESSED);
                // if(inviRedirectionUrl !=''){
                //   window.open(inviRedirectionUrl,'_self');
                //   return;
                // }
                setLoginWithMobile(true);
                
              }}
              src={`/images/${icon}.png`}
            />
            <span className="text-white">{t("PHONE")}</span>
          </div>
        );
    } else
      return (
        <LoginWithFacebook
          key={index}
          backgroundColor={backgroundColor}
          showShortIcon={index > 1 && true}
          type={type}
        />
      );
  };
  if((window as any).userSessionTokenChanged && showSessionOutModal){
    setLoginBackground("bg-login-image");
  }
  return (
    <>
      <LoadingGIF showLoading={isLoading}>
        {!loginWithMobile ? (
          <div className="d-flex flex-column h-100 top-padding">
            <div className="d-flex flex-column justify-content-center px-3 w-100">
              <LanguageSelection
                selectedLanguage={selectedLanguage}
                isFromLogin={true}
              />
              <div className="text-center">
                <img src="/images/logo_green2.png" width="30%" height="100%" />
              </div>
              <br />
              <div className="text-white text-center font-size-1-2" style={{ paddingBottom : "5%"}}>
                {/* {t("PLAY_MULTIPLAYER_GAMES_WITH_FRIENDS_AND_VOICE_CHAT")} */}
                {t("PLAY_MULTIPLAYER_GAMES_WITH")}
                <br />
                {t("FRIENDS_AND_VOICE_CHAT")}
              </div>
              <div className="login-image"></div>

              {
                <div
                  className={
                    "login-component " +
                    (loginOptionsBig.length === 1 ? "mt-4" : "")
                  }
                >
                  {loginOptionsBig.map((option: any, index) => {
                    return getMarkUp(
                      index,
                      option.type,
                      option.text,
                      option.icon,
                      option.backgroundColor
                    );
                  })}
                  <div className="d-flex">
                    {loginOptionsSmall.map((option: any, index) => {
                      return getMarkUp(
                        index + 2,
                        option.type,
                        option.text,
                        option.icon,
                        option.backgroundColor
                      );
                    })}
                  </div>
                </div>
              }
            </div>
          </div>
        ) : (
          <LoginContainer
          setLoginBackground={setLoginBackground}
            fcmToken={fcmToken}
            setShowSubscriptionSuccessfull={setShowSubscriptionSuccessfull}
            isFirebaseInitilized={isFirebaseInitilized}
            loginSequence={[
              ...loginOptionsBig.filter((ls) => ls.type !== "PHONE"),
              ...loginOptionsSmall.filter((ls) => ls.type !== "PHONE"),
            ]}
            isCountryMoroco={isCountryMorocco}
            setLoginWithMobile={(flag: boolean) => {
              if (!flag) {
                setLoginBackground("bg-login-image")
              }
              setLoginWithMobile(flag)}}
          />
        )}
      </LoadingGIF>
      {(window as any).userSessionTokenChanged && showSessionOutModal && (
        <GenericPopupModal
          title={t("LOGGED_OUT")}
          description={t("LOGGED_OUT_MSG")}
          showSingleButton={true}
          showInfoIcon={true}
          firstButtonText={t("OK")}
          firstButtonCallback={() => {
            (window as any).userSessionTokenChanged = false;
            setShowSessionOutModal(false);
          }}
        />
      )}
    </>
  );
};

export default MultiLogin;
