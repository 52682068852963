// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import {
    ConversationModel,
    POSTMarkMessageAsReadPayload,
    POSTMarkMessageAsReadResponse,
    UploadMediaFilesModel
} from "@Interfaces";
import { getQueryParams } from "@Services/Cookie";
// #endregion Interface Imports

const apiBaseUrl = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL;
const apiBaseUrlChatV1 = process.env.REACT_APP_IS_PRODUCTION_API === "true" ? process.env.REACT_APP_CHAT_API_URL_V1 : process.env.REACT_APP_CHAT_STAGING_API_URL_V1;

export const ConversationService = {
    GetConversation: async (
        payload: ConversationModel.GetGETConversationPayload
    ): Promise<ConversationModel.GetGETConversationResponse> => {
        let response: ConversationModel.GetGETConversationResponse;

        const { userId, ...rest } = payload;
        try {
            response = await Http.Request<ConversationModel.GetGETConversationResponse>(
                "GET",
                "/api/conversation/user/" + userId + getQueryParams({ ...rest }),
                undefined
            );
        } catch (error) {
            response = {
                status: false,
                response: [{
                    text: "Hahhshs",
                    id: 0
                }, {
                    text: "Hahhshs Ohoooo",
                    id: 1
                }, {
                    text: "Hahhshs kskksk",
                    id: 2
                }]
            }
        }
        return response;
    },
    UploadMediaOnStorage: async (
        payload: UploadMediaFilesModel.GetUploadMediaFilesPayload
    ): Promise<UploadMediaFilesModel.GetUploadMediaFilesResponse> => {
        let response: UploadMediaFilesModel.GetUploadMediaFilesResponse;
        try {
            const { formData, apiPath } = payload;
            response = await Http.UploadFile<UploadMediaFilesModel.GetUploadMediaFilesResponse>(
                "POST",
                apiBaseUrl + (apiPath ? apiPath : "/chat/media"),
                undefined,
                formData
            );
        } catch (error) {
            response = {
                message: "Media Successfully uploaded..",
                messageId: "1603822243",
                response: "5f8976d9f3456267bfc2c221_bad.jpg",
                success: false
            };
        }
        return response;
    },
    FetchEmojis: async (): Promise<any> => {
        let response: any;
        try {
            response = await Http.RequestWithoutToken<any>(
                "GET",
                "https://api.giphy.com/v1/gifs/trending?api_key=0dK98w2yPTpmQZTsve0kofgTVptst9zP&limit=20",
                undefined
            );
            // response = await fetch("http://api.giphy.com/v1/gifs/trending?api_key=0dK98w2yPTpmQZTsve0kofgTVptst9zP&limit=20")
            // response = await response.json();
        } catch (error) {
            response = {
                message: "Media Successfully uploaded..",
                messageId: "1603822243",
                response: "5f8976d9f3456267bfc2c221_bad.jpg",
                success: false,
                data: []
            };
        }
        return response;
    },
    SendFirebaseOfflineMessage: async (
        payload: ConversationModel.GetPOSTSendFirebaseOfflineMessagePayload
    ): Promise<ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse> => {
        let response: ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse;
        try {
            response = await Http.Request<ConversationModel.GetPOSTSendFirebaseOfflineMessageResponse>(
                "POST",
                apiBaseUrl + "/notification/notification",
                undefined,
                { ...payload }
            );
        } catch (error) {
            response = {
                message: "",
                success: false
            };
        }
        return response;
    },
    SendMessageOnServer: async (
      payload: ConversationModel.GetPOSTSendMessagePayload
    ): Promise<ConversationModel.GetPOSTSendMessageResponse> => {
        // let response: ConversationModel.GetPOSTSendMessageResponse;
        let response: any;
        try {
            response = await Http.Request<ConversationModel.GetPOSTSendMessageResponse>(
              "POST",
              apiBaseUrlChatV1 + "/messages/create",
              undefined,
              { ...payload },
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                data: {lastMessageId: -1}
            };
        }
        return response;
    },

    ReceiveAllMessageFromServer: async (
      payload: ConversationModel.GetGETReceiveAllMessagePayload
    ): Promise<ConversationModel.GetGETReceiveAllMessageResponse> => {
        // let response: ConversationModel.GetGETReceiveAllMessageResponse;
        let response: any;
        const roomId = payload
        try {
            response = await Http.Request<ConversationModel.GetGETReceiveAllMessageResponse>(
            "GET",
            apiBaseUrlChatV1 + "/messages/getAll/",
              {
                  roomId: roomId,
              },
              undefined,
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                data: []
            };
        }
        return response;
    },

    ReceiveMessageFromServer: async (
      payload: ConversationModel.GetGETReceiveMessageByIdPayload
    ): Promise<ConversationModel.GetGETReceiveAllMessageResponse> => {
        let response: ConversationModel.GetGETReceiveAllMessageResponse;
    // console.log('payload *************', payload)
        // const { lastMessageId, roomId,fetchLatest } = payload;
        // let payloadObj={};
        // if(lastMessageId && fetchLatest){
        //     payloadObj={
        //         lastMessageId: lastMessageId,
        //         roomId: roomId,
        //         fetchLatest:fetchLatest
        //     }
        // }
        // else if()
        // {
        //     payloadObj={
        //         roomId: roomId,
        //     }
        // }
        const {lastMessageId, roomId, fetchLatest} = payload
        try {
            response = await Http.Request<ConversationModel.GetGETReceiveAllMessageResponse>(
              "GET",
              apiBaseUrlChatV1 + "/messages/getLatest/",
              {
                roomId: roomId,
                lastMessageId: lastMessageId!,
                fetchLatest: fetchLatest!
            }
            );
        } catch (error) {
            response = {
                message: "",
                success: false,
                data: []
            };
        }
        return response;
    },

    CheckTyping: async (
      payload: ConversationModel.GetPOSTCheckTypingPayload
    ): Promise<ConversationModel.GetPOSTCheckTypingResponse> => {
        let response: ConversationModel.GetPOSTCheckTypingResponse;
        try {
            response = await Http.Request<ConversationModel.GetPOSTCheckTypingResponse>(
              "POST",
              apiBaseUrlChatV1 + "/rooms/users/isTyping/",
              undefined,
            {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                response_code: -1
            };
        }
        return response;
    },

    MarkMessageAsRead: async (
      payload: ConversationModel.GetPOSTMarkMessageAsReadPayload
    ): Promise<ConversationModel.GetPOSTMarkMessageAsReadResponse> => {
        let response: ConversationModel.GetPOSTMarkMessageAsReadResponse;
        try {
            response = await Http.Request<ConversationModel.GetPOSTMarkMessageAsReadResponse>(
              "POST",
              apiBaseUrlChatV1 + "/recipient/messages/markIsRead",
              undefined,
              {...payload}
            );
        } catch (error) {
            response = {
                success: false,
                response_code: -1
            };
        }
        return response;
    },


}