// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, ILoginPage } from "@Interfaces";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { VerificationScreen } from "@Services/API/VerificationScreens/VerificationScreen";
// #endregion Interface Imports

const INITIAL_STATE: ILoginPage.IStateProps = {
  errors: [],
  firebaseConfig: {
    regions: [],
  },
  userGeoLocation: null,
  utmParameters: {
    compaign_id: "",
    tracking_code: "",
    compaign_name: "",
    medium: "",
  },
  // paymentBundles: new Array<Array<SUB_BUNDLES>>(),
  configurationUpdated:false,
  verificationConfigData: undefined,
};

export const LoginReducer = (
  state = INITIAL_STATE,
  action: IAction<ILoginPage.Actions.IMapLoginErrorResponse>
) => {
  switch (action.type) {
    case ActionConsts.Authentication.SetFirebaseConfigError: {
      return Object.assign({}, state, {
        firebaseConfig: {
          regions: [],
        },
      });
    }
    // case ActionConsts.Authentication.SetFirebaseConfigSuccess: {
    //     console.log("in login");
    //     return Object.assign({}, state, {
    //         firebaseConfig: {
    //             regions: (action.payload as any).regions
    //         }
    //     });
    // }
    case ActionConsts.Authentication.UserLoginError: {
      const { error } = action.payload!;

      return Object.assign({}, state, {
        errors: [error],
      });
    }
    case ActionConsts.Authentication.ClearLoginError: {
      return Object.assign({}, state, { errors: [] });
    }
    case ActionConsts.Settings.UserGeoLocation: {
      return Object.assign({}, state, { userGeoLocation: action.payload });
    }
    case ActionConsts.Authentication.SetUTMParams: {
      const payloadData = action.payload as any;
      // console.log("UTM PARAMS",payloadData)
      return Object.assign({}, state, {
        utmParameters: {
          compaign_id: payloadData.compaign_id,
          tracking_code: payloadData.tracking_code,
          compaign_name: payloadData.compaign_name,
          medium: payloadData.medium,
        },
      });
    }
    // case ActionConsts.Authentication.SetPaywallData: {
    //   const data = action.payload as any;
    //   return Object.assign({}, state, {
    //     paymentBundles: data.paymentTypes,
    //   });
    // }
    case ActionConsts.Authentication.SetVerificationConfigData: {
      const data = action.payload as VerificationScreen
      return Object.assign({}, state, {
        verificationConfigData: data,
      });
    }
    // case ActionConsts.Authentication.SetSingleServiceData: {
    //   const data = action.payload as any;

    //   let isDataPushedInArray = false;
    //   let dublicateArray = state.paymentBundles;

    //   let outerServiceObject = data.data;
    //   let service: SUB_BUNDLES = outerServiceObject.services;

    //   service.numLimit = outerServiceObject.numLimit;
    //   service.operatorCode = outerServiceObject.operatorCode;
    //   service.operatorImage = outerServiceObject.operatorImage;
    //   service.titleAr = data.titleAr;
    //   service.titleEng = data.titleEng;
    //   service.paymentType = service.paymentType.toLowerCase();

    //   state.paymentBundles.forEach((payment, index) => {
    //     if (
    //       payment.length > 0 &&
    //       payment[0].package.toLowerCase() === service.package?.toLowerCase()
    //     ) {
    //       isDataPushedInArray = true;
    //       dublicateArray[index].push(service);
    //     }
    //   });

    //   if (!isDataPushedInArray) dublicateArray.push([service]);

      
    //   return Object.assign({}, state, {
    //     paymentBundles: dublicateArray,
    //   });
    // }
    case ActionConsts.Authentication.UpdateFirebaseConfig:{
      return Object.assign({}, state, {
        configurationUpdated: action.payload
      })
    }
   
    default:
      // return action.type === ActionConsts.Authentication.LogoutUserSuccess ?
      //     Object.assign({}, INITIAL_STATE, { userGeoLocation: state.userGeoLocation, firebaseConfig: state.firebaseConfig })
      //     : state;
      return state;
  }
};
