import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ParagraphText, PrimaryButton } from "@Styled";
import { useTranslation } from 'react-i18next';
import styles from "./style.module.scss";
import {TOURNAMENT_NOTIFICATION_DATA} from "@Interfaces";
import {getPositionSupText} from "@HelperFunctions";


const ConfirmButton: React.FunctionComponent<{onClick: () => void}> = ({onClick}) => {
  return (
    <div className="position-relative">
      <div className={`text-white titillium-semibold text-center cursor-pointer ${styles.addButtonText}`}
           style={{background: 'linear-gradient(180deg, #FECF0F 0%, #E37130 100%)'
      }} onClick={onClick}>
        COLLECT
      </div>
      <img src="/images/newDesign/ellipse.png" width="7" alt="ellipse" className={styles.imageEllipse}/>
    </div>)
}

export const TournamentResultNotification: React.FunctionComponent<{ onRejectClick: () => void, onAcceptClick: () => void, tournamentNotificationData: TOURNAMENT_NOTIFICATION_DATA }>
  = ({ onRejectClick, onAcceptClick, tournamentNotificationData }) => {

  const [showIgnoreWarning, setShowIgnoreWarning] = useState(false);
  const { t } = useTranslation();
  const vouchersLength = tournamentNotificationData && tournamentNotificationData.vouchers?.length;

  const viewPort = document.getElementById('view-port-container');

  return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={1}>
    <div className={`w-100 ${styles.tournamentRewardContainer}`}>
    <div className="d-flex flex-column w-100 bg-primary"
         style={{
           borderRadius: "30px",
           backgroundImage: `url('/images/tournament/notification_image.png')`,
           backgroundPosition: "center",
           backgroundSize: "cover",
           height: "auto",
           minHeight: "300px",
           border: "6px solid #C336F4"
           }}>
      <img src="/images/tournament/notification-ellipse.png" alt="ellipse" width={75} style={{position: "absolute", marginTop: '-1px'}}/>
      <div className="d-flex flex-column py-4 px-3 text-white align-items-center justify-content-center">
        {!vouchersLength && <div className="d-flex w-100 justify-content-end align-items-center cursor-pointer"
              onClick={() => {
                onRejectClick();
              }}>
          <img width="22px" src="/images/cancel.png" className={styles.cancelButtonStyle}/>
        </div>}

        <div className="font-18px titillium-web "> Tournament Ended </div>
        <div className="position-relative my-3">
          <img src={`${tournamentNotificationData?.thumbnail}`} alt="" width={75} style={{borderRadius: '7px'}}/>
          <div className="position-absolute font-12px"
               style={{borderRadius: '40px', backgroundColor: "#8A0FD9", top: '0', padding: '0 8px'}}>
            {tournamentNotificationData?.rank || 0} <sup> {getPositionSupText(tournamentNotificationData?.rank || 0)} </sup>
          </div>
        </div>
        <div className="pt-2 titillium-semibold font-20px">
          {vouchersLength ? "CONGRATULATIONS" : "YOU TRIED BEST"}
        </div>
        <div className="titillium-web font-16px">
          {vouchersLength ? "YOU HAVE WON" : "Play more tournaments to win exciting prizes"}
        </div>
        {!!vouchersLength && <div className={"py-4 d-flex w-100 " + styles.justifyContentSpaceEvenly}>
          {tournamentNotificationData?.vouchers?.map((voucher, index) => {
            return <div key={index} className="d-flex flex-column align-items-center">
              <img src={`${voucher.voucherImage}`} alt="prize" style={{borderRadius: '8px'}} width={75}/>
              <div className="font-12px pt-2"> {voucher.name} </div>
            </div>
          })}
        </div>}
        <div className="d-flex flex-column align-items-center justify-content-center">
          {!!vouchersLength && <ConfirmButton onClick={onAcceptClick}/>}
        {/*<br />*/}
      </div>
      </div>
    </div>
    </div>
  </AnimatePopup>, viewPort ? viewPort : document.body)
}