// #region Global Imports
import { combineReducers } from "redux";
// #endregion Global Imports

// #region Local Imports
// import { HomeReducer } from "./home";
import { HomeReducer } from "./Home";
import { PersistReducer } from "./PersistReducer";
import { LoginReducer } from "./Login";
import { MessagesReducer } from "./Messages";
import { ConversationReducer } from "./Conversation";
import { InviteReducer } from "./Invite";
import { LeaderBoardReducer } from "./LeaderBoard";
import {ExclusiveFeaturesReducer} from "./ExclusiveFeatures";
import {MultiGameReducer} from "./MultiGamePlayReducer";
import {TournamentReducer } from "./Tournament"
// #endregion Local Imports

export default combineReducers({
    home: HomeReducer,
    login: LoginReducer,
    messages: MessagesReducer,
    invite: InviteReducer,
    conversation: ConversationReducer,
    persistState: PersistReducer,
    leaderBoard: LeaderBoardReducer,
    exclusiveFeature:ExclusiveFeaturesReducer,
    multiPlayState: MultiGameReducer,
    tournamentState: TournamentReducer,
});
