import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { SendAnalyticsToFirebase,AnalyticsEventsName } from '@Services/FirebaseEvents';


import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";

export const LowBalanceModal: React.FunctionComponent<{onYesClick: ()=>void}> 
    = ({ onYesClick }) => {

        useEffect(() => {
            SendAnalyticsToFirebase(AnalyticsEventsName.LOW_BALANCE)
        },[])

    const viewPort = document.getElementById('view-port-container');

    return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={1}>
        <ModalContainer headerChild={<img src="/images/warning.png" />}>
            <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
                <ParagraphText className="text-black text-center font-16px">
                    You have low balance. Please recharge account to continue
                </ParagraphText>
                <div className="d-flex align-items-center justify-content-center text-white">
                    <div style={{ borderRadius: "27px", width: "70px", height: "32px" }} 
                        className="cursor-pointer bg-green d-flex align-items-center justify-content-center"
                        onClick={() => { onYesClick(); }}>
                        Ok
                    </div>
                </div>
            </div>
        </ModalContainer>
    </AnimatePopup>,  viewPort ? viewPort : document.body)
}