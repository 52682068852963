// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { GameService } from "@Services/API/Games";
// #endregion Local Imports

// #region Interface Imports
import { GAME_CATEGORY, IHomePage } from "@Interfaces";
import flatten from "lodash/flatten";
// #endregion Interface Imports

export const HomeActions = {
  SetUserHasLowBalance: (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.Home.SetUserHasLowBalance,
    });
  },
  GetGamesList:
    (payload: IHomePage.Actions.IGetGETGamesListPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.GetGamesList(payload);

      dispatch({
        payload: {
          gamesList: result.response && result.success ? result.response : [],
        },
        type:
          result.response && result.success
            ? ActionConsts.Home.GetGamesListSuccess
            : ActionConsts.Home.GetGamesListError,
      });
      dispatch({
        payload: result.response && result.success ? true : false,
        type: ActionConsts.Home.GameListLoaded,
      });
    },
  GetAppDesignGamesList:
    (
      payload: IHomePage.Actions.IGetGETGamesListPayload,
      recentlyPlayedGameIds: number[]
    ) =>
    async (dispatch: Dispatch) => {
      let result = await GameService.GetAppDesignGamesList(payload);
      if (payload.pageNo == undefined || payload.pageNo === 1) {
        dispatch({
          payload: {
            gamesList: result && result.success ? result.response : [],
          },
          type:
            result.response && result.success
              ? ActionConsts.Home.GetAppDesignGamesListSuccess
              : ActionConsts.Home.GetGamesListError,
        });
      } else {
        //if page is greater than 1 , then we have to append single player games in existing array
        dispatch({
          payload: {
            gamesList: result.response && result.success ? result.response : [],
          },
          type:
            result.response && result.success
              ? ActionConsts.Home.GetAppDesignGamesListAppend
              : ActionConsts.Home.GetGamesListError,
        });
      }
      dispatch({
        payload: {
          gamesListLoaded: result.response && result.success ? true : false,
          homePageNo: payload.pageNo,
        },
        type: ActionConsts.Home.GameListLoaded,
      });

      if (result.extraData?.configUpdated) {
        dispatch({
          type: ActionConsts.Authentication.UpdateFirebaseConfig,
          payload: true,
        });
      }

      localStorage.setItem("ipn", payload.pageNo + "");
    },
  RequestMatchMaking:
    (payload: IHomePage.Actions.IGetPOSTMatchMakingRequestPayload) =>
    async (dispatch: Dispatch) => {
      const result = GameService.RequestMatchMaking(payload);
      return result;
    },
    GetMatch: (payload: IHomePage.Actions.IGetGETMatchPayload) => async (
      dispatch: Dispatch
    ) => {
        const result = GameService.GetMatch(payload);
        return result;
    },

    StartMatchOnRequest: (payload: IHomePage.Actions.IGetPOSTPlayGameOnRequestPayload) => async (
      dispatch: Dispatch
    ) => {
        const result = GameService.PlayGameOnRequest(payload);
        return result;
    },
    GetGameDocStr:
      (payload: IHomePage.Actions.IGetGETGameDocStrPayload) =>
        async (dispatch: Dispatch) => {
            const result = await GameService.GetGameDocStr(payload);
            return result;
        },
  CancelMatchMaking:
    (payload: IHomePage.Actions.IGetPOSTCancelMatchMakingPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.CancelMatchMaking(payload);
      return result;
    },
  SaveMatchResult:
    (payload: IHomePage.Actions.IGetPOSTSaveResultPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.SaveMatchResult(payload);
      return result;
    },
  PlayAgain:
    (payload: IHomePage.Actions.IGetPOSTPlayAgainPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.PlayAgain(payload);
      return result;
    },
  InviteToPlayAgain:
    (payload: IHomePage.Actions.IGetPOSTInvitePlayAgainPayload) =>
      async (dispatch: Dispatch) => {
        const result: IHomePage.Actions.IGetPOSTInvitePlayAgainResponse = await GameService.InviteToPlayAgain(payload);

        dispatch({
          payload: {
            matchId: result.response && result.success ? result.response.matchId : ""},
            type: ActionConsts.MultiPlayerGame.SetMatchObject
        });
        dispatch({
          payload: {
            isHost: result.response && result.success},
            type: ActionConsts.MultiPlayerGame.SetIsHost
        });

        return result;
      },
  CancelPlayAgain:
    (payload: IHomePage.Actions.IGetPOSTCancelPlayAgainPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.CancelPlayAgain(payload);
      return result;
    },
  SearchGames:
    (payload: IHomePage.Actions.IGetGETSearchGamesPayload) =>
    async (dispatch: Dispatch) => {
      const result = await GameService.SearchGames(payload);
      return result;
    },
  GetGameResultFromServer: (payload: any) => async (dispatch: Dispatch) => {
    const result = await GameService.GetGameResultFromServer(payload);
    return result;
  },
  SetShowGameBoard: (payload: any) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.Home.SetShowGameBoard,
    });
  },
  LeaveGame: (payload: IHomePage.Actions.IGetPOSTLeaveMatchPayload) => async (
      dispatch: Dispatch
    ) => {
      const result = await GameService.LeaveMatch(payload);
      return result;
  },
};
