import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';

import { AnimatePopup, ModalContainer, ParagraphText, InputTransparent, PrimaryButton } from "@Styled";
import { useDispatch, useSelector } from "react-redux";
import { USER_SESSION } from "@Interfaces";
import { AuthenticationActions } from "@Actions/Authentication";
import { useHistory } from "react-router-dom";
import { IStore } from "@Redux/IStore";
import { PersistActions } from "@Actions/PersistActions";
import { SendAnalyticsToFirebase, AnalyticsEventsName } from "@Services/FirebaseEvents";

export const SetNameModal: React.FunctionComponent<{ user: USER_SESSION, showDummyName?: boolean, setShowNoNameModal?: (a: boolean) => void }>
    = ({ user, showDummyName, setShowNoNameModal }) => {
        const [error, setError] = useState<string>('');
        const [name, setName] = useState<string>('');
        const viewPort = document.getElementById('view-port-container');
        const persistState = useSelector((state: IStore) => state.persistState);
        const dispatch = useDispatch();
        const history = useHistory();
        const { t } = useTranslation();

        const saveName = async () => {
            const updateResponse: any = await dispatch(AuthenticationActions.UpdateUser({
                _id: user._id,
                name: name,
                isWeb: true,
                configVersion: persistState.persistFirebaseConfiguration.v
            }));
            if (updateResponse.success && updateResponse.response) {
                dispatch(PersistActions.SetUserHasNoName(false));
                setShowNoNameModal && setShowNoNameModal(false);
                !showDummyName &&
                    history.push(persistState.redirectAfterLoginRoute, { prevRoute: "/" });
            }
            else
                setError('Error updating name');
        }
        useEffect(() => {
            if (showDummyName) {
                try {
                    let dummyName = user.phone.slice(4) + user.phone.slice(user.phone.length - 4);
                    let sum = dummyName.split('').map(Number).reduce(function (a, b) { return a + b });
                    setName("user " + sum);
                }
                catch (ex) {
                    console.log(ex);
                }
                SendAnalyticsToFirebase(AnalyticsEventsName.NEW_USER_NAME_POPUP_TRIGGER);
            }
        }, [])
        useEffect(() => {
            setError('');
        }, [name]);

        return ReactDOM.createPortal(<AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={10} >
            <ModalContainer>
                <div className="d-flex flex-column text-black">
                    <ParagraphText className="font-24px text-green titillium-semibold text-center">
                        {t('USER_NAME')}
                    </ParagraphText>
                    {/* <br/> */}
                    <InputTransparent onChange={(e) => { setName(e.target.value) }}
                        placeholder={t('NAME') + '*'}
                        className="text-black"
                        borderColor="#AEAEAE"
                        type="text"
                        value={name}
                        size={20}
                        maxLength={20}
                        textAlign="start"
                        pattern="[^A-Za-z0-9]+"
                        lineHeight="30px"
                        width="100%"
                        color="black" />
                    {/* <br/> */}
                    <PrimaryButton className="bg-green text-white titillium-semibold mt-4"
                        onClick={() => {
                            name ? saveName() : setError("Name can't be empty");
                        }}>
                        {t('SAVE')}
                    </PrimaryButton>
                    {error.length > 0 && <ParagraphText className="font-12px text-danger text-center">
                        {error}
                    </ParagraphText>}
                </div>
            </ModalContainer>
        </AnimatePopup>, viewPort ? viewPort : document.body)
    }