// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { MessagesService } from "@Services/API/Messages";
// #endregion Local Imports

// #region Interface Imports
import { IMessagesPage } from "@Interfaces";
import chatManager from "@Services/ChatManager/ChatManager";
import { addListnerOnRooms } from "@Services/FirebaseEvents";
// #endregion Interface Imports

export const MessagesActions = {
  GetFriends:
    (payload: IMessagesPage.Actions.IGetGETMessagesListPayload) =>
    async (dispatch: Dispatch) => {
      const { type, page } = payload;
      const result = await MessagesService.GetFriends(payload);

      if (type === 1) {
        dispatch({
          payload: {
            recipients:
              result.success && result.response ? result.response : [],
            page: page,
          },
          type:
            result.success && result.response
              ? ActionConsts.Messages.GetStrangerListSuccess
              : ActionConsts.Messages.GetStrangerListError,
        });
      }
      if (type === 0) {
        dispatch({
          payload: {
            recipients:
              result.success && result.response ? result.response : [],
            page: page,
          },
          type:
            result.success && result.response
              ? ActionConsts.Messages.GetFriendsListSuccess
              : ActionConsts.Messages.GetFriendsListError,
        });

        // console.log("chat manger whrn api", chatManager);
        // chatManager.addRoomsIfNeededFromAPI(result.response)
        // addListnerOnRooms(result.response)
        dispatch({
          payload: result.response,
          type: ActionConsts.Messages.SaveRooms,
        });
        chatManager.addRoomsIfNeededFromAPI(result.response);
        // addListnerOnRooms
        // dispatch({
        //     payload:result.response,
        //     type:ActionConsts.Messages.SaveRooms

        // })
      }
      return result;
    },
  GetRoomById: (payload: IMessagesPage.Actions.IGetGETRoomByIdPayload) =>
    async (dispatch: Dispatch) => {
      const result = await MessagesService.GetRoomById(payload);

      return result;
    },
  BlockUser:
    (payload: IMessagesPage.Actions.IGetPATCHBlockUserPayload) =>
    async (dispatch: Dispatch) => {
      const result = await MessagesService.BlockUser(payload);

      return result;
    },
  ReportRoom:
    (payload: IMessagesPage.Actions.IGetPOSTReportRoomPayload) =>
    async (dispatch: Dispatch) => {
      const result = await MessagesService.ReportRoom(payload);

      return result;
    },
  UpdateUnseenMessageInFotter:
    (payload: number) => async (dispatch: Dispatch) => {
      dispatch({
        payload: { currentCount: payload },
        type: ActionConsts.Messages.UpdateUnseenMessageInFotter,
      });
    },
  UnfriendUser: (payload: any) => async (dispatch: Dispatch) => {
    return await MessagesService.UnfriendUser(payload);
  },
    GetNewFriendRequest:
    (payload: boolean) => async (dispatch: Dispatch) => {
      dispatch({
        payload: payload,
        type: ActionConsts.Messages.GetNewFriendsRequest,
      });
    },
};
