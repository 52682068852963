// import firebase from "firebase";
import { AuthenticationActions } from "@Actions/Authentication";
import { MessagesActions } from "@Actions/Messages";
import { useDispatch } from "react-redux";
import { ActionConsts } from "@Definitions/ActionConsts";
import { store } from "@Redux";
import { matchMakingResponseMapping } from "@HelperFunctions";
import chatManager from "@Services/ChatManager/ChatManager";
import { reduceRight } from "lodash";
import { MESSAGING_ROOM } from "@Services/API/Messages/GETMessagesListResponse";
import ChatManager from "@Services/ChatManager/ChatManager";
import { PersistActions } from "@Actions/PersistActions";

let firebase: any = {};

let invitesRefrencesList: any = [];

let messaging: any = {};
export const AnalyticsEventsName = {
  // SET USER NAME FOR NEW USER
  ON_SAVE_USER_NAME: "new_user_save_name",

  // TERMS AND CONDITIONS
  ON_TERMS_N_CONDITION_ACCEPTED: "terms_and_conditions_accepted",
  ON_TERMS_N_CONDITION_DECLINE: "terms_and_conditions_decline",

  // ON PHONE SCREEN
  ON_ENTER_PHONE_NUMBER: "enter_phone_number",
  ON_SUBMIT_PHONE: "submit_phone",
  ON_NUMBER_SUCCESS_ERROR: "phone_screen_success_error",

  // ON PIN SCREEN
  ON_PIN_ENTER: "pin_enter",
  ON_CONFIRM_PIN_SUCCESS: "confirm_pin_success",
  ON_CONFIRM_PIN_FAIL: "confirm_pin_fail",
  ON_PIN_RESET_RESEND: "pin_reset_resend",

  // MULTIPLAYER SCREEN
  ON_MP_GAME_ITEM_PRESSED: "mp_game_item_pressed",
  ON_MP_GAME_ITEM_CROSSED: "mp_game_item_cross_pressed",
  // ON_MP_GAME_DOWNLOADING_START : "mp_game_downloading_start",
  // ON_MP_GAME_DOWNLOADING_SUCCESS : "mp_game_downloading_success",
  // ON_MP_GAME_DOWNLOADING_ERROR_CANCEL : "mp_game_downloading_error_cancel",

  // MULTIPLAYER SCREEN
  // ON_MP_GAME_ITEM_WITH_NAME_PRESSED : "mp_%s_pressed",
  // ON_MP_GAME_ITEM_WITH_NAME_CROSSED : "mp_%s_cross_pressed",
  // ON_MP_GAME_WITH_NAME_DOWNLOADING_START : "mp_%s_downloading_start",
  // ON_MP_GAME_WITH_NAME_DOWNLOADING_SUCCESS : "mp_%s_downloading_success",
  // ON_MP_GAME_WITH_NAME_DOWNLOADING_ERROR_CANCEL : "mp_%s_downloading_error_cancel",

  // MATCH MAKING SCREEN
  // GAME_COUNT : "game_played",
  // ACTUAL_GAME_COUNT : "played_with_real_user",
  ON_MATCH_END: "match_end",
  ON_MP_WAITING_EXIT: "quit_from_mp_waiting_screen",
  ON_REMATCH: "rematch",
  // ON_INVITE_ACCEPT : "invite_accepted",
  // ON_INVITE_REJECT : "invite_rejected",
  // ON_INVITATION_EXPIRE : "invite_expire",
  ON_INVITE_SENT: "invite_sent",

  // FACEBOOK LOGIN
  ON_FB_LOGIN_SUCCESS: "fb_login_success",
  ON_FB_LOGIN_FAILED: "fb_login_failed",

  // CONTACT SYNC
  ON_CONTACT_SYNC_SUCCESS: "contact_sync_success",
  ON_CONTACT_SYNC_FAILED: "contact_sync_failed",

  // ON PROFILE VIEW
  EDIT_PROFILE: "edit_profile",
  PROFILE_VIEW: "profile_view",

  // ON SINGLE PLAYER
  ON_SP_GAME_ITEM_PRESSED: "sp_game_item_pressed",
  // ON_SP_GAME_END : "sp_game_end",
  // ON_SINGLE_PLAYER_PLAYED: 'sp_%s_played',

  // ON CHAT
  // CHAT_MSG : "chat_messages",
  USE_CHAT: "use_chat_feature",
  // ******   yet to decide *******

  // LEADERBOARD
  // ON_OPEN_LEADERBOARD : "on_leaderboard_pressed",

  ON_OPEN_DISCOVERPEOPLE: "discover_people_ui_opened",
  // GET_PEOPLE_IN_AREA : "get_people_in_area",

  SHARE_LINK_WHATSAPP: "share_link_via_whatsapp",
  // SHARE_LINK_MESSAGE : "share_link_via_message",
  SHARE_LINK_MESSENGER: "share_link_via_messenger",
  // SHARE_LINK : "share_link",

  // PRESS EVENTS
  INVITE_FRIENDS_PRESSED: "invite_friends_pressed",
  DISCOVER_PEOPLE_PRESSED: "discover_people_pressed",
  // PLAY_HISTORY_PRESSED: 'play_history_pressed',
  SETTINGS_PRESSED: "settings_pressed",
  FEEDBACK_PRESSED: "feedback_pressed",
  BLOCKED_USERS_PRESSED: "blocked_users_pressed",
  ABOUT_US_PRESSED: "about_us_pressed",
  // SINGLE_PLAYER_GAMES_SEARCH_PRESSED: 'single_player_games_search_pressed',
  CHAT_LIST_CELL_PRESSED: "chat_list_cell_pressed",
  // CHAT_MENU_PRESSED: 'chat_menu_pressed',
  REPORT_USER_PRESSED: "report_user_pressed",
  BLOCK_USER_PRESSED: "block_user_pressed",

  OPEN_EMOJIS_PRESSED: "open_emojis_pressed",
  OPEN_GAMES_FROM_CHAT_PRESSED: "open_games_from_chat_pressed",
  MIC_PRESSED: "mic_pressed",
  OPEN_GIFS_PRESSED: "opne_gifs_pressed",
  // OPEN_GALLERY_PRESSED:'open_gallery_pressed',
  SEND_MESSAGE_PRESSED: "send_message_pressed",

  TAB_HOME_PRESSED: "tab_home_pressed",
  TAB_CHAT_PRESSED: "tab_chat_pressed",
  TAB_PROFILE_PRESSED: "tab_profile_pressed",
  TAB_LEADER_BOARD_PRESSED: "tab_leader_board_pressed",
  PURCHASE: "purchase",
  DIRECT_LOGIN: "direct_login",

  LOW_BALANCE: "low_balance",

  NEW_USER_NAME_POPUP_TRIGGER: "new_user_name_popup_triggerd",

  // Jitsi Call events

  JOIN_CALL_PRESSED: "join_call_pressed",
  CALL_JOINED: "call_joined",
  MUTE_AUDIO: "mute_audio",
  UNMUTE_AUDIO: "unmute_audio",
  LEAVE_CALL_PRESSED: "leave_call_pressed",
  CALL_LEAVED: "call_leaved",
  LAN_EN: "lan_en",
  LAN_AR: "lan_ar",
  BANNER_SUCCESS_TO_REDIRECT: "banner_success_to_redirect",
  BANNER_FAIL_TO_REDIRECT: "banner_fail_to_redirect",
  USER_CLOSED_REDIRECT_BANNER: "user_closed_redirect_banner",
  // APP_REOPEN:'app_reopen',
  // PUSH_NOTIFICATION_CLICK:'push_notification_click',
  // CHAT_COUNT:'chat_count',

  //  Subscription Events
  //    ELIGIBLE_FOR_SUBSCRIPTION_BUT_EMPTY_MCC:"eligible_for_subscription_but_mcc_empty",
  //    PHONE_SCREEN_OPENED:"phone_number_screen_opened",
  //    INVALID_NUMBER_ENETERED:"invalid_number_entered",
  //    SEND_PIN_PRESSED:"send_pin_pressed",
  //    SEND_PIN_SUCCESS:"send_pin_success",
  //    SEND_PIN_FAILED:"send_pin_failed",
  //    CONFIRM_PIN_SCREEN_OPENED:"confirm_pin_screen_opened",
  //    CONFIRM_PIN_PRESSED:"confirm_pin_pressed",
  //    CONFIRM_PIN_SUCCESS:"confirm_pin_success",
  //    CONFIRM_PIN_FAIL:"confirm_pin_fail",
  //    RESEND_PIN_PRESSED:"resend_pin_pressed",
  //    SUBSCRIDED_SUCCESSFULLY:"subscribed_successfully",

  // Login Screen Events
  LOGIN_WITH_FACEBOOK_PRESSED: "web_login_with_fb_pressed",
  LOGIN_WITH_FACEBOOK_SUCCESS: "web_login_with_fb_success",
  LOGIN_WITH_FACEBOOK_FAILED: "web_login_with_fb_failed",
  LOGIN_WITH_GOOGLE_PRESSED: "web_login_with_g_pressed",
  LOGIN_WITH_GOOGLE_SUCCESS: "web_login_with_g_success",
  LOGIN_WITH_GOOGLE_FAILED: "web_login_with_g_failed",
  LOGIN_WITH_TWITTER_PRESSED: "web_login_with_t_pressed",
  LOGIN_WITH_TWITTER_SUCCESS: "web_login_with_t_success",
  LOGIN_WITH_TWITTER_FAILED: "web_login_with_t_failed",
  LOGIN_WITH_PHONE_PRESSED: "web_login_with_p_pressed",
  LOGIN_SEND_PIN_PRESSED: "web_login_send_pin_pressed",
  LOGIN_SEND_PIN_SUCCESS: "web_login_send_pin_success",
  LOGIN_SEND_PIN_FAILED: "web_login_send_pin_failed",
  SUBSCRIPTION_AVAILABLE_AT_LOGIN: "web_sub_available_at_login",
  LOGIN_CONFIRM_PIN_PRESSED: "web_login_confirm_pin_pressed",
  LOGIN_CONFIRM_PIN_SUCCESS: "web_login_confirm_pin_success",
  LOGIN_CONFIRM_PIN_FAILED: "web_login_confirm_pin_failed",
  LOGIN_SUBSCRIPTION_PIN_PRESSED: "web_login_sub_pin_pressed",
  LOGIN_SUBSCRIPTION_PIN_SUCCESS: "web_login_sub_pin_success",
  LOGIN_SUBSCRIPTION_PIN_FAILED: "web_login_sub_pin_failed",
  LOGIN_USER_SUBSCRIBED: "web_login_user_subscribed",
  LOGIN_PHONE_RESEND_PIN: "web_login_phone_resend_pin",
  LOGIN_EDIT_PHONE: "web_login_edit_phone",
  LOGIN_INVALID_PIN_CODE: "web_login_invalid_pin_code",
  LOGIN_SUBSCRIPTION_INVALID_PIN_CODE: "web_login_sub_invalid_pin_code",
  FIREBASE_LOGIN_INIT_FROM_SERVER: "web_fbase_auth_login_init_from_server",
  FIREBASE_LOGIN_INIT_FROM_PLATFORM: "web_fbase_auth_login_init_from_platform",
  FIREBASE_LOGIN_SEND_PIN_PRESSED: "web_fbase_auth_login_send_pin_pressed",
  FIREBASE_LOGIN_SEND_PIN_SUCCESS: "web_fbase_auth_login_send_pin_success",
  FIREBASE_LOGIN_SEND_PIN_FAILED: "web_fbase_auth_login_send_pin_failed",
  FIREBASE_LOGIN_CONFIRM_PIN_PRESSED:
    "web_fbase_auth_login_confirm_pin_pressed",
  FIREBASE_LOGIN_CONFIRM_PIN_SUCCESS:
    "web_fbase_auth_login_confirm_pin_success",
  FIREBASE_LOGIN_CONFIRM_PIN_FAILED: "web_fbase_auth_login_confirm_pin_failed",
  LOGIN_LANGUAGE_CHANGE: "web_login_language_change",
  HOME_SCREEN_TOURNAMENT_BANNER_PRESSED:
    "home_screen_tournament_banner_pressed",
  HOME_SCREEN_STCPLAY_BANNER_PRESSED: "home_screen_stcplay_banner_pressed",
  MULTIPLAYER_VIEW_MORE_PRESSED: (categoryName: string) =>
    `${categoryName}_view_more_pressed`,
  GENRE_PRESSED: (categoryName: string | undefined, genreName: string) =>
    `${categoryName}_genre_${genreName}_pressed`,
  DOWNLOAD_APP_BUTTON: "Download_App_Button",

  // Paywall Screen Events
  PAYWALL_OPENED: "paywall_screen_opened",
  PAYWALL_SKIPPED: "paywall_skipped",
  PAY_WITH_METHOD_SELECTED: (paymentType: string, packageType: string) =>
    `web_${paymentType}_${packageType}_selected`,
  PAY_WITH_METHOD_SERVICE_NOT_AVAILABLE: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_service_not_available`,
  PAY_WITH_METHOD_SEND_PIN_PRESSED: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_send_pin`,
  PAY_WITH_METHOD_SEND_PIN_SUCCESS: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_send_pin_success`,
  PAY_WITH_METHOD_SEND_PIN_FAILED: (paymentType: string, packageType: string) =>
    `web_${paymentType}_${packageType}_send_pin_failed`,
  PAY_WITH_METHOD_CONFIRM_PIN_PRESSED: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_confirm_pin`,
  PAY_WITH_METHOD_CONFIRM_PIN_SUCCESS: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_confirm_pin_success`,
  PAY_WITH_METHOD_CONFIRM_PIN_FAILED: (
    paymentType: string,
    packageType: string
  ) => `web_${paymentType}_${packageType}_confirm_pin_failed`,
  PAY_WITH_METHOD_EDIT_PHONE: (paymentType: string, packageType: string) =>
    `web_${paymentType}_${packageType}_edit_phone`,
  PAY_WITH_METHOD_RESEND_PIN: (paymentType: string, packageType: string) =>
    `web_${paymentType}_${packageType}_resend_pin`,

  PAY_WITH_DCB_SELECTED: (packageType: string) =>
    `web_pay_with_dcb_${packageType}_selected`,
  // DCB_PROCESS_START: (packageType: string) =>
  //   `dcb_${packageType}_process_start`,
  // DCB_PURCHASED: (packageType: string) => `dcb_${packageType}_purchased`,
  // DCB_FAILED: (packageType: string) => `dcb_${packageType}_failed`,

  // Tournament Events
  TAB_TOURNAMENTS_PRESSED: "tab_tournaments_pressed",
  TOURNAMENT_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_pressed`,
  TOURNAMENT_JOIN_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_join_pressed`,
  TOURNAMENT_PLAY_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_play_pressed`,
  TOURNAMENT_PREPLAY_BACK_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_pre_play_back_pressed`,
  TOURNAMENT_LEADERBOARD_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_leaderboard_pressed`,
  TOURNAMENT_MATCH_MAKING_EXIT: (tournamentName: string) =>
    `${tournamentName}_tournament_match_making_exit_pressed`,
  TOURNAMENT_GAME_PLAY: (tournamentName: string) =>
    `${tournamentName}_tournament_game_play`,
  TOURNAMENT_GAME_PLAY_EXIT: (tournamentName: string) =>
    `${tournamentName}_tournament_game_play_exit`,
  TOURNAMENT_VIEW_LEADERBOARD: (tournamentName: string) =>
    `${tournamentName}_tournament_view_leaderboard_result_pressed`,
  TOURNAMENT_PLAY_AGAIN_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_play_again_pressed`,
  TOURNAMENT_GAMEINFO_PRESSED: (tournamentName: string) =>
    `${tournamentName}_tournament_game_info_pressed`,
  TOURNAMENT_CHAT_PRESSED: "tournament_chat_pressed",
  TOURNAMENT_WALLET_PRESSED: "tournament_wallet_pressed",
  TOURNAMENT_CLAIMED_HISTORY_PRESSED: "tournament_claimed_history_pressed",
  TOURNAMENT_REWARD_INFO_PRESSED: "tournament_reward_info_pressed",
  TOURNAMENT_INFO_PRESSED: "tournament_info_pressed",
  TOURNAMENT_HISTORY_PRESSED: "tournament_history_pressed",

  MULTIPLAYER_WITH_REAL_USER: "multiplayer_with_real_user",
  MULTIPLAYER_WITH_BOT: "multiplayer_with_bot",
};

export const SendAnalyticsToFirebase = (eventName: string, params?: any) => {
  try {
    let mergedParams = getGenericBundleParams();

    const _eventName = eventName.replace(/ /g, "_");
    // if (process.env.REACT_APP_IS_PRODUCTION_API === "true") {
    if (params) {
      mergedParams = { ...params, ...mergedParams };
    }

    firebase.analytics().logEvent(_eventName, mergedParams);
    console.log("SendAnalyticsToFirebase", eventName);
    // }
  } catch (error) {
    //    console.log("AnalyticsEvents.js Error " + eventName, error);
  }
};

export const getGenericBundleParams = () => {
  let params: any = {
    platform: "web",
  };

  try {
    let user = store.getState().persistState.session;

    if (user != null) {
      if (user.phone && user.phone.length > 0) params.user_phone = user.phone;
    }
    if ((window as any).userSubscriptionStatus != null)
      params.sub_status = (window as any).userSubscriptionStatus;
  } catch (err) {
    console.log("SendAnalyticsToFirebase error", err);
  }
  return params;
};

export const setUserIdentityOnFirebase = () => {
  try {
    let user = store.getState().persistState.session;
    const defaultAppAnalytics = firebase.analytics();

    if (user) {
      defaultAppAnalytics.setUserId(user._id);

      let properties: any = {
        id: user._id,
      };
      if (user.phone && user.phone.length > 0) properties.phone = user.phone;

      if (user.fbId && user.fbId != "") properties.fbId = user.fbId;

      defaultAppAnalytics.setUserProperties(properties);
      // console.log("setting user identity properties", properties);
    }
  } catch (error) {
    console.log("Error in setting user identity properties", error);
  }
};

export const InitilizeFirebase = async () => {
  return new Promise(async (resolve, reject) => {
    if (!firebase.apps) {
      const _firebase = await import("firebase");
      firebase = _firebase.default;
      firebase.initializeApp({
        apiKey:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "AIzaSyC6PzkI025jETWLjzlUGe0PbD5u8kUtCeg"
            : "AIzaSyBUUEmcG0xJraY0n9ho3FiK8u_0_oTvmH0",
        authDomain:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "gago-dcbe8.firebaseapp.com"
            : "gagi-stagging.firebaseapp.com",
        databaseURL:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "https://gago-dcbe8.firebaseio.com"
            : "https://gagi-stagging-default-rtdb.firebaseio.com",
        projectId:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "gago-dcbe8"
            : "gagi-stagging",
        storageBucket:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "gago-dcbe8.appspot.com"
            : "gagi-stagging.appspot.com",
        messagingSenderId:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "908976407755"
            : "1010770973883",
        appId:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "1:908976407755:web:9c2320ed5aa0600a4c074e"
            : "1:1010770973883:web:6c0af28d23c0540446859e",
        measurementId:
          process.env.REACT_APP_IS_PRODUCTION_API === "true"
            ? "G-L5B04JHBZ6"
            : "G-QR5Q78DRZY",
      });
      let uid: any = null;

      let event = firebase
        .auth()
        .onAuthStateChanged(async (firebaseUser: any) => {
          //console.log("authState change", firebaseUser);
          if (firebaseUser) uid = firebaseUser.uid;
          else {
            const fireBaseUser: any = await firebase.auth().signInAnonymously();
            if (fireBaseUser.user) {
              uid = fireBaseUser.user.uid;
            }
          }
          if (uid) initMessaging();
          resolve(uid);
        });
      // return uid;
      // const fireBaseUser: any = await firebase.auth().signInAnonymously();
      // if (fireBaseUser.user) {
      //   initMessaging();
      //   return fireBaseUser.user.uid;
      // }
      // return null;
    }
  });
};

const OnAuthStateChanged = (
  user: any,
  userId: string,
  updateBlockInSession: (a: { key: string; val: string[] }) => void,
  sessionId: string
) => {
  return new Promise((resolve, reject) => {
    if (!user) {
      try {
        SetDatabaseConnectionListner(userId, updateBlockInSession, sessionId)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      } catch ({ message }) {
        reject(false);
      }
    } else {
      reject(null);
    }
  });
};

export const ObserveAuth = (
  userId: string,
  updateBlockInSession: (a: { key: string; val: string[] }) => void,
  sessionID: string
) => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((firebaseUser: any) => {
      OnAuthStateChanged(firebaseUser, userId, updateBlockInSession, sessionID)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  });
};

export const SetOnlineStatus = (
  userId: string,
  onlineStatus: boolean,
  addDisconnectListner = true,
  updateBlockInSession: (a: { key: string; val: string[] }) => void,
  sessionId: string
) => {
  return new Promise((resolve, reject) => {
    let refernce = firebase.database().ref("users/" + userId);
    let isFirebaseListnerAdded = (window as any).isFirebaseInitilized;
    if (
      (isFirebaseListnerAdded === undefined || !isFirebaseListnerAdded) &&
      userId != "" &&
      onlineStatus
    ) {
      (window as any).isFirebaseInitilized = true;
      addListnerOnUserNode(userId, updateBlockInSession);
      AddSessionIdListner(userId, sessionId);
    }
    refernce
      .update({
        isOnline: onlineStatus,
        lastActiveTime: firebase.database.ServerValue.TIMESTAMP,
      })
      .then(() => {
        resolve(true);
      })
      .catch((e: any) => {
        reject(false);
      });

    if (addDisconnectListner)
      refernce.onDisconnect().update(
        {
          isOnline: false,
          lastActiveTime: firebase.database.ServerValue.TIMESTAMP,
        },
        (e: any) => {
          console.log("Firebase online status onDisconnect");
        }
      );
  });
};

export const addListnerOnUserNode = (
  userId: string,
  updateBlockInSession: (a: { key: string; val: string[] }) => void
) => {
  setUserIdentityOnFirebase();
  firebase
    .database()
    .ref("users/" + userId + "/hasBlocked")
    .on("value", (snapshot: any) => {
      if (snapshot.exists()) {
        const snapshotData = snapshot.val();
        updateBlockInSession({
          key: "hasBlocked",
          val: snapshotData,
        });
      } else {
        updateBlockInSession({ key: "hasBlocked", val: [] });
      }
    });

  firebase
    .database()
    .ref("users/" + userId + "/isBlockedBy")
    .on("value", (snapshot: any) => {
      if (snapshot.exists()) {
        const snapshotData = snapshot.val();
        updateBlockInSession({
          key: "isBlockedBy",
          val: snapshotData,
        });
      } else {
        updateBlockInSession({ key: "isBlockedBy", val: [] });
      }
    });
};

export const SetDatabaseConnectionListner = async (
  userId: string,
  updateBlockInSession: (a: { key: string; val: string[] }) => void,
  sessionId: string
) => {
  return new Promise(async (resolve, reject) => {
    if (firebase.apps) {
      // const fireBaseUser: any = await firebase.auth().signInAnonymously();
      // if (fireBaseUser.user) {
      firebase
        .database()
        .ref(".info/connected")
        .on("value", (snapshot: any) => {
          if (snapshot.val() && userId) {
            // console.log("Connection listner");
            SetOnlineStatus(userId, true, true, updateBlockInSession, sessionId)
              .then((res) => {
                resolve(true);
              })
              .catch((res: any) => {
                reject(false);
              });
          }
          // if (!userId) resolve(fireBaseUser.user.uid);
        });
      //} else {
      // ObserveAuth(userId, updateBlockInSession, sessionId)
      //   .then((res: any) => {
      //     resolve(res);
      //   })
      //   .catch((res) => {
      //     reject(res);
      //   });
      // }
    } else {
      reject(false);
    }
  });
};

export const AddOnlineEventListnerWithOpponent = (
  userId: string,
  onlineCallback: any
) => {
  try {
    let refernce = firebase.database().ref("users/" + userId + "/isOnline");

    // refernce.on('value', snapshot => {
    //
    //     if (snapshot.exists() && userId) {
    //
    //         console.log('FIRE.JS Oppenent Online Status is ', snapshot.val());
    //         onlineCallback(snapshot.val());
    //     }
    // });
    refernce.on("value", onlineCallback);
  } catch (e) {
    (async () => {
      const fireBaseUser = await firebase.auth().signInAnonymously();

      if (fireBaseUser.user) {
        AddOnlineEventListnerWithOpponent(userId, onlineCallback);
      }
    })();
  }
};

export const AddSessionIdListner = (userId: string, localSessionID: string) => {
  let userNumberFromZong = localStorage.getItem("phoneFromZong")!;
  let tokenFromZong = localStorage.getItem("tokenFromZong")!;

  if (!userNumberFromZong && !tokenFromZong) {
    try {
      let refernce = firebase.database().ref("users/" + userId + "/sessionId");
      refernce.on("value", (snapshot: any) => {
        if (snapshot.exists()) {
          let state = store.getState();
          let firebaseSessionID = snapshot.val();
          // debugger;
          if ((window as any).IS_DIRECT_LOGIN || (window as any).SOCIAL_LOGIN) {
            localSessionID = state.persistState.sessionID;
          }
          if (localSessionID != firebaseSessionID) {
            // set this flag to show popup on loginScreen that you are logged out
            (window as any).userSessionTokenChanged = true;
            // when user is forced logout
            (window as any).isFirebaseInitilized = false;
            // console.log("SocketResponse",(window as any).gameSocket);
            (window as any).gameSocket?.disconnect();
            // console.log("SocketResponse",(window as any).gameSocket);
            (window as any).gameSocket?.close();
            (window as any).gameSocket = null;
            // width is updated for landspcape games
            let vpc = document.getElementById("view-port-container");
            if (vpc) {
              vpc.style.width = "600px";
              vpc.style.maxWidth = "600px";
            }

            try {
              (window as any).FB?.logout((logoutResponse: any) => {
                if (logoutResponse.status === "connected")
                  console.log("loggedIn");
                else console.log("notLoggedIn");
              });
            } catch (error) {
              console.log("Logging Out error", error);
            }
            RemoveSessionIdListner(userId);
            // logout User
            store.dispatch(AuthenticationActions.LogoutUser());
            store.dispatch({
              payload: undefined,
              type: ActionConsts.Authentication.LogoutUserSuccess,
            });
          }
          (window as any).IS_DIRECT_LOGIN = false;
          (window as any).SOCIAL_LOGIN = false;
        }
      });
    } catch (e) {
      (async () => {
        const fireBaseUser = await firebase.auth().signInAnonymously();

        if (fireBaseUser.user) {
          AddSessionIdListner(userId, localSessionID);
        }
      })();
    }
  }
};

export const RemoveSessionIdListner = async (userId: string) => {
  console.log("Session removed");
  let refernce = firebase.database().ref("users/" + userId + "/sessionId");
  refernce.off("value");
  await removeValueListners(userId);
};

export const removeValueListners = (userId: string) => {
  let isBlockedByRefernce = firebase
    .database()
    .ref("users/" + userId + "/isBlockedBy");
  isBlockedByRefernce.off("value");
  let hasBlockedRefernce = firebase
    .database()
    .ref("users/" + userId + "/hasBlocked");
  hasBlockedRefernce.off("value");

  let receivedRequestsRefernce = firebase
    .database()
    .ref("users/" + userId + "/receivedRequests");
  receivedRequestsRefernce.off("value");

  let friendsRefernce = firebase.database().ref("users/" + userId + "/friends");
  friendsRefernce.off("value");

  let sentRequestsRefernce = firebase
    .database()
    .ref("users/" + userId + "/sentRequests");
  sentRequestsRefernce.off("value");
  removeEventListnerOnInvites(userId);
};

export const RemoveOnlineEventListnerWithOpponent = (
  opponentUserId: string
) => {
  let refernce = firebase
    .database()
    .ref("users/" + opponentUserId + "/isOnline");
  refernce.off("value");
};

export const RemoveEventListnerOnUserNode = (userId: string) => {
  let refernce = firebase.database().ref("users/" + userId);
  refernce.off("value");
};

export const FetchBlockedUsersInfoFromFirebase = (
  blockedUsersIds: string[]
) => {
  return new Promise((resolve, reject) => {
    blockedUsersIds.length <= 0 && resolve([]);

    const blockedUsersList: any = [];
    for (let i = 0; i < blockedUsersIds.length; i++) {
      firebase
        .database()
        .ref("users/" + blockedUsersIds[i])
        .once("value")
        .then((snapshot: any) => {
          let snapData = snapshot.val();
          let profileData = {
            name: snapData.name,
            profileImage: snapData.profileImage,
            id: snapData._id,
            fb_image: snapData.fb_image,
            is_bot: snapData.is_bot,
          };
          blockedUsersList.push(profileData);

          if (i === blockedUsersIds.length - 1) {
            resolve(blockedUsersList);
          }
        })
        .catch((error: any) => {
          reject([]);
        });
    }
  });
};

const setNewRequest = () => {
  store.dispatch(MessagesActions.GetNewFriendRequest(true));
};

export const FriendRequestListner = (
  userId: string,
  urlName: string,
  callBack: any
) => {
  (window as any).urlName = true;
  let refernceFriends = firebase
    .database()
    .ref("users/" + userId + "/" + urlName);
  refernceFriends.on("value", (snapshot: any) => {
    // if (snapshot.exists()) callBack(snapshot.val());
    // else
    callBack(snapshot.val());
    let state = store.getState();
    let user = state.persistState.session;
    let persistState = store.getState().messages;
    if (urlName === "receivedRequests") {
      if (snapshot.val()?.length > persistState.newFriendReqArray?.length) {
        setNewRequest();
      }
      store.dispatch(
        PersistActions.GetNewFriendReqArray(
          snapshot.val()?.length ? snapshot.val() : []
        )
      );
    }
    let persistFirebaseConfiguration =
      state.persistState.persistFirebaseConfiguration;
    store.dispatch(
      AuthenticationActions.GetUser(
        user._id,
        persistFirebaseConfiguration.v,
        store.dispatch
      )
    );
  });
};

export const RemoveFriendRequestListner = (userId: string, urlName: string) => {
  (window as any).urlName = false;
  let friendsRefernce = firebase
    .database()
    .ref("users/" + userId + "/" + urlName);
  friendsRefernce.off("value");
};

const removeEventListnerOnInvites = async (userId: string) => {
  let refernceInvites = getInvitesKeyReference(userId);
  refernceInvites.off("child_added");
  refernceInvites.off("child_removed");

  if (invitesRefrencesList != null && invitesRefrencesList.length > 0) {
    invitesRefrencesList.forEach((inviteRef: any) => {
      // console.log("Removing invite Listner on this refernce", inviteRef.key);
      inviteRef.inviteRefence.off("value");
    });
  }
  // console.log("Invites Array after removing all invites", invitesRefrencesList)
};

const removeEventListnerOnSingleInvite = (
  userId: string,
  inviteItemKey: string,
  receiveGameInviteCallBack?: any
) => {
  let singleInviteRefernce = firebase
    .database()
    .ref("users/" + userId + "/Invites/" + inviteItemKey);
  singleInviteRefernce.off("value");
  receiveGameInviteCallBack &&
    receiveGameInviteCallBack(null, inviteItemKey, userId);
  // remove item from refrence list aswell

  let index = invitesRefrencesList.findIndex(
    (invite: any) => invite.key == inviteItemKey
  );

  if (index >= 0) {
    invitesRefrencesList.splice(index, 1);
  }
  // console.log("Invites Array after removing single invite", invitesRefrencesList)
};

export const addInviteListeners = (
  userId: string,
  receiveGameInviteCallBack: any
) => {
  // console.log("addInviteListeners addedd");
  let refernceInvites = getInvitesKeyReference(userId);

  refernceInvites.on("child_added", (snapshot: any) => {
    if (snapshot.exists()) {
      // console.log("firebaseInvites invite Child Added ", snapshot.val());
      // console.log("firebaseInvites invite Child Added ", snapshot.key);
      addValueListnerOnSingleGameInvite(
        userId,
        snapshot.key,
        receiveGameInviteCallBack
      );
    }
  });
  refernceInvites.on("child_removed", (snapshot: any) => {
    if (snapshot.exists()) {
      // console.log("firebaseInvites invite Child removed ", snapshot.val());
      // console.log("firebaseInvites invite Child removed ", snapshot.key);
      removeEventListnerOnSingleInvite(
        userId,
        snapshot.key,
        receiveGameInviteCallBack
      );
    }
  });
};

export const addInviteOnFirebase = (
  userId: string,
  opponentId: string,
  data: any,
  isBotUser: boolean
) => {
  // console.log(
  //   "Adding addInviteOnFirebase",
  //   userId,
  //   opponentId,
  //   isBotUser,
  //   data
  // );
  let keyNode = Date.now().toString();
  let refernceUserInvite = getSpecificInviteKeyReference(userId, keyNode);
  let refernceOpponentInvite = getSpecificInviteKeyReference(
    opponentId,
    keyNode
  );

  refernceUserInvite.set(data);
  refernceOpponentInvite.set(data);
  return keyNode;
};

const addValueListnerOnSingleGameInvite = (
  userId: string,
  inviteItemKey: string,
  receiveGameInviteCallBack: any
) => {
  let singleInviteRefernce = firebase
    .database()
    .ref("users/" + userId + "/Invites/" + inviteItemKey);
  singleInviteRefernce.on("value", (snapshot: any) => {
    if (snapshot.exists()) {
      // console.log('*************************', snapshot.val(), snapshot.key)
      //processInviteData(snapshot.val(), inviteItemKey, userId);
      let val: any = snapshot.val();
      receiveGameInviteCallBack(val, inviteItemKey, userId);
      if (val && val.status !== "invited") {
        removeEventListnerOnSingleInvite(userId, inviteItemKey);
      }
    }
  });

  invitesRefrencesList.push({
    key: inviteItemKey,
    opponentKey: null,
    inviteRefence: singleInviteRefernce,
  });
  // console.log("Invites Array after Pushing single invite", invitesRefrencesList)
};

const processInviteData = (
  data: any,
  inviteItemKey: string,
  userId: string
) => {
  // console.log("processInviteData", data, inviteItemKey);
  calculateServerAndDeviceTimeDiff(data.createdTime);
  // ChatManager.addInviteInRespectiveChat(data, inviteItemKey);
  // if (data.status == InviteActionsEnum.INVITED) {
  //   ChatManager.addInviteInReducer(data, inviteItemKey);
  // } else {
  //   this.removeEventListnerOnSingleInvite(userId, inviteItemKey);
  // }
};

const getInvitesKeyReference = (id: string) => {
  return firebase.database().ref("users/" + id + "/Invites");
};
const getSpecificInviteKeyReference = (id: string, key: string) => {
  return firebase.database().ref("users/" + id + "/Invites/" + key);
};

const getGameReference = (matchId: string) => {
  return firebase.database().ref("mmV4/" + matchId);
};
export const addListenerOnGameNode = (matchId: string, gameCallBack: any) => {
  let user = store.getState().persistState.session;
  const reference = getGameReference(matchId);
  reference.on("value", (snapshot: any) => {
    if (snapshot.exists()) {
      const snapShotData = snapshot.val();
      console.log("snapShotData *********", snapShotData);
      // if (snapShotData.maxPs === snapShotData.ps?.length) {
      //   const gameData = matchMakingResponseMapping(snapShotData)
      //   const playersArray = gameData.players;
      //   playersArray.splice(0,0,playersArray.splice(playersArray.findIndex((player: any) => player.playerId === user._id),1));
      //   gameData.players = playersArray.flat();
      gameCallBack();
      // }
    }
  });
};

export const removeObserverOnMatchMaking = (matchId: string) => {
  const reference = getGameReference(matchId);
  reference.off("value");
};
export const updateInviteOnFirebase = (
  curretUserId: string,
  key: string,
  opponentId: string,
  latestStatus: string,
  receiveGameInviteCallBack: any
) => {
  let refernceOpponentInvite = null;
  let currentUserInvite = null;

  // let index
  // = this.invitesRefrencesList.findIndex(
  //   invite => invite.key == key,
  // );

  //if (index >= 0) {
  refernceOpponentInvite = getSpecificInviteKeyReference(opponentId, key);
  currentUserInvite = getSpecificInviteKeyReference(curretUserId, key);
  refernceOpponentInvite.update({ status: latestStatus });
  currentUserInvite.update({ status: latestStatus });
  //}

  removeEventListnerOnSingleInvite(
    curretUserId,
    key,
    receiveGameInviteCallBack
  );
};
export const getServerTimeOffset = () => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(".info/serverTimeOffset")
      .once("value", function (snap: any) {
        var offset = snap.val();
        resolve(offset);
        // console.log('.info/serverTimeOffse', data.createdTime);
        // console.log('.info/serverTimeOffse', new Date().getTime() + offset);
        // console.log(
        //   '.info/serverTimeOffse',
        //   new Date(new Date().getTime() + offset),
        // );
      });
  });
};
const calculateServerAndDeviceTimeDiff = (serverTimeStamp: string) => {
  // if (
  //   global.serverTimeDifference == null ||
  //   global.serverTimeDifference == 0
  // ) {
  //   let systemUtcTime = new Date(new Date().toUTCString());
  //   const servertUtcTime = new Date(serverTimeStamp);
  //   global.serverTimeDifference = servertUtcTime - systemUtcTime;
  //   console.log(' Server Time Difference= ', global.serverTimeDifference);
  // }
};

//firebase messaging

const initMessaging = () => {
  if (firebase && firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
};
export const getMessagingToken = () => {
  if (Object.keys(messaging).length)
    return messaging.getToken({
      vapidKey:
        "BKz74kkS6-WuF0a4Io4W673lkeOHpoCQemu5VMZLdoSxDykIX6-kd4S1Ol6FeQGsIK4ApZl819jRVJJiF4N4wiI",
    });
  else return new Promise((resolve) => resolve(""));
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload: any) => {
      // console.log("payloaddddddd", payload);
      resolve(payload);
    });
  });

export const addInGameMessagesListner = (
  matchId: any,
  currentUserId: any,
  gameMessageReceived: any
) => {
  let inGameChatRefernce = firebase.database().ref("chatMessages/" + matchId);
  inGameChatRefernce.on("child_added", (snapshot: any) => {
    if (snapshot.exists()) {
      const snapshotData = snapshot.val();
      if (snapshotData.s !== currentUserId) {
        gameMessageReceived(snapshotData);
        setTimeout(() => {
          store.dispatch({ type: ActionConsts.Home.DeleteGameMessage });
        }, 3000);
      }
    }
  });
};
export const removeObserverOnInGameMessages = (matchId: any) => {
  let refernce = firebase.database().ref("chatMessages/" + matchId);
  refernce.off("child_added");
};

export const sendMessageToOpponent = (matchId: any, messageObj: any) => {
  let updateObj = messageObj;
  // updateObj.timestamp = firebase.database.ServerValue.TIMESTAMP;
  firebase
    .database()
    .ref("chatMessages/" + matchId)
    .push(updateObj);
};

export const firebaseObject = () => {
  // debugger;
  return firebase;
};

export const firebaseExternalLogin = async (
  authProvider: string,
  userLang?: string
) => {
  let provider;
  if (authProvider === "GOOGLE") {
    SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_GOOGLE_PRESSED);
    provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("email");
  } else {
    SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_TWITTER_PRESSED);
    provider = new firebase.auth.TwitterAuthProvider();
  }

  // const auth = firebase.auth();
  firebase.auth().languageCode = userLang;
  let result = await firebase.auth().signInWithPopup(provider);
  //then((result: any) => {
  // console.log("provider", provider);
  // console.log("result", authProvider);
  console.log("result", result);
  let credential = result.credential;
  // console.log("Credntial", credential);
  // console.log("token", credential.accessToken);
  // console.log("token", result.user);

  if (authProvider === "GOOGLE")
    SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_GOOGLE_SUCCESS);
  else if (authProvider === "TWITTER") {
    SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_TWITTER_SUCCESS);
  }

  return result;
  //}).catch((error: any) => {
  // console.log(error);
  //})
};
export const firebaseTwitterLogin = (userLang?: string) => {
  const provider = new firebase.auth.TwitterAuthProvider();
  // const auth = firebase.auth();
  firebase.auth().languageCode = userLang;
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result: any) => {
      let credential = result.credential;
      console.log("Credntial", credential);
      console.log("token", credential.accessToken);
      console.log("token", result.user);
    })
    .catch((error: any) => {
      console.log(error);
    });
};
const getRoomReference = (roomId: string) => {
  return firebase.database().ref("rooms/" + roomId);
};
export const addRoomsListener = (roomId: string, callBack: any) => {
  const referenceRooms = getRoomReference(roomId);
  referenceRooms.on("value", (snapshot: any) => {
    // if (snapshot.exists()) {
    const roomId = snapshot.key;
    callBack(snapshot.val());
    // }
    // else
  });
};
export const addListnerOnRooms = async (rooms: MESSAGING_ROOM[]) => {
  if (!rooms || rooms.length === 0) {
    return;
  }
  for (const room of rooms) {
    let refernce = getRoomReference(room._id);
    // let refernce = firebase.database().ref(ref);
    //@ts-ignore
    refernce.on("value", async (snapshot) => {
      // if (snapshot.exists()) {
      const roomId = snapshot.key;
      const lastMsgId = await ChatManager.getLastMsgId(roomId);
      const updatedAt = await ChatManager.getUpdatedAt(roomId);
      if (
        lastMsgId !== snapshot.val()?.lastMsgId ||
        updatedAt !== snapshot.val()?.updatedAt
      ) {
        await chatManager.updateDataOnFirebaseTrigger(roomId, snapshot.val());
      }
      // }
    });
    refernce.onDisconnect().update({
      isTyping: [],
    });
  }
};

export const removeRoomListeners = (rooms: MESSAGING_ROOM[]) => {
  rooms.map((room: MESSAGING_ROOM) => {
    const reference = getRoomReference(room._id);
    reference.off("value");
  });
};

//@ts-ignore
export const addMessage = (chatMsg) => {
  const roomId = chatMsg.roomId;
  let ref = "chats/" + roomId;
  console.log("ref** of send message", ref);
  let refernce = firebase.database().ref(ref);
  let newRef = refernce.push(chatMsg);
  updateLastMsgID(roomId, newRef.key);
};
//@ts-ignore
export const updateLastMsgID = (roomId, id) => {
  let ref = "rooms/" + roomId;
  let refernce = firebase.database().ref(ref);
  refernce
    .update({
      lastMsgID: id,
      updatedAt: Date.now(),
    })
    .then(() => {});
};
export const getTypingArray = async (roomId: string) => {
  console.log("In get typping array");
  let ref = "rooms/" + roomId + "/isTyping";
  let refernce = await firebase.database().ref(ref).once("value");
  return refernce.val();
};
//@ts-ignore
export const addOrRemoveUserInTypingList = async (
  userId: string,
  roomId: string,
  isTyping:boolean
) => {
  console.log("Coming here", userId, roomId, isTyping);
  let ref = "rooms/" + roomId;
  let ref2 = "rooms/" + roomId + "/isTyping";
  let refernce = firebase.database().ref(ref);
  let getRef = await firebase.database().ref(ref2).once("value");
  console.log("getRef***", getRef.val());

  let typingUsers = await refernce.once("value").then((val: any) => {
    return val?.val()?.isTyping ?? {};
  });
  typingUsers[userId] = isTyping;

  let typingIds: [] = [];
  // if(isTyping){
  //   //@ts-ignore
  //   typingIds=[userId]
  // }
  refernce
    .update({
      isTyping: typingUsers,
    })
    .then(() => {});
};
export const getMessage = async (roomId: string) => {
  let ref = "chats/" + roomId;
  let refernce = await firebase.database().ref(ref).once("value");
  return refernce.val();
};
const getChatsReference = (roomId: string, nodeId = "") => {
  return firebase.database().ref("chats/" + roomId + `/${nodeId}`);
};
export const getMessagesFromChatNode = async (roomId: string) => {
  const referenceChats = await getChatsReference(roomId).once("value");
  return referenceChats.val();
};

export const addMessageOnDummyChatNode = (roomId: string, messageObj: any) => {
  const referenceChats = getChatsReference(roomId);
  const pushedRef = referenceChats.push();
  messageObj.id = pushedRef.key;
  const chatRoomRef = getChatsReference(roomId, pushedRef.key);
  chatRoomRef.update(messageObj);
  updateLastMsgID(roomId, pushedRef.key);
};

// export const updateLastMsgID = (roomId: string, id: string) => {
//   console.log('***************************', roomId, id)
//   const referenceRooms =  getRoomsReference(roomId);
//   referenceRooms.update({lastMsgID: id})
// }
export const getLastMsgID = (roomId: string) => {
  const referenceRooms = getRoomReference(roomId).once("values");
  return referenceRooms.val();
};
