import React, {useEffect, useState} from "react";
import {BackgroundImage} from "@Styled";
import {AvatarPlaceholder, GetResultIcons, PlayerNamePlaceholder} from "@Components/MatchMaking/helpers";
import {PLAYERS} from "@Interfaces";
import styles from './style.module.scss';
import {getProfileImage} from "@HelperFunctions";
import {useSelector} from "react-redux";
import {IStore} from "@Redux/IStore";
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {PlayerStatusType} from "@Constants";

export const Player: React.FunctionComponent<{
  userProfileImage?: string, user: PLAYERS,
  gameResultScores: any[], singlePlayer: boolean, revert?: boolean, index: number, backgroundColor?: string,
  showFriends: () => void, timer?: boolean, showGlow?: boolean, gameResult?: string, isTeamView: boolean, chunkedArrayIndex: number
}>
  = ({
       userProfileImage, user, gameResultScores, singlePlayer, revert = false,  index, backgroundColor,
       showFriends, timer, showGlow, gameResult, isTeamView, chunkedArrayIndex
     }) => {
  const {
    specificSelectedConfiguration,
    isHost,
    matchObject: {allPlayers}
  } = useSelector((state: IStore) => state.multiPlayState);
  const {session} = useSelector((state: IStore) => state.persistState);
  const {isSelectable} = specificSelectedConfiguration;
  const invitationTime = 30;
  const [timeLeft, setTimeLeft] = useState(invitationTime);
//  const showingGlow=showGlow && user._id == session._id;

  useEffect(() => {
    let time: NodeJS.Timeout;
    if(timer) {
      time = setTimeout(() => {
        if (timeLeft > 0) setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if(!timer) setTimeLeft(invitationTime)
    return () => clearTimeout(time);
  }, [timer, timeLeft]);

  const userProfile = () => {
    let userProfileImage;
    if (index === 0) userProfileImage = getProfileImage(user);
    else if (!user) {
      userProfileImage = ("/images/avatar/" + Math.floor(Math.random() * 11) + ".png");
    } else {
      // if (otherUser.isBot) {
      userProfileImage = getProfileImage(user);
    }
    return userProfileImage
  }
  const isMineUser=()=>{
    return user?user._id == session._id:false
  }
  return (<div
    className={`d-flex flex-row flex-wrap align-items-center ${revert ? "justify-content-end" : "justify-content-start"}`}>
    {(isHost && timer && timeLeft > 0) && <div style={{position: 'absolute', zIndex: 3, width: "30.5%", paddingBottom: '40px'}}>
      <CircularProgressbar
        maxValue={30}
        value={timeLeft}
        text={`${timeLeft}`}
        strokeWidth={4}
        styles={buildStyles({
          textColor: "white",
          pathColor: "#e3722f",
          trailColor: "transparent",
          pathTransitionDuration: 1.5
        })}
      />
    </div>}
    <div className={`position-relative ${(isSelectable && !user && isHost) && "cursor-pointer"}`} style={{width: "65%", opacity: user?.status === PlayerStatusType.PENDING ? 0.5 : 1}}
         onClick={(isSelectable && !user && isHost) ? showFriends : () => {}}>
      <BackgroundImage
        src={(!isSelectable || (isSelectable && user)) ? [userProfile()] : ''}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius="50%"
        paddingBottom="99.25%"
        backgroundColor={(isSelectable && !user) ? '#8A0FD9' : ''}/>
      {(isSelectable && !user) &&
        <div className={`position-absolute font-16px titillium-semibold ${styles.inviteCircleStyle}`}>
          <img src="/images/newDesign/plus_image.png" height="35px"/>
          Invite
        </div>}
      <div className="position-absolute h-100" style={{top: "0px", left: "0px", width: "100%"}}>
        <AvatarPlaceholder isMine={isMineUser()} index={index} isTeamView={isTeamView} chunkedArrayIndex={chunkedArrayIndex}/>
      </div>
      {gameResult &&
      <GetResultIcons userRank={user?.resultRank} index={index} isTeamView={isTeamView} chunkedArrayIndex={chunkedArrayIndex} isMine={isMineUser()} resultStatus={user?.resultStatus ? user.resultStatus : ''} />
      /* <>
      
        <div style={{position: 'absolute', width: '145%', top: '-20%', left: '-25%', zIndex: -1}}>
          <BackgroundImage
            src={[gameResult === 'win' ? "/images/newDesign/player_glow.png" : "/images/newDesign/opponent_glow.png"]}
            backgroundSize="cover"
            backgroundPosition="center"
            paddingBottom="100%"/>
        </div>
        <div style={{ position: "absolute", bottom: "86%", left: "1%" }}>
        <img
          style={{ width: "50%" }}
          src={"/images/newDesign/Yellow_crown.png"}
        />
      </div>
      </> */
      } 
    </div>

    <div style={{marginTop: singlePlayer && !gameResultScores.length ? "40px" : "20px", display: 'flex'}}>
      <PlayerNamePlaceholder isMine={isMineUser()} user={user} index={index} isTeamView={isTeamView}
                             gameResultScores={gameResultScores} chunkedArrayIndex={chunkedArrayIndex}/>
    </div>
  </div>)
}

