import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { LoadingSpinner } from "@Components/LoadingSpinner";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";
import { FacebookLoginStatus, PATCHUserPayload } from "@Interfaces";
import { AuthenticationActions } from "@Actions/Authentication";
import { ConversationActions } from "@Actions/Conversation";
import { IStore } from "@Redux/IStore";
import {
  AnalyticsEventsName,
  firebaseExternalLogin,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";

import { ActionConsts } from "@Definitions/ActionConsts";
import { GameReconnectingModal } from "@Components/Modals/GameReconnectingModal";

export const LoginWithFacebook: React.FunctionComponent<{
  backgroundColor?: string;
  showShortIcon?: boolean;
  type?: string;
  fcmToken?: string;
  showTextBelowIcon?: boolean;
}> = ({ backgroundColor, showShortIcon, type = "FACEBOOK", fcmToken }) => {
  const [logInStatus, setLogInStatus] = useState<string>("");
  const persistState = useSelector((state: IStore) => state.persistState);
  const { session, persistFirebaseConfiguration, selectedLanguage } =
    persistState;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLogInStatus("loading");

    if (type === "FACEBOOK") {
      if (!(window as any).FB) loadFacebookSDK();
      else getFBLoginStatus();
    }
  }, []);

  const loadFacebookSDK = () => {
    const sdkKey =
      process.env.REACT_APP_IS_PRODUCTION === "true"
        ? process.env.REACT_APP_FACEBOOK_SDK_KEY
        : process.env.REACT_APP_STAGING_FACEBOOK_SDK_KEY;
    (window as any).fbAsyncInit = function () {
      (window as any).FB.init({
        appId: sdkKey,
        xfbml: true,
        version: "v13.0",
      });
      getFBLoginStatus();
    };

    (function (d, s, id) {
      let js: any,
        fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const getFBLoginStatus = () => {
    (window as any).FB.getLoginStatus(function (
      statusResponse: FacebookLoginStatus
    ) {
      if (statusResponse.status === "connected") setLogInStatus("loggedIn");
      else setLogInStatus("notLoggedIn");
    });
  };

  const logout = () => {
    dispatch(AuthenticationActions.LogoutUser());
    (window as any).FB.logout((logoutResponse: any) => {
      if (logoutResponse.status === "connected") setLogInStatus("loggedIn");
      else setLogInStatus("notLoggedIn");
    });
  };

  const loginWithFacebook = () => {
    if (logInStatus === "loggedIn") {
      (window as any).FB.logout((logoutResponse: any) => {
        if (logoutResponse.status === "connected") setLogInStatus("loggedIn");
        else setLogInStatus("notLoggedIn");
      });
    } else {
      SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_FACEBOOK_PRESSED);
      setIsLoading(true);
      (window as any).FB.login(
        function (loginResponse: any) {
          setIsLoading(false);
          if (loginResponse.status === "connected") {
            setLogInStatus("loggedIn");
            getFacebookGraphData(loginResponse);

            // Analytics
            // SendAnalyticsToFirebase(AnalyticsEventsName.ON_FB_LOGIN_SUCCESS);
            SendAnalyticsToFirebase(
              AnalyticsEventsName.LOGIN_WITH_FACEBOOK_SUCCESS
            );
          } else {
            setLogInStatus("notLoggedIn");

            // SendAnalyticsToFirebase(AnalyticsEventsName.ON_FB_LOGIN_FAILED);
            SendAnalyticsToFirebase(
              AnalyticsEventsName.LOGIN_WITH_FACEBOOK_FAILED
            );
          }
        },
        { scope: "email,public_profile,gaming_user_picture,gaming_profile" }
      );
    }
  };

  const uploadProfilePic = async (imageUrl: string, fbId: string) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then((response: any) => {
          if (response.status === 200) {
            response.blob().then(async (fbProfileImage: Blob) => {
              const formData = new FormData();
              session._id && formData.append("userId", session._id);
              formData.append("file", fbProfileImage, fbId + ".png");
              const uploadPath: any = await dispatch(
                ConversationActions.UploadMediaOnStorage({
                  formData: formData,
                  apiPath: "/image",
                })
              );
              resolve(uploadPath);
            });
          } else {
            resolve("");
          }
        })
        .catch(() => {
          resolve("");
        });
    });
  };

  const getFacebookGraphData = async (loginResponse: FacebookLoginStatus) => {
    const token = loginResponse.authResponse.accessToken || null;
    setLogInStatus("loading");
    const graphLoginReponse: any = token
      ? await dispatch(
          AuthenticationActions.GetFacebookGraphData({
            access_token: token,
            userId: loginResponse.authResponse.userID,
          })
        )
      : loginWithFacebook();

    (window as any).FB.api(
      "/" +
        loginResponse.authResponse.userID +
        "/picture?redirect=false&width=400&height=400",
      "GET",
      {},
      async function (response: any) {
        console.log("graphLoginReponse", response);
        // console.log("graphLoginReponse1", graphLoginReponse);
        let uploadPath: any = "";
        const social = {
          id:
            graphLoginReponse.email && graphLoginReponse.email.length > 2
              ? graphLoginReponse.email
              : loginResponse.authResponse.userID,
          type: "facebook",
        };
        const facebookSocial = {
          id: graphLoginReponse.id && graphLoginReponse.id.length > 2
            ? graphLoginReponse.id
            : loginResponse.authResponse.userID,
          type: "facebook",
        }
        // console.log("social Params", social);
        let updateParams: PATCHUserPayload = {
          loginType: social,
          // _id: session._id,
          social: facebookSocial,
          name: graphLoginReponse.name,
          isWeb: true,
          configVersion: persistFirebaseConfiguration.v,
          fcm_key: fcmToken,
          userImageUrl: response.data.url
        };
        // userImageUrl: "https://graph.facebook.com/" +
        // loginResponse.authResponse.userID +
        // "/picture?height=240&width=240",
        console.log("loginParams", updateParams);

        // if (response.data)
        //   uploadPath = await uploadProfilePic(
        //     response.data.url,
        //     loginResponse.authResponse.userID
        //   );
        // if (uploadPath)
        //   Object.assign(updateParams, { userImageUrl: uploadPath });
        if (session._id) Object.assign(updateParams, { _id: session._id });
        if (!session._id) (window as any).SOCIAL_LOGIN = true;
        const updateResponse: any = await dispatch(
          AuthenticationActions.UpdateUser(updateParams)
        );
        if (updateResponse.success && updateResponse.response) {
          setPaywallVisibility(updateResponse.response);
          history.push("/");
        } else {
          setLogInStatus("notLoggedIn");
        }
      }
    );
  };
  const setPaywallVisibility = (response: any) => {
    if (
      response.paymentMethods === null ||
      response.paymentMethods?.filter(
        (e: { active: boolean }) => e.active === true
      ).length === 0
    ) {
      dispatch({
        payload: true,
        type: ActionConsts.Authentication.ShowPaywall,
      });
    } else {
      (window as any).compaignPaymentMethods = null;
      dispatch({
        payload: false,
        type: ActionConsts.Authentication.ShowPaywall,
      });
    }
  };

  const getProfileData = (result: any, provider: string) => {
    let profile: any = {};

    if (provider === "GOOGLE") {
      profile.email = result.additionalUserInfo.profile.email;
      profile.id = result.additionalUserInfo.profile.id;
      profile.photo = result.additionalUserInfo.profile.picture;
      profile.name = result.additionalUserInfo.profile.name;
    } else {
      profile.email = result.additionalUserInfo.profile.email;
      profile.id = result.additionalUserInfo.profile.id_str;
      profile.photo = result.additionalUserInfo.profile.profile_image_url_https;
      profile.name = result.additionalUserInfo.profile.name;
    }
    console.log("getProfileData", profile);
    return profile;
  };

  const login = async (authProvider: string) => {
    try {
      setIsLoading(true);
      let result: any = await firebaseExternalLogin(
        authProvider,
        selectedLanguage
      );
      setIsLoading(false);
      // console.log("result", result);

      (window as any).SOCIAL_LOGIN = true;
      let profile = getProfileData(result, authProvider);
      let loginParams = {
        name: result.user.displayName,
        loginType: {
          id: profile.email ? profile.email : profile.id, //? result.user.email :,
          type: authProvider.toLocaleLowerCase(),
        },
        // images: [result.user.photoURL],
        userImageUrl: profile.photo,
        isWeb: true,
        fcm_key: fcmToken,
        configVersion: persistFirebaseConfiguration?.v,
      };
      console.log("loginParams", loginParams);

      // let uploadPath: any = await uploadProfilePic(profile.photo, profile.id);
      const userResponse: any = await dispatch(
        AuthenticationActions.UpdateUser(loginParams)
      );
      if (userResponse.success && userResponse.response) {
        setPaywallVisibility(userResponse.response);
        history.push("/");
      }
    } catch (error) {
      if (authProvider === "GOOGLE")
        SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_GOOGLE_FAILED);
      else if (authProvider === "TWITTER") {
        SendAnalyticsToFirebase(AnalyticsEventsName.LOGIN_WITH_TWITTER_FAILED);
      }
      console.log("Error In Login", error);
      setIsLoading(false);
    }
  };
  return (
    <>
      {type === "FACEBOOK" && (
        <>
          {showShortIcon ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: "42px" }}
                className="mt-3 cursor-pointer mx-3"
                onClick={() => {
                  loginWithFacebook();
                }}
                src="/images/facebook.png"
              />
              <span className="text-white">{t("FACEBOOK")}</span>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center mt-3 w-100">
             
                <div
                  className="d-flex align-items-center justify-content-center px-4 py-2 cursor-pointer social-login-button-width"
                  style={{
                    borderRadius: "25px",
                    background: backgroundColor ? backgroundColor : "#4267b2",
                  }}
                  onClick={() => {
                    loginWithFacebook();
                  }}
                >
                  <img
                    height="28px"
                    src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png?_nc_eui2=AeGeQEXMBSSWwAtsqCD2Ke6F6ukh_QO9K2vq6SH9A70ra78zujQxxQHQbkAq9CkoGIiwK1FvETS-gHUqKRva0c_k"
                  />
                  <div
                    style={{ minWidth: "200px" }}
                    className="text-center font-20px text-white titillium-semibold ml-2"
                  >
                    { logInStatus === "loading" ? 
                     <LoadingGIF color="white" size="1x" />:
                      t("CONTINUE_WITH_FACEBOOK")
                      // "Continue with Facebook"
                    }
                  </div>
                </div>
             
            
              {/* {logInStatus === 'loggedIn' && <div className="d-flex align-items-center justify-content-between px-4 py-2 cursor-pointer"
                        style={{ background: backgroundColor ? backgroundColor : "#4267b2", borderRadius: "25px" }}
                        onClick={() => { logout() }}>

                        <img height="28px"
                            src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png?_nc_eui2=AeGeQEXMBSSWwAtsqCD2Ke6F6ukh_QO9K2vq6SH9A70ra78zujQxxQHQbkAq9CkoGIiwK1FvETS-gHUqKRva0c_k" />
                        <div style={{ width: "200px" }} className="text-center font-20px text-white titillium-semibold">{t("LOGOUT")}</div>
                    </div>} */}

              {/* {logInStatus === "loading" && ( */}
                {/* <div
                  className="d-flex align-items-center justify-content-between px-4 py-2 cursor-pointer"
                  style={{
                    borderRadius: "25px",
                    background: backgroundColor ? backgroundColor : "#4267b2",
                  }}
                >
                  <img
                    height="28px"
                    src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png?_nc_eui2=AeGeQEXMBSSWwAtsqCD2Ke6F6ukh_QO9K2vq6SH9A70ra78zujQxxQHQbkAq9CkoGIiwK1FvETS-gHUqKRva0c_k"
                  />
                  <div className="text-center" style={{ width: "200px" }}> */}
                    {/* <LoadingGIF color="white" size="1x" /> */}
                  {/* </div>
                </div> */}
              {/* )} */}
            </div>
          )}
        </>
      )}
      {type === "GOOGLE" && (
        <>
          {showShortIcon ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: "42px" }}
                className="mt-3 cursor-pointer mx-3"
                onClick={() => {
                  login(type);
                }}
                src="/images/google_icon.png"
              />
              <span className="text-white">{t("GOOGLE")}</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center mt-3 w-100"
              onClick={() => login(type)}
            >
              <div
                className="d-flex align-items-center justify-content-center px-4 py-2 cursor-pointer social-login-button-width"
                style={{ borderRadius: "25px", background: backgroundColor }}
              >
                <img height="28px" src={`/images/google_icon.png`} />
                <div
                  style={{ minWidth: "200px", color: "#1D0141" }}
                  className="text-center font-20px titillium-semibold ml-2"
                >
                  {t("CONTINUE_WITH_GOOGLE")}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {type === "TWITTER" && (
        <>
          {showShortIcon ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                style={{ width: "42px" }}
                className="mt-3 cursor-pointer mx-3"
                onClick={() => {
                  login(type);
                }}
                src="/images/twitter_icon.png"
              />
              <span className="text-white">{t("TWITTER")}</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center mt-3 w-100"
              onClick={() => login(type)}
            >
              <div
                className="d-flex align-items-center justify-content-center px-4 py-2 cursor-pointer social-login-button-width"
                style={{ borderRadius: "25px", background: backgroundColor }}
              >
                <img height="28px" src={`/images/twitter_icon.png`} />
                <div
                  style={{ minWidth: "200px", color: "#1D0141" }}
                  className="text-center text-white font-20px titillium-semibold ml-2"
                >
                  {t("CONTINUE_WITH_TWITTER")}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading && <GameReconnectingModal text=""></GameReconnectingModal>}
    </>
  );
};
