// #region Global Imports
import React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { IScrollableList } from "./ScrollableList";
// #endregion Local Imports

const _ScrollableList = styled.div<IScrollableList.IProps>`
    overflow-x: ${({ overfloxX }) => { return (overfloxX ? overfloxX : "initial") }};
    overflow-y: ${({ overflowY }) => { return (overflowY ? overflowY : "initial") }};
    margin: ${({ margin }) => { return (margin ? margin : "") }};
    width: 100%;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ScrollableList: React.FunctionComponent<IScrollableList.IProps> 
    = ({ children, ...rest}) => {

    return <_ScrollableList {...rest}>
        { children }
    </_ScrollableList>;
};
