
import { Http } from '../Http';
import { UserModel } from './UserModel'; 

const apiBaseUrl =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
export const UserService = {
  ResetUser: async (
    payload: UserModel.ResetUserPayload
  ): Promise<UserModel.ResetUserResponse> => {
    let response: UserModel.ResetUserResponse;

    try {
      response = await Http.Request<UserModel.ResetUserResponse>(
        'POST',
        `${apiBaseUrl}/user/resetUser`,
        {
          'x-access-token': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21tb25TZWNyZXQiOiJNSUlFdlFJQkFEQU5CZ2txaGtpRzl3MEJBUUVGQUFTQ0JLY3dnZ1MiLCJpYXQiOjE3MzMxMzc5MTMsImV4cCI6MTczMzIyNDMxM30.rzxosBZ6In9lSpZS_nze1qk5wQyuD5xWycHIPkSFJt0',
          'Content-Type': 'application/json',
        },
        payload
      );
    } catch (error) {
      response = {
        success: false,
        message: 'Failed to reset user.',
      };
    }
    return response;
  },
};
