import React from 'react';
import { Link, useHistory, Route } from "react-router-dom";
import { PrimaryButton } from "@Styled";
import { useTranslation } from "react-i18next";
import { theme } from "@Definitions/Styled";
import { AnalyticsEventsName, SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import {useSelector} from "react-redux";
import {IStore} from "@Redux/IStore";

export const Header: React.FunctionComponent<{setShowServiceWorketPrompt: (a: boolean) => void}>
  = ({ setShowServiceWorketPrompt}) => {
    const { t } = useTranslation();
    const history = useHistory();
  const persistState = useSelector((state: IStore) => state.persistState);
  const { selectedLanguage } = persistState;
    const isAndroidDevice = /Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isIosDevice = /iPad Simulator|iPhone Simulator|iPod Simulator|iPhone|iPod|iPad/i.test(navigator.userAgent);

    const checkIosDevice = () => {
        SendAnalyticsToFirebase(
          AnalyticsEventsName.DOWNLOAD_APP_BUTTON
        );
        if(isIosDevice){
          setShowServiceWorketPrompt(true)
        } else{
          return  window.location.href = 'https://play.google.com/store/apps/details?id=com.gago.multiplayergames&hl=en&gl=US';
        }
    }

    return <div className="d-flex justify-content-between align-items-center py-3 px-3 w-100">
      <img src="/images/GagoNewicon.png" width="70px" height="20px" />
      <div className="d-flex align-items-center">
        {(isAndroidDevice || isIosDevice) &&
          // <a href="https://play.google.com/store/apps/details?id=com.gago.multiplayergames&hl=en&gl=US">
            <PrimaryButton
              padding="5px 14px 6px"
              className="d-flex align-items-center font-14px titillium-semibold text-white mx-3"
              backgroundColor={theme.colors.orange}
              borderRadius="30px"
              onClick={checkIosDevice}
            >
              <img src="/images/download.png" width={15} className={`${selectedLanguage === 'ar' ? 'ml-2' : 'mr-2'}`} />
              {t("DOWNLOAD_APP")}
            </PrimaryButton>
          // </a>
        }
        <Link to="/search-games" className="cursor-pointer">
          <img height="20px"
            width="20px" src="/images/Ico_Search.png" />
        </Link>
      </div>
      {/* <img src="/images/Ico_Notification.png" width="20px" height="20px" /> */}
    </div>
  }