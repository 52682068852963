import React from "react";
import { ParagraphText } from "@Styled";

export const OnlineStatus: React.FunctionComponent = () => {
    return (<div className="position-absolute" style={{
            backgroundColor: "#f9edbe",
            border: "1px solid #f0c36d",
            borderRadius: "0 0 2px 2px",
            boxShadow: "0 2px 4px rgb(0 0 0 / 20%)",
            color: "#222",
            padding: "6px 10px",
            zIndex: 1
        }}>
            <span>Not connected...</span>
            <span style={{ textDecoration: "underline" }} 
                className="ml-2 cursor-pointer titillium-semibold" 
                onClick={()=>{ window.location.href = "/" }}>
                    Try Now
            </span>
    </div>)
}