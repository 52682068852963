import React, { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularImage, PositionedModal } from "@Styled";
import { MESIBO_CHAT_MESSAGE, GAME_ITEM, USER_SESSION, IStore } from '@Interfaces';
import { useSelector } from 'react-redux';
import { getProfileImage } from '@HelperFunctions';

declare namespace IInviteRecievedModal {
    export interface IProps {
        isShowing?: boolean;
        modalRef?: RefObject<HTMLDivElement>;
        onOptionClick: (type: boolean) => void;
        inviteRecievedData: any;
        multiplayerGamesList: GAME_ITEM[];
        user: USER_SESSION;
        onClose: () => void;
        index: number;
    }
}
let cleartime: any = null;
export const InviteRecievedModal: React.RefForwardingComponent<HTMLDivElement, IInviteRecievedModal.IProps> = ((props) => {
    const { isShowing, modalRef, onOptionClick, inviteRecievedData, multiplayerGamesList, user, onClose, index } = props;

    const persistState = useSelector((state: IStore) => state.persistState);
    const { selectedLanguage } = persistState;

    const gameData = JSON.parse(inviteRecievedData.message);
    const { t } = useTranslation();
    const inviteGameId = inviteRecievedData.message && JSON.parse(inviteRecievedData.message)._gameId
    const inviteGame = inviteGameId >= 0 ? multiplayerGamesList.filter((game) => {
        return inviteGameId === game.gameId;
    }) : false;


    return inviteGameId >= 0 ? <div style={{
        borderRadius: "12px", backgroundColor: "#8A0FD9", zIndex: 100, flexShrink: 0
    }} className={"invite-width position-relative " + (index === 0 ? " mx-auto" : " mx-4")} >
        <div className="d-flex flex-row px-3 my-3 align-items-center" ref={modalRef}>
            <img className="position-absolute mx-auto"
                style={{
                    top: "-40px", left: "0", right: "0", border: "5px solid #8A0FD9",
                    borderRadius: "12px", height: "64px", width: '64px', backgroundColor: "#8A0FD9"
                }} src={inviteGame ? (inviteGame[0].gifUrl && inviteGame[0].gifUrl !== "false" ? inviteGame[0].gifUrl : inviteGame[0].thumbnail) : ""} />
            <CircularImage
                src={gameData._pid2_profileImage}
                height="64px"
                width="64px" />
            {/* <div>{t("HEY") + " " + user.name + ' !!'}</div> */}
            <div className="d-flex justify-content-between w-100">
                <div className="flex-column ml-2">
                    <div className="font-24px text-white">{gameData._pid2_name}</div>
                    <div className="text-white mr-2">invites you to play <span className="font-16px" style={{ color: "#FFA234" }}>{inviteGame ? inviteGame[0].title : ""}</span></div>
                </div>
                {/* <div>
                <span>{t("YOU_RECEIVED_GAME_INVITE_FOR")}</span>
                <span className="ml-1 text-primary titillium-semibold">{inviteGame && inviteGame[0] ? (inviteGame[0].title) : ''}</span>
            </div> */}
                <div className="d-flex align-items-center justify-content-end mt-2">
                    <button type="button" style={{ backgroundColor: "#FFA234", borderRadius: "6px", padding: "10px 20px", color: "white" }}
                        onClick={() => { onOptionClick(true); }}>{t("JOIN")}</button>
                    {/* <span className="text-danger ml-4 cursor-pointer"
                    onClick={() => { onOptionClick(false); }}>{t("DISMISS")}</span> */}
                    <div style={{
                        right: "-18px",
                        top: "-12px",
                        border: "6px solid rgb(138, 15, 217)",
                        borderRadius: "50%",
                        background: "rgb(138, 15, 217)",
                        margin: "0px"
                    }} className="position-absolute cursor-pointer" onClick={() => { onOptionClick(false); }}>
                        <img src="/images/close_invite.png" />
                    </div>
                </div>
            </div>
        </div>
    </div > : null
});
