// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, IInvitePage, USER_SESSION } from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: IInvitePage.IStateProps = {
    searchUsers: new Array<USER_SESSION>(),
    error: {
        searchUsers: new Array<string>(),
        inviteFriend: new Array<string>()
    }
};

export const InviteReducer = (
    state = INITIAL_STATE,
    action: IAction<IInvitePage.Actions.IMapSerachUsers>
) => {
    switch (action.type) {
        case ActionConsts.Invite.ClearSearchUser: {
            return Object.assign({}, state, {
                searchUsers: []
            });
        }
        case ActionConsts.Invite.GetSearchUsersSuccess: {
            const { users } = action.payload!;

            return Object.assign({}, state, {
                searchUsers: users
            });
        }
        case ActionConsts.Invite.GetSearchUsersError: {
            const { error } = action.payload!;

            return Object.assign({}, state, {
                error: {
                    searchUsers: error,
                    inviteFriend: state.error.inviteFriend
                }
            });
        }
        default:
            return state;
    }
}
