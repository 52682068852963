// Global Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flatten from "lodash/flatten";
//
// Local Imports
// import { ScrollableList } from '@Components';
import { PrimaryButton, ScrollableList, LazyImage } from "@Styled";
// import { HomeModule } from "./module";
import { HomeActions } from "@Actions/Home";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { PersistActions } from "@Actions/PersistActions";

// import { ScriptLoader } from '@Services';
import { GameItem, GameItemPlaceholder } from "@Components/GameItem";
import { SinglePlayerGameBoard } from "@Components/SinglePlayerGameBoard";
// import { LoadingSpinner } from "@Components/LoadingSpinner";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";

import styles from "./style.module.scss";
import { IStore } from "@Redux/IStore";
import { GAME_ITEM, GAME_CATEGORY, GAME_CONFIG } from "@Interfaces";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { getConfigurations, isPaidContentAllowed } from "@HelperFunctions";
import { AccessResponseStatus } from "@Constants";
import { faGrinBeamSweat } from "@fortawesome/free-solid-svg-icons";
import { GameItemWithoutBackground } from "@Components/GameItem/GameItemWithoutBackground";
import { GameTypeView } from "@Components/GameTypeView";
import { MultiGamePlayAction } from "@Actions/MultiGamePlayAction";
import { getCookie } from "@Services/Cookie";
import { theme } from "@Definitions/Styled";

//
let loadingOnScroll = false;
export const HomeContainerNewDesign: React.FunctionComponent<{
  onRequestMatchMaking: (a: number, b: boolean) => void;
  setShowLowBalanceModal: (a: boolean) => void;
  setShowNoNameModal: (a: boolean) => void;
  showSubscriptionSuccessfull: boolean;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
  setShowPaywall: (a: boolean) => void;
  setPremiumFeature: (a: boolean) => void;
  isFirebaseInitilized: boolean;
  // setExclusiveFeatureTitle:(a: string)=>void
}> = ({
  onRequestMatchMaking,
  setShowLowBalanceModal,
  setShowNoNameModal,
  showSubscriptionSuccessfull,
  setShowSubscriptionSuccessfull,
  setShowPaywall,
  setPremiumFeature,
  isFirebaseInitilized,
}) => {
  const [showGameBoard, setShowGameBoard] = useState(false);
  const dispatch = useDispatch();
  const persistState = useSelector((state: IStore) => state.persistState);
  const { selectedGameConfig } = useSelector(
    (state: IStore) => state.multiPlayState
  );
  const homeState = useSelector((state: IStore) => state.home);
  const [itemSpace, setItemSpace] = useState(8);
  // const loginState = useSelector((state: IStore) => state.login);
  // const { firebaseConfig } = loginState;
  const { userHasLowBalance, gamesListLoaded, homePageNo } = homeState;
  let {
    gamesList,
    recentlyPlayedGameIds,
    selectedLanguage,
    session,
    userHasNoName,
    persistFirebaseConfiguration: firebaseConfig = { regions: [] },
    showPaywall,
    gameConfigJson,
    recentPlayed,
  } = persistState;
  const [loadingGames, setLoadingGames] = useState(false);
  const [showGameConfigurationScreen, setShowGameConfigurationScreen] =
    useState(false);
  const [gameCategoryList, setGameCategoryList] = useState<GAME_CATEGORY[]>([]);
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<GAME_ITEM[]>(
    []
  );
  const history = useHistory();
  const [selectedGame, setSelectedGame] = useState<GAME_ITEM | any>({});
  const { t } = useTranslation();
  const state = useSelector((state) => state);

  if ((gamesList == null || gamesList.length == 0) && !loadingGames) {
    setLoadingGames(true);
  }
  useEffect(() => {
    setRecentlyPlayedGames(recentPlayed);
  }, [recentPlayed, recentlyPlayedGameIds]);

  useEffect(() => {
    let scrollElement = document.getElementById("mainScroll");
    if (scrollElement) {
      if (!scrollElement.onscroll) {
        scrollElement.addEventListener(
          "scroll",
          async function (event: any) {
            if (isInView("sfakndfaja5sad5155")) {
              if (!loadingOnScroll) {
                loadingOnScroll = true;
                await dispatch(
                  HomeActions.GetAppDesignGamesList(
                    getGameListparams(),
                    recentlyPlayedGameIds
                  )
                );
                loadingOnScroll = false;
              }
            }
          },
          false
        );
      }
    }
    return () => {
      scrollElement?.removeEventListener("scroll", () => {});
    };
  }, []);
  const getPageNo = () => {
    let page = localStorage.getItem("ipn");
    if (page) return +page;
    return 1;
  };
  const getGameListparams = () => {
    var configNo = localStorage.getItem("configNo");
    if (!configNo) {
      configNo = "0";
    }
    return {
      pageNo: getPageNo() + 1,
      itemCount: 12,
      configVersion: parseFloat(configNo),
    };
  };
  const isInView = (id: string) => {
    let currentUser = document.getElementById(id);
    if (currentUser) {
      const rect = currentUser.getBoundingClientRect();

      let isInViewPort =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      return isInViewPort;
    }
  };
  useEffect(() => {
    // if (firebaseConfig.regions.length > 0) setLoadingGames(false);
    if (!gamesListLoaded) {
      (async () => {
        if (!gamesList.length) setLoadingGames(true);
        // await dispatch(HomeActions.GetGamesList({}));
        // console.log("debugger");
        // debugger;
        (localStorage.getItem("api-token") || getCookie("api-token")) &&
          (await dispatch(
            HomeActions.GetAppDesignGamesList(
              {
                pageNo: homePageNo,
                itemCount: 12,
                configVersion: persistState?.persistFirebaseConfiguration?.v,
              },
              recentlyPlayedGameIds
            )
          ));
        setLoadingGames(false);
      })();
    }
  }, [localStorage.getItem("api-token"), getCookie("api-token")]);

  useEffect(() => {
    // if (recentlyPlayedGameIds) {
    //   const allGames = flatten(
    //     gamesList.map((category: GAME_CATEGORY) => {
    //       return [...category.games];
    //     })
    //   );
    //   const _recentlyPlayedGames: any = [];
    //   recentlyPlayedGameIds.forEach((playedGameId) => {
    //     const playedGame = allGames.filter((game) => {
    //       return game.gameId === playedGameId;
    //     });
    //     playedGame.length && _recentlyPlayedGames.push(playedGame[0]);
    //   });
    //   // setRecentlyPlayedGames([..._recentlyPlayedGames]);
    //   let allCat = gamesList.filter((category: GAME_CATEGORY) => {
    //     return category.name !== "recentPlayed"
    //   })
    //   allCat[0].games = [..._recentlyPlayedGames, ...allCat[0].games]

    // }

    if (gamesList && recentlyPlayedGameIds) {
      const allGames = flatten(
        gamesList.map((category: GAME_CATEGORY) => {
          return [...category.games];
        })
      );
      const _recentlyPlayedGames: any = [];
      recentlyPlayedGameIds.forEach((playedGameId) => {
        const playedGame = allGames.filter((game: any) => {
          return game.gameId === playedGameId;
        });
        playedGame.length && _recentlyPlayedGames.push(playedGame[0]);
      });
      let allCat = gamesList.filter((category: GAME_CATEGORY) => {
        return category.viewType !== "recentPlayed";
      });
      // let games = [..._recentlyPlayedGames];
      // _recentlyPlayedGames.forEach((game: any) => {
      //   let index = allCat[0].games.findIndex((r: any) => r.gameId === game.gameId);
      //   if (index > -1)
      //     allCat[0].games.splice(index, 1);
      // });
      // if (allCat && allCat.length)
      //   allCat[0].games = [...(games.reverse()), ...allCat[0].games]
      //
      // allCat.forEach((cat: GAME_CATEGORY, index: number) => {
      //   if (index > 0) {
      //     _recentlyPlayedGames.forEach((rgame: any, gameIndex: number) => {
      //       let idx = cat.games.findIndex((g: any) => g.gameId === rgame.gameId);
      //       if (idx > -1) {
      //         let removed = cat.games.splice(idx, 1)
      //         cat.games = [...cat.games, ...removed];
      //       }
      //     })
      //   }
      // })

      setGameCategoryList(allCat);
    }
  }, [recentlyPlayedGameIds, gamesList]);

  useEffect(() => {
    if (!session._id && (window as any).userSessionTokenChanged)
      history.push("/login");
  }, [session]);

  const cancelMatchMaking = () => {
    setSelectedGame({});
    setShowGameBoard(false);
  };
  const onPremiumGameClick = () => {
    setShowPaywall(true);
    dispatch(ExclusiveFeaturesActions.SetExclusiveFeature(true));
    // setPremiumFeature(true)
    // setExclusiveFeatureTitle("EXCLUSIVE_FEATURE")
  };
  // const getConfigurations = (game: GAME_ITEM) => {
  //   let selectedGameTypes: GAME_CONFIG[] = [];
  //   let selectedGameTypesIds = game.configurations;
  //   // need to uncomment the maps, to be changed
  //
  //   selectedGameTypesIds?.map((id: string) => {
  //     gameConfigJson.map((config: GAME_CONFIG) => {
  //       // to be changed
  //       if (id === config.id) selectedGameTypes.push(config);
  //     });
  //     // selectedGameTypes = gameConfigJson;
  //     // console.log('selectedGameTypesIds ***************', selectedGameTypes)
  //     dispatch(MultiGamePlayAction.SetSelectedGameToPlay(game));
  //     dispatch(MultiGamePlayAction.SetSelectedGameConfig(selectedGameTypes));
  //     if (selectedGameTypes.length === 1) {
  //       dispatch(MultiGamePlayAction.SetSpecificSelectedConfiguration(selectedGameTypes[0].matchMakingTemplate));
  //     }
  //   });
  // }
  const playGame = (game: GAME_ITEM) => {
    localStorage.removeItem("fromChat");
    if (!persistState.session._id) {
      history.push("/login");
      return;
    }

    if (userHasNoName) {
      setShowNoNameModal(true);
      return;
    }

    if (game.isSubRequired === true) {
      const { accessAllowed, accessBlockReason } =
        isPaidContentAllowed(session);
      if (!accessAllowed) {
        accessBlockReason === AccessResponseStatus.LOW_BALANCE
          ? setShowLowBalanceModal(true)
          : onPremiumGameClick();
        return;
      }
    }

    if (game.multiplayer) {
      getConfigurations(game, gameConfigJson, dispatch);
      if (game.configurations?.length > 1) {
        dispatch(MultiGamePlayAction.SetShowGameTypeView(true));
        setShowGameConfigurationScreen(true);
      } else {
        // dispatch(MultiGamePlayAction.SetIsHost(true));
        onRequestMatchMaking(game.gameId, true);
      }
      // else onRequestMatchMaking(game.gameId, true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_MP_GAME_ITEM_PRESSED);
    } else {
      setSelectedGame(game);
      setShowGameBoard(true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED);
    }
  };

  const checkGamesLength = (
    noOfItemsPerRow: number | undefined,
    noOfRows: number,
    gamesArray: GAME_ITEM[],
    i: number
  ) => {
    let totalNoRows;
    let btn = false;
    let games = gamesArray;
    if (noOfItemsPerRow && noOfRows) {
      totalNoRows = noOfItemsPerRow * noOfRows;
      if (gamesArray.length > totalNoRows) {
        const arrayslice = gamesArray.slice(0, totalNoRows - 1);
        games = arrayslice;
        btn = true;
        return { games, btn, totalNoRows };
      } else {
        return { games };
      }
    } else return { games };
  };

  return (
    <div
      className={"d-flex flex-column pb-3 " + (loadingGames ? "h-100" : "")}
      id="scroll"
    >
      {showGameConfigurationScreen && (
        <GameTypeView
          show={showGameConfigurationScreen}
          onRequestMatchMaking={onRequestMatchMaking}
          setShow={setShowGameConfigurationScreen}
          selectedGameTypes={selectedGameConfig}
        />
      )}

      {showGameBoard && !selectedGame.multiplayer && isFirebaseInitilized && (
        <SinglePlayerGameBoard
          show={showGameBoard}
          selectedGame={selectedGame}
          selectedLanguage={selectedLanguage}
          selectedGameId={selectedGame.gameId}
          exitGame={() => {
            // Analytics
            SendAnalyticsToFirebase(
              AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED
            );

            // dispatch(
            //   PersistActions.UpdateRecentlyPlayedGame({
            //     gameId: selectedGame.gameId,
            //   })
            // );
            cancelMatchMaking();
          }}
        />
      )}

      {/*<div className="text-white my-2 titillium-semibold font-16px">*/}
      {/*  /!* {t("RECENT_PLAYED")} *!/*/}
      {/*</div>*/}
      <div className={styles.overflowX}></div>
      <LoadingGIF showLoading={loadingGames} color="#ffffff" size="4x">
        {!!(recentlyPlayedGames && recentlyPlayedGames.length) && (
          <>
            <div className="text-white titillium-semibold d-flex align-items-center font-18px mb-2">
              <div>{t("RECENT_PLAYED")}</div>
            </div>
            <div className={styles.overflowX}>
              <div className="d-flex align-items-center mb-2">
                {recentlyPlayedGames &&
                  recentlyPlayedGames.length &&
                  recentlyPlayedGames.map((game: GAME_ITEM, i: number) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          playGame(game);
                        }}
                        style={{
                          margin:
                            i > 0
                              ? `${
                                  selectedLanguage === "ar"
                                    ? "0rem 0.7rem 1rem 0rem"
                                    : "0rem 0 1rem 0.7rem"
                                }`
                              : "0rem 0 1rem",
                          flex: "0 0 60px",
                        }}
                        className="cursor-pointer"
                      >
                        <LazyImage
                          src={
                            game.gifUrl && game.gifUrl !== "false"
                              ? game.gifUrl
                              : game.thumbnail
                          }
                          alt={""}
                          height="100%"
                          borderRadius="6px"
                          width="100%"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        {gameCategoryList.map((gameCategory: GAME_CATEGORY, i: number) => {
          const { games, btn } = checkGamesLength(
            gameCategory.noOfItemsPerRow,
            gameCategory.noOfRows,
            gameCategory.games,
            i
          );
          return (
            <div
              className="d-flex flex-column align-items-start w-100 h-100"
              key={i}
            >
              {gameCategory.viewType !== "inviteAndDiscover" &&
                gameCategory.viewType !== "stcplayBanner" && (
                  <>
                    <div
                      className={
                        "text-white titillium-semibold d-flex align-items-center font-18px mb-2 " +
                        styles.categoryNameHeader
                      }
                    >
                      {gameCategory.icon && (
                        <img
                          src={gameCategory.icon}
                          className={styles.fireCircle}
                        />
                      )}

                      {gameCategory.viewType !== "recentPlayed" && (
                        <div className="px-2">
                          {selectedLanguage === "en"
                            ? gameCategory.name
                            : gameCategory.nameAr}
                        </div>
                      )}
                    </div>
                    <div className={(i > 0 ? "mb-3 " : "") + styles.overflowX}>
                      {/*{gameCategory.viewType === "recentPlayed" && <div className="d-flex align-items-center mb-3 mt-3">*/}

                      {/*  {recentlyPlayedGameIds && recentlyPlayedGameIds.length > 0 ?*/}
                      {/*    (*/}
                      {/*      recentlyPlayedGames.map((game: GAME_ITEM, i: number) => {*/}
                      {/*        return (*/}
                      {/*          <div key={i} onClick={() => { playGame(game); }} className={styles.flexGameItems}*/}
                      {/*          //style={{ marginLeft: i > 0 ? "10px" : "0px", flex: "0 0 20%", }}*/}
                      {/*          >*/}
                      {/*            <LazyImage*/}
                      {/*              src={*/}
                      {/*                game.gifUrl && game.gifUrl !== "false"*/}
                      {/*                  ? game.gifUrl*/}
                      {/*                  : game.thumbnail*/}
                      {/*              }*/}
                      {/*              alt={game.title}*/}
                      {/*              height="100%"*/}
                      {/*              borderRadius="15px"*/}
                      {/*              width="100%"*/}
                      {/*            />*/}
                      {/*          </div>*/}
                      {/*        );*/}
                      {/*      })*/}
                      {/*    ) :*/}
                      {/*    <div className="text-white mt-2 text-center w-100">*/}
                      {/*      No recently played games.*/}
                      {/*    </div>*/}
                      {/*  }*/}
                      {/*</div>*/}
                      {/*}*/}
                      {/*style={{width: gameCategory.viewDirection === "vertical" && gameCategory?.noOfItemsPerRow ? window.innerWidth/(gameCategory?.noOfItemsPerRow) : ""}}*/}
                      <div
                        className={
                          "d-flex align-items-stretch " +
                          (gameCategory.viewDirection === "vertical"
                            ? " justify-content-left flex-wrap"
                            : " ")
                        }
                      >
                        {!loadingGames ? (
                          games.map((game: GAME_ITEM, i) => {
                            return (
                              <>
                                {gameCategory.viewDirection !== "vertical" &&
                                gameCategory.viewType === "highlighted" ? (
                                  <GameItem
                                    game={game}
                                    onGameClick={playGame}
                                    isGameInvite={false}
                                    loading={selectedGame._id === game._id}
                                    key={i}
                                    selectedLanguage={selectedLanguage}
                                    showGameItemBackground={
                                      gameCategory.viewType === "highlighted"
                                    }
                                  />
                                ) : (
                                  <GameItemWithoutBackground
                                    game={game}
                                    noOfItemsPerRow={
                                      gameCategory?.noOfItemsPerRow
                                    }
                                    onGameClick={playGame}
                                    key={i}
                                    index={i + 1}
                                    selectedLanguage={selectedLanguage}
                                    itemSpace={itemSpace}
                                    gameCategoryDirectionVertical={
                                      gameCategory.viewDirection === "vertical"
                                    }
                                  />
                                )}
                              </>
                            );
                          })
                        ) : (
                          <GameItemPlaceholder key={i} />
                        )}
                        {btn && (
                          <Link
                            to={{
                              pathname: `/games-list`,
                              state: gameCategory._id,
                            }}
                            style={{
                              textDecoration: "none",
                              width: `${92 / gameCategory?.noOfItemsPerRow}%`,
                              // margin: `15px ${selectedLanguage === 'en' ? itemSpace/gameCategory?.noOfItemsPerRow : 0}% 15px ${selectedLanguage === 'en' ? 0 : itemSpace/gameCategory?.noOfItemsPerRow}%`}
                              margin: `0 0 1rem`,
                            }}
                            onClick={() => {
                              // Analytics
                              SendAnalyticsToFirebase(
                                AnalyticsEventsName.MULTIPLAYER_VIEW_MORE_PRESSED(
                                  gameCategory.name
                                )
                              );
                            }}
                          >
                            <div
                              className={
                                "cursor-pointer align-items-stretch flex-column"
                              }
                              style={{
                                backgroundColor: "#8A0FD9",
                                borderRadius: "6px",
                                position: "relative",
                                aspectRatio: "1",
                              }}
                            >
                              <div
                                className="px-2 py-2 m-0 "
                                style={{
                                  textAlign:
                                    selectedLanguage === "ar" ? "start" : "end",
                                }}
                              >
                                <img
                                  src="/images/arrowviewmore.png"
                                  width="25px"
                                />
                              </div>
                              <div
                                className={
                                  "text-white font-16px titillium-semibold " +
                                  styles.viewMoreBtnHome
                                }
                              >
                                {t("VIEW_MORE")}
                                <br />
                                {t("GAMES")}
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  </>
                )}

              {/* {gameCategory.viewType === "liveTournamnetBanner" && ( */}
              {gameCategory.viewType === "stcplayBanner" && (
                <div
                  className={
                    "d-flex align-items-center w-100 my-3 " +
                    styles.justifyContentSpaceEvenly
                  }
                  key={i}
                  onClick={() => {
                    SendAnalyticsToFirebase(
                      AnalyticsEventsName.HOME_SCREEN_STCPLAY_BANNER_PRESSED
                    );
                  }}
                >
                  <a href={gameCategory.redirectUrl}>
                    {selectedLanguage === "en" ? (
                      <img
                        className="d-block w-100"
                        style={{ borderRadius: "4px" }}
                        src={gameCategory.icon}
                        alt="tournamentBanner"
                      />
                    ) : (
                      <img
                        className="d-block w-100"
                        style={{ borderRadius: "4px" }}
                        src={gameCategory.iconAr}
                        alt="tournamentBanner"
                      />
                    )}
                  </a>
                </div>
              )}

              {gameCategory.viewType === "inviteAndDiscover" && (
                <div
                  className={
                    "d-flex align-items-center w-100 my-4 " +
                    styles.justifyContentSpaceEvenly
                  }
                  key={i}
                >
                  <Link to="/invite-friends">
                    <PrimaryButton
                      padding="14px 32px"
                      className="font-16px titillium-semibold text-white mr-2"
                      backgroundColor={theme.colors.orange}
                      onClick={() => {
                        // Analytics
                        SendAnalyticsToFirebase(
                          AnalyticsEventsName.INVITE_FRIENDS_PRESSED
                        );
                      }}
                      borderRadius="30px"
                    >
                      {t("INVITE_FRIENDS")}
                    </PrimaryButton>
                  </Link>
                  <Link to="/discover-people">
                    <PrimaryButton
                      padding="14px 32px"
                      className="font-16px titillium-semibold text-white"
                      backgroundColor="#FFA234"
                      onClick={() => {
                        // Analytics
                        SendAnalyticsToFirebase(
                          AnalyticsEventsName.DISCOVER_PEOPLE_PRESSED
                        );
                      }}
                      borderRadius="30px"
                    >
                      {t("DISCOVER_PEOPLE")}
                    </PrimaryButton>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </LoadingGIF>
      <div id="sfakndfaja5sad5155" className="text-center">
        {!persistState.appendListLoaded && loadingGames === false && (
          <LoadingGIF size="4x"></LoadingGIF>
        )}
      </div>
    </div>
  );
};
