// #region Global Imports
import React from "react";
import styled from "styled-components";
// #endregion Global Imports

// #region Local Imports
import { ISelect } from "./Select";
// #endregion Local Imports

const _Select = styled.select<{ background?: string, borderWidth?: string, padding?: string }>`
    // background-color: ${({ background }) => { return background ? background : "#FFFFFF" }};
    border: 1px solid #FFC700; /*${({ borderWidth }) => { return borderWidth ? borderWidth : "0px" }};*/
    color: #1D0141;
    outline-width: 0;
    padding: ${({ padding }) => { return padding ? padding : "15px 15px 15px 75px" }};
    -webkit-appearance: textfield;
    outline: none;
    border-radius:5px;
    font-weight:bold;
    height:3em;
    opacity:1;
`;
export const Select: React.FunctionComponent<ISelect.IProps> = (({ options, ...rest }) => {
    return <_Select {...rest} disabled={options.length === 1 ? true : false}>

        {options.map((value: string, index: number) => (
            <option value={value} key={index}>{value}</option>
        ))}
    </_Select>;
});
