import React, { useState, useEffect, useRef, RefObject } from 'react';
import compact from "lodash/compact";
import { useTranslation } from 'react-i18next';

import { GradientLoadingRing, InputTransparent, PrimaryButton, ParagraphText } from "@Styled";
import { LoadingSpinner } from "@Components/LoadingSpinner";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";
import { theme } from "@Definitions/Styled";
import { GenericPopupModal } from '@Components/Modals/GenericPopupModal';
// export const EnterPinCode: React.FunctionComponent<{ disclaimer: string, setErrors: (a: string[]) => void, errors: string[], onResendCode: () => void, onConfirmPinCode: (code: string) => void, loading: boolean, userNumber: string, setLoginStep: (a: number) => void }>
//     = ({ disclaimer, errors, setErrors, onResendCode, onConfirmPinCode, loading, userNumber, setLoginStep }) => {
export const EnterPinPayment: React.FunctionComponent<{ setPaymentStep: (a: number) => void, confirmPin: (a: string) => any, number: string, loading: boolean, disclaimer: string,editPhoneNumber:()=>void,resendPin:()=>void }>
    = ({ setPaymentStep, confirmPin,editPhoneNumber,resendPin, number, loading, disclaimer }) => {
        const [timeLeft, setTimeLeft] = useState(40);
        // const [loading, setLoading] = useState(false);
        const [verificationCode, setVerificationCode] = useState('');
        const [email, setEmail] = useState('');
        const { t } = useTranslation();
        const pinRef: RefObject<HTMLInputElement> = useRef(null);
        const hiddenRef: RefObject<HTMLInputElement> = useRef(null);
        const [showErrorPopup, setShowErrorPopup] = useState({
            header: "",
            message: "",
        });


        useEffect(() => {
            const timer = setTimeout(() => {
                if (timeLeft > 0) setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }, [timeLeft]);
        useEffect(() => {
            pinRef.current?.focus();
        }, [])
        return <div className="d-flex flex-column w-100">
            <button
                className="d-flex justify-content-start app-back-button"
                style={{ width: "10%" }}
                onClick={() => {
                    setPaymentStep(1);
                }}
            >
                <img
                    className="mr-2"
                    height="12px"
                    width="6px"
                    src="/images/back.png"
                />
            </button>
            <ParagraphText className="font-18px titillium-semibold text-white text-center font-size-2-5">
                {t("PIN_VERIFICATION")}
            </ParagraphText>
            <div className="d-flex align-items-center justify-content-center flex-column">
                {timeLeft > 0 && <GradientLoadingRing height="64px" width="64px">
                    <span className="text-white titillium-semibold">{timeLeft}</span>
                </GradientLoadingRing>}
            </div>
            {timeLeft > 0 && <div className="font-size-1-4">
                <div className="text-center mt-3" style={{ color: "#FFC700" }}>{t('WE_HAVE_SENT_THE_PIN')}
                    <span className="text-center font-weight-bold">
                        {t('PIN_VIA_SMS')}
                    </span>
                </div>
                <div className="text-center mt-1" style={{ color: "#FFC700" }}>{t('TO_THE_FOLLOWING_NUMBER')}
                </div>
                <div onClick={() => { editPhoneNumber() }} className="d-flex text-align-center justify-content-center mt-3">
                    <span className="text-white mx-3 ltr">{number}</span>
                    <img src="/images/edit_yellow.png" height="20px" width="20px" />
                </div>
            </div>
            }
            {/* {
                timeLeft <= 0 && <>
                    <div className="text-center mt-5" style={{ color: "#FFC700" }}>{t('RESEND_PIN_TEXT')}
                    </div>
                </>
            } */}
            {
                // timeLeft > 0 &&
                <>
                    <div className="d-flex justify-content-center mt-3 flex-column">
                        <div className="d-flex justify-content-between text-white titillium-semibold font-18px mb-2">
                            <span>{t('PLEASE_ENTER_PIN_RECEIVED_IN_SMS')}</span>
                            <img width="25px" className="cursor-pointer"
                                // onClick={() => showInfoModal(true)} 
                                src="/images/paywall/alert-circle.png" />
                        </div>
                        <input type="tel"
                            ref={pinRef}

                            style={{
                                margin: " 0 5px",
                                textAlign: "center",
                                lineHeight: "60px",
                                outline: "none",
                                width: "100%",
                                transition: "all .2s ease-in-out",
                                border: "1px solid #FFC700",
                                zIndex: 1,
                                opacity: 1,
                                padding: "0px",
                                borderRadius: "5px",
                                // color: "rgba(29, 1, 65, 0.3)"
                                color: "#1D0141",
                                fontWeight: "bold",
                                height: "2.4em"
                            }} maxLength={6}
                            minLength={4}
                            placeholder={t("PIN")}
                            value={verificationCode}
                            className="font-size-2"
                            onChange={(event) => {
                                const numberRegex = /^\d+$/;
                                if ((numberRegex.test(event.target.value) || event.target.value === "") && event.target.value.length <= 6) {
                                    setVerificationCode(event.target.value);
                                }
                            }}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    let button = document.getElementById("primary");
                                    if (button) {
                                        button.click();
                                        pinRef.current?.blur();
                                    }
                                }
                            }}
                            onFocus={() => {
                                // setTimeout(() => {
                                hiddenRef.current?.scrollIntoView();

                            }}
                        />

                        {/* <div className="d-flex justify-content-between text-white titillium-semibold font-18px mb-2">
                            <span>{t('PLEASE_ENTER_EMAIL')}</span>
                        </div>
                        <input type="email"
                            style={{
                                margin: " 0 5px",
                                textAlign: "center",
                                lineHeight: "60px",
                                outline: "none",
                                width: "100%",
                                transition: "all .2s ease-in-out",
                                border: "1px solid #FFC700",
                                zIndex: 1,
                                opacity: 1,
                                padding: "0px",
                                borderRadius: "5px",
                                // color: "rgba(29, 1, 65, 0.3)"
                                color: "#1D0141",
                                fontWeight: "bold",
                                height: "2.4em"
                            }}
                            placeholder={t("EMAIL")}
                            value={email}
                            className="font-size-2"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            onFocus={() => {
                                hiddenRef.current?.scrollIntoView();

                            }}
                        /> */}
                    </div>

                    <div className="d-flex w-100 justify-content-center">
                        <PrimaryButton
                            className="font-20px titillium-semibold text-primary position-relative  ltr mt-5 mx-3 get-pin-button py-3"
                            backgroundColor={"#FFC700"}
                            borderRadius="30px"
                            disabled={loading}
                            id="primary"
                            onClick={async () => {
                                if (!loading ) {
                                    if (verificationCode.length >= 4) {
                                        confirmPin(verificationCode)
                                    } else {
                                        setShowErrorPopup({ header: t("INVALID_OTP_HEADER"), message: t("INVALID_OTP") });
                                    }
                                }
                            }}>
                            {t('SUBSCRIBE')}
                            {loading && <div className="position-absolute" style={{ left: 0, right: 0, top: 5 }}>
                                <img src="/continue_loader_1.gif" style={{ width: "50px" }} />
                            </div>}
                        </PrimaryButton>
                    </div>
                </>}
            <div className={"text-center " + (timeLeft <= 0 ? "mt-3" : "")}>
                <div className="text-white mb-1 font-20">{t('NOT_GET_PIN_TEXT')}</div>
                <div onClick={() => {
                    timeLeft <= 0 && resendPin();
                }} className="titillium-semibold  font-size-1-4 text-yellow100 cursor-pointer">{
                t('RESEND_PIN')}</div>
            </div>
            <div className="text-center mt-4 font-18px">
                <div className="text-white mt-1">{disclaimer}</div>
            </div>

            {(showErrorPopup.header || showErrorPopup.message) && (
                <GenericPopupModal
                title={showErrorPopup.header}
                description={showErrorPopup.message}
                showSingleButton={true}
                showInfoIcon={true}
                firstButtonText={t("OK")}
                firstButtonCallback={() => {
                  setShowErrorPopup({ header: "", message: "" });
                }}
              />
            )
            }
        </div >
    }