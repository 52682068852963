import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "@brixtol/currency-codes";
import { PaymentMethodType } from "@Constants";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { getUserActivePaymentMethod } from "@HelperFunctions";
import { useSelector } from "react-redux";
import { IStore } from "@Redux/IStore";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { ImageModal } from "@Components/Modals/ImageModal";

const PaymentMethod: React.FC<{
  method: SUB_BUNDLES;
  click: (a: any) => void;
  onVideoPlay: (a: any) => void;
  packageType: string;
  currentCountryISO: string;
}> = ({ method, click, onVideoPlay, packageType, currentCountryISO }) => {
  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const persistState = useSelector((state: IStore) => state.persistState);
  const { session } = persistState;
  const activeMethod = getUserActivePaymentMethod(session);
  let classNameStr =
    "d-flex justify-content-between align-items-center  payment-method my-2 cursor-pointer ";
  const showSelection = activeMethod
    ? activeMethod.serviceId === method.serviceId
      ? (classNameStr += "selected-payment-method")
      : false
    : false;

  const getImageStyleAccordingToMethodType = () => {
    if (method.paymentType === PaymentMethodType.EASYPAISA)
      return { width: "130px" };
    else if (method.paymentType === PaymentMethodType.GPLAY)
      return { width: "90px" };
  };

  const showAlertPopup = () => {
    return (
      <GenericPopupModal
        title={t("ALREADY_SUBSCRIBE")}
        description={t("ALREADY_SUB_BUNDLE")}
        showSingleButton={true}
        showInfoIcon={true}
        firstButtonText={t("OK")}
        firstButtonCallback={() => {
          // setShowPopup(false)
          // console.log(showPopup)
          return;
        }}
      />
    );
  };

  const getCurrencyValue = () => {
    if (
      method.paymentType.toLowerCase() ===
        PaymentMethodType.GPLAY.toLowerCase() &&
      method.extras &&
      //@ts-ignore
      method.extras.productId.toLowerCase().includes("global")
    )
      return "USD";

    return getCurrency(currentCountryISO);
  };

  return (
    <>
      <div
        className={classNameStr}
        onClick={() => {
          if (showSelection) {
            setShowPopup(!showPopup);
            return;
          }
          click(method);
        }}
      >
        <div className="d-flex flex-column w-50">
          {!!method.isPopular && (
            <div className="d-flex font-13px mb-2 align-items-center">
              <img
                style={{ width: "15px", height: "15px" }}
                src="/images/paywall/most_popular.png"
              />
              <div className="d-flex px-2 most-popular">
                {t("MOST_POPULAR")}
              </div>
            </div>
          )}
          {method.paymentType === PaymentMethodType.MOBILE ? (
            <>
              <div className="font-weight-bold font-20px d-flex flex-column align-items-center " >
                {t("PAY_WITH")} {method.operatorTitle}
                {method.operatorImage && (
                  <img
                    width="35%"
                    className="ml-1"
                    src={method.operatorImage}
                  />
                )}
              </div>
              {/* {method.availavleTelcos.length > 1 && <div className="d-flex mt-1 overflow-x-scroll" style={{ overflow: "scroll" }}>{method.availavleTelcos.map((image: string) => <img width="25px" className="mr-1" src={image} />)}</div>} */}
            </>
          ) : (
            <img
              style={getImageStyleAccordingToMethodType()}
              src={
                method.operatorImage ||
                `/images/paywall/${method.paymentType.toLowerCase()}.svg`
              }
            />
          )}
        </div>
        <div className="d-flex flex-column">
          {method.discountedPrice && (
            <div className="d-flex align-items-center font-13px">
              <div className="d-flex text-through">
                {getCurrencyValue() + " " + method.price}
              </div>
              <div className="d-flex save px-2 mx-2 ">
                {"Save " +
                  (
                    (100 * (method.price - method.discountedPrice)) /
                    method.price
                  ).toFixed(0) +
                  "%"}
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="d-flex font-24px">
              {getCurrencyValue() +
                " " +
                (method.discountedPrice
                  ? method.discountedPrice
                  : method.price)}
              <span className="font-13px align-self-end inclusive-tax">
                {"/" + packageType}
              </span>
            </div>
            <div
              className="font-13px align-self-start inclusive-tax"
              style={{ marginTop: -4 }}
            >
              {t("INCLUSIVE_TAX")}
            </div>
          </div>
        </div>
        {showPopup && showAlertPopup()}
      </div>
      {/* {method.helpUrl && method.helpUrl.trim().length > 0 && ( */}
        <>
          <div className="d-flex text-white justify-content-center align-items-center mb-3">
          <span style={{height:'12px'}}/>
          {method.helpUrl && method.helpUrl.trim().length > 0 &&(
            <>
            
            <span>{t("HAVING_TROUBLE")}</span>   
            <span
              style={{ color: "#FFC700", cursor: "pointer" }}
              onClick={() => {
                onVideoPlay(method);
              }}
            >
              &nbsp;&nbsp;{t("WATCH_THIS_VIDEO")}
              <img width="12px" src="/images/paywall/video_play.png" />
            </span>
            </>
          ) }
            
          </div>
        </>
      {/* )} */}
    </>
  );
};

export default PaymentMethod;
