// Global Imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
//
// Local Imports
import { AuthenticationActions } from "@Actions/Authentication";
import { IStore } from "@Redux/IStore";
import { theme } from "@Definitions/Styled";
import { PrimaryButton, ParagraphText } from "@Styled";
// import { LoadingSpinner } from '@Components/LoadingSpinner';
import { LoadingGIF } from '@Components/LoadingSpinner/LoadingGIF';
//

export const UnSubContainer: React.FunctionComponent = () => {
    const persistState = useSelector((state: IStore) => state.persistState);
    const [errors, setErrors] = useState(['']);
    const [loading, setLoading] = useState(false);
    const [userNumber, setUserNumber] = useState('+92');
    const dispatch = useDispatch();

    const onUnSubClick = async () => {
        if (userNumber) {
            setLoading(true);
            const unSubResponse: any = await dispatch(AuthenticationActions.UnSub({
                msisdn: userNumber.replace('+', '')
            }));
            if (unSubResponse.success && unSubResponse.response_code === 0) {
                dispatch(AuthenticationActions.LogoutUser());
                window.location.href = "/";
            } else {
                setErrors([unSubResponse.message])
            }
            setLoading(false);
        }
    }

    return <div className="d-flex flex-column h-100 pb-4 align-items-center" style={{ paddingTop: "40px" }}>
        <div className="d-flex flex-column justify-content-center px-3 w-100">
            <div className="text-center">
                <img src="/images/logo_green2.png" width="70px" height="25px" />
            </div>
            {/* <div className="text-grey100 text-center font-18px">Un Subscribe</div> */}
        </div>
        <div className="px-4 d-flex flex-column align-items-center justify-content-center mt-5"
            style={{ width: "320px" }}>
            <input style={{
                marginTop: "20px", width: "100%", height: "40px", border: "transparent",
                color: "white", borderBottom: "1px solid grey", outline: "none",
                padding: "15px", background: "transparent"
            }}
                type="tel"
                name="mobile_number"
                onChange={(e) => {
                    const reg = /^\d+$/;
                    if (reg.test(e.target.value.replace('+', ''))) {
                        setUserNumber(e.target.value);
                    }
                    if (e.target.value === '+') {
                        setUserNumber(e.target.value);
                    }
                }}
                value={userNumber}
                required
                disabled={false} />

            <div className="text-grey100 text-left mt-2 w-100 font-10px">Fromat: +9234********</div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mt-3">
            <div>
                <PrimaryButton
                    className="font-18px titillium-semibold text-primary w-100 mt-3"
                    backgroundColor={theme.colors.yellow}
                    borderRadius="30px"
                    onClick={() => {
                        !loading && userNumber && onUnSubClick();
                    }}>
                    {"Un Subscribe"}
                    {loading && <LoadingGIF color="white" size="1x" />}
                </PrimaryButton>
            </div>
            <ParagraphText className="text-danger">{errors.length ? errors[0] : ''}</ParagraphText>
        </div>
    </div>
}