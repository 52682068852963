import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";

import styles from "./style.module.scss";
import {
  GAME_ITEM,
  MATCH_MAKING_TEMPLATE,
  PLAYERS,
  TOURNAMENT_LEADERBOARD_USER,
  USER,
  USER_SESSION,
} from "@Interfaces";
import {
  BackgroundImage,
  CircularImage,
  ParagraphText,
  PrimaryButton,
  StaticImage,
} from "@Styled";
import { theme } from "@Definitions/Styled";
import {
  removeObserverOnMatchMaking,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { IStore } from "@Redux/IStore";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MatchStatusType, MessageType, PlayerStatusType } from "@Constants";
import { useTranslation } from "react-i18next";
import { InviteModal } from "@Components/Modals/InviteModal";
import { PlayersView } from "@Components/MatchMaking/playersView";
import { InviteActions } from "@Actions/Invite";
import { TournamentActions } from "@Actions/Tournament";
import { HomeActions } from "@Actions/Home";
import { WarningModal } from "@Components/Modals/WarningModal";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import { MultiGamePlayAction } from "@Actions/MultiGamePlayAction";
import { getTemplateForResult } from "./helpers";
import Counter from "@Components/Counter";
import {
  addLeadingZero,
  getPositionSupText,
  getProfileImage,
} from "@HelperFunctions";

export const numOfPlayersInMatch = (
  matchConfiguration: MATCH_MAKING_TEMPLATE,
  matchType?: string,
  getMin?: boolean
) => {
  const {
    maxPlayerCount,
    minPlayerCount,
    maxTeamCount,
    minTeamCount,
    playersInTeam,
    isSelectable,
    teamUp,
  } = matchConfiguration;
  let playersInMatch;
  if (!isSelectable) {
    if (maxTeamCount === 0) playersInMatch = maxPlayerCount;
    else if (maxTeamCount > 0) playersInMatch = playersInTeam * maxTeamCount;
  } else if (isSelectable && !getMin) {
    if (maxTeamCount === 0) playersInMatch = maxPlayerCount;
    else if (maxTeamCount > 0) playersInMatch = playersInTeam * maxTeamCount;
  } else if (isSelectable && getMin) {
    if (minTeamCount === 0 && (matchType === "individual" || matchType === ""))
      playersInMatch = minPlayerCount;
    else if ((minTeamCount === 0 && matchType === "team") || maxTeamCount > 0)
      playersInMatch = playersInTeam * maxTeamCount;
  }
  return playersInMatch;
};
export const MatchMaking: React.FunctionComponent<{
  gameResultScores: any;
  onPlayMoreGamesClick: () => void;
  playAgainStatus: string;
  title: string;
  cancelMatchMaking: (a?: boolean, b?: boolean) => void;
  players: any;
  gameResult: string;
  onPlayAgain: (a?: USER) => void;
  acceptGameInviteAgain: () => void;
  selectedGame: GAME_ITEM;
  showInvitePopup: (a: any) => void;
  selectedLanguage?: string;
  setGameMusicStatus: (a: boolean) => void;
  gameThumbnail: string;
  onMatchFound: (data: any) => void;
  setStartGame: (a: boolean) => void;
}> = ({
  gameResultScores,
  onPlayMoreGamesClick,
  playAgainStatus,
  title,
  cancelMatchMaking,
  players: apiPlayers,
  gameResult,
  onPlayAgain,
  acceptGameInviteAgain,
  selectedGame,
  showInvitePopup,
  selectedLanguage,
  setGameMusicStatus,
  gameThumbnail,
  onMatchFound,
  setStartGame,
}) => {
  const [otherUserProfileImage, setOtherUserProfileImage] = useState("");
  const [matchFinished, setMatchFinished] = useState(false);
  const [matchPlayAgain, setMatchPlayAgain] = useState(false);
  const [inviteReceived, setInviteReceived] = useState(false);
  const [showJoinGameButton, setShowJoinGameButton] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [teamPlayers, setTeamPlayers] = useState(2);
  const [showInvite, setShowInvite] = useState(false);
  const [playerIndex, setPlayerIndex, playerIndexRef] = useStateRef(0);
  const [showBotsConfirmation, setShowBotsConfirmation] = useState(false);
  const {
    specificSelectedConfiguration: matchMakingTemplate,
    matchObject: { matchId, players, allPlayers },
    isHost,
  } = useSelector((state: IStore) => state.multiPlayState);
  const showToggle =
    matchMakingTemplate.isSelectable &&
    matchMakingTemplate.maxTeamCount > 0 &&
    matchMakingTemplate.minTeamCount === 0;
  const [matchType, setMatchType] = useState(showToggle ? "individual" : "");
  const { userFriends, session } = useSelector(
    (state: IStore) => state.persistState
  );
  const invite: any = useSelector((state: IStore) => state.conversation);
  const {
    isTournamentMatch,
    selectedTournament,
    tournamentLeaderboardData: { currentUser },
  } = useSelector((state: IStore) => state.tournamentState);
  const inviteReceive = invite.inviteReceived;
  let playersInMatch = numOfPlayersInMatch(matchMakingTemplate);
  if (gameResult && apiPlayers?.length != playersInMatch) {
    const resultTemplate = getTemplateForResult(apiPlayers?.length)!;
    if (resultTemplate) {
      playersInMatch = numOfPlayersInMatch(resultTemplate);
    }
  }
  const minPlayersInMatch = numOfPlayersInMatch(
    matchMakingTemplate,
    matchType,
    true
  );
  const { teamUp } = matchMakingTemplate;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const arrayWithoutNullEntries = players.filter(
    (player: PLAYERS) => player !== null
  );
  const arrayHasPendingRequest = allPlayers.some(
    (player: PLAYERS) => player?.status === PlayerStatusType.PENDING
  );
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  useEffect(() => {
    if (gameResult) {
      setMatchFinished(true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_MATCH_END);
    } else setMatchFinished(false);
  }, [gameResult]);
  useEffect(() => {
    if (
      matchMakingTemplate.maxTeamCount > 0 &&
      matchMakingTemplate.minTeamCount > 0 &&
      !showToggle
    ) {
      dispatch(
        MultiGamePlayAction.SetSpecificSelectedConfiguration({
          ...matchMakingTemplate,
          teamUp: true,
        })
      );
    }
  }, [players]);

  useEffect(() => {
    if (players.length === playersInMatch || matchMakingTemplate.isSelectable) {
      setTimeElapsed(0);
    } else {
      setTimeout(() => {
        setTimeElapsed(timeElapsed + 1);
      }, 1000);
    }
    if (timeElapsed > 30) cancelMatchMaking();
  }, [timeElapsed]);

  useEffect(() => {
    if (inviteReceive && !isFirst) {
      // setInviteReceived(true);
      if (inviteReceive.type === MessageType.INVITE) {
        let message = JSON.parse(inviteReceive.message);
        let gameId = message._gameId;
        if (
          gameId === selectedGame.gameId &&
          matchId === inviteReceive?.previousMatchId
        ) {
          setShowJoinGameButton(true);
        } else if (
          gameId === selectedGame.gameId &&
          inviteReceive.sender !== session._id
        ) {
          setShowJoinGameButton(false);
          showInvitePopup(inviteReceive);
        }
        if (
          inviteReceive.inviteAction &&
          inviteReceive.inviteAction === "canceled"
        ) {
          setInviteReceived(false);
          showInvitePopup(inviteReceive);
          setShowJoinGameButton(false);
        }
        if (gameId !== selectedGame.gameId) {
          showInvitePopup(inviteReceive);
          setShowJoinGameButton(false);
        }
      }
    } else {
      setInviteReceived(false);
      setShowJoinGameButton(false);
    }
    setIsFirst(false);
  }, [inviteReceive]);
  const onMoreGamesClick = () => {
    // if (!otherUser) return;
    //
    isTournamentMatch &&
      SendAnalyticsToFirebase(AnalyticsEventsName.TOURNAMENT_CHAT_PRESSED);
    onPlayMoreGamesClick();
  };

  const updateGameBoardView = async (matchType: string) => {
    setMatchType(matchType);
    let teamUp;
    if (matchType === "team") teamUp = true;
    else if (matchType === "individual") teamUp = false;

    const result = await dispatch(
      InviteActions.UpdateGameBoardView({
        matchMakingTemplate: { ...matchMakingTemplate, teamUp },
        matchId: matchId,
      })
    );
  };
  const showFriendsPopUp = (index: number) => {
    setPlayerIndex(index);
    setShowInvite(true);
  };

  const inviteUser = async (user?: USER, playerIndex?: number) => {
    return dispatch(
      InviteActions.SendMatchInvite({
        currentUserId: session._id,
        otherUserId: user ? user._id : "",
        matchId: matchId,
        userPlaceIndex: playerIndexRef.current,
      })
    );
  };
  const onFriendInvite = async (user?: USER) => {
    setShowInvite(false);
    const result = await inviteUser(user);
    // console.log('result of Invite **********', result);
  };

  const sendInviteForPlayAgain = async () => {
    onPlayAgain();
    setTimeout(() => {
      const playersWithoutUser = players.filter(
        (player: PLAYERS) => session._id !== player._id
      );
      playersWithoutUser.map((player: any, index: number) =>
        inviteUser(player, index + 1)
      );
    }, 2000);
  };

  const showAddPlayerDialog = () => {
    setShowBotsConfirmation(true);
  };

  const startMatch = async () => {
    setStartGame(true);
    const result: any = await dispatch(
      HomeActions.StartMatchOnRequest({
        matchId: matchId,
      })
    );
    const response = result.response;
    if (response.status === MatchStatusType.STARTED) {
      onMatchFound(response);
    }
    dispatch(
      MultiGamePlayAction.SetMatchObject({ allPlayers: response.players })
    );
    // console.log('result of Play match **********', result);
  };
  const leaveGame = async () => {
    const result = await dispatch(
      HomeActions.LeaveGame({
        matchId: matchId,
        userId: session._id,
      })
    );
    cancelMatchMaking(false);
    // console.log('result of leave match **********', result);
  };

  return (
    <div
      style={{
        zIndex: 1,
        background: "url('/images/newDesign/BG_Design.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflow: "scroll",
      }}
      className="w-100 h-100 bg-primary hide-y-scroller"
    >
      <div
        className="d-flex justify-content-between align-items-start text-center font-18px px-3 pt-3"
        style={{ minHeight: "6%" }}
      >
        <div
          className="d-flex align-items-center"
          // style={{minHeight: '58px'}}
        >
          {!gameResult && (
            <button
              className="app-back-button"
              onClick={isHost ? () => cancelMatchMaking() : leaveGame}
            >
              <img
                className="mr-2"
                // height="12px"
                width="9px"
                src="/images/back.png"
              />
            </button>
          )}
        </div>

        {gameResult && isTournamentMatch && (
          <div className="text-white font-16px">
            <img
              className="mr-2"
              style={{ verticalAlign: "sub" }}
              src="images/tournament/timer.png"
              alt="timer"
              width={18}
            />
            <Counter endTime={selectedTournament.expireTime} />
          </div>
        )}

        {showToggle && isHost && !gameResult && (
          <div className={styles.toggleButtons}>
            {matchType === "individual" ? (
              <>
                <div className="position-relative" style={{ width: "63%" }}>
                  <div
                    className={`text-white font-18px titillium-semibold text-center ${styles.matchTypeButtonText}`}
                  >
                    Individual
                  </div>
                  <img
                    src="/images/newDesign/ellipse.png"
                    width="7"
                    alt="ellipse"
                    className={styles.imageEllipse}
                  />
                </div>
                <span
                  onClick={() => updateGameBoardView("team")}
                  className={styles.teamsText + " titillium-semibold"}
                >
                  Team{" "}
                </span>
              </>
            ) : (
              <>
                <span
                  onClick={() => updateGameBoardView("individual")}
                  className={styles.teamsText + " titillium-semibold "}
                  style={{ width: "63%" }}
                >
                  Individual{" "}
                </span>
                <div className="position-relative" style={{ width: "50%" }}>
                  <div
                    className={`text-white font-18px titillium-semibold text-center ${styles.matchTypeButtonText}`}
                  >
                    Team
                  </div>
                  <img
                    src="/images/newDesign/ellipse.png"
                    width="7"
                    alt="ellipse"
                    className={styles.imageEllipse}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/*NEED TO UNCOMMENT THIS*/}

      {/*{(!isTournamentMatch) && <div*/}
      {/*  className="d-flex flex-column align-items-center justify-content-center pb-2 mx-auto">*/}
      {/*  <div*/}
      {/*  // className="position-absolute"*/}
      {/*  >*/}
      {/*    <img src={'/images/Carrom.png'} alt="" width="150"*/}
      {/*      // style={{ border: '3px solid #FEC144', borderRadius: ' 6px 6px 0px 0px' }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  /!*<div*!/*/}
      {/*  /!*  // className="position-absolute"*!/*/}
      {/*  /!*  style={{*!/*/}
      {/*  /!*    background: "url('/images/gametitle_banner.png')",*!/*/}
      {/*  /!*    height: "40px",*!/*/}
      {/*  /!*    width: "225px",*!/*/}
      {/*  /!*    backgroundSize: "cover",*!/*/}
      {/*  /!*    backgroundPosition: "center",*!/*/}
      {/*  /!*    top: "31px"*!/*/}
      {/*  /!*  }}>*!/*/}
      {/*  /!*  <div style={{ paddingTop: '6px' }}*!/*/}
      {/*  /!*    className="text-center text-black titillium-semibold font-18x">{title}</div>*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</div>}*/}

      {/*TESTING FOR NEW GAME TITLE*/}
      {/*{(matchFinished && !isTournamentMatch) && <div*/}
      {/*  className="d-flex flex-column align-items-center justify-content-center pb-2 mx-auto">*/}
      {/*  <div>*/}
      {/*    <img src="/images/game_thumb.png" alt="" width="200"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>}*/}

      {!matchFinished && isTournamentMatch && (
        <div className="d-flex flex-column align-items-center justify-content-center pb-2 mx-auto">
          <div
          // className="position-absolute"
          >
            <img
              src={selectedTournament?.thumbnail || gameThumbnail}
              alt=""
              width="70"
              style={{
                border: "3px solid #8A0FD9",
                borderRadius: " 6px 6px 0px 0px",
              }}
            />
          </div>
          <div
            style={{
              padding: "6px 60px",
              backgroundColor: "#8A0FD9",
              borderRadius: "10px",
              marginTop: "-3px",
            }}
            className="text-center text-white titillium-semibold font-18x"
          >
            {selectedTournament?.name}
          </div>
        </div>
      )}

      <div
        className="d-flex flex-column justify-content-center px-3 py-3 align-items-center"
        style={{
          minHeight: matchFinished || isTournamentMatch ? "80%" : "80%",
        }}
      >
        {!isTournamentMatch && (
          <div className="d-flex flex-column align-items-center justify-content-center pb-2 mx-auto">
            <div
            // className="position-absolute"
            >
              <img
                src={selectedGame.titleIcon}
                alt=""
                width="120"
                // style={{ border: '3px solid #FEC144', borderRadius: ' 6px 6px 0px 0px' }}
              />
            </div>
            {/*<div*/}
            {/*  // className="position-absolute"*/}
            {/*  style={{*/}
            {/*    background: "url('/images/gametitle_banner.png')",*/}
            {/*    height: "40px",*/}
            {/*    width: "225px",*/}
            {/*    backgroundSize: "cover",*/}
            {/*    backgroundPosition: "center",*/}
            {/*    top: "31px"*/}
            {/*  }}>*/}
            {/*  <div style={{ paddingTop: '6px' }}*/}
            {/*    className="text-center text-black titillium-semibold font-18x">{title}</div>*/}
            {/*</div>*/}
          </div>
        )}

        <div className={styles.primaryToWhiteGradient + " position-relative"}>
          {!matchFinished && !isTournamentMatch && (
            <div className="d-flex align-items-center font-24px titillium-semibold text-white justify-content-center pb-4">
              {/*       style={{border: '3px solid #FEC144', borderRadius: ' 6px 6px 0px 0px'}}/>*/}
              {/*</div>*/}
              {/*<div className="position-absolute" style={{*/}
              {/*  background: "url('/images/gametitle_banner.png')",*/}
              {/*  height: "40px",*/}
              {/*  width: "225px",*/}
              {/*  backgroundSize: "cover",*/}
              {/*  backgroundPosition: "center",*/}
              {/*  top: "32px"*/}
              {/*}}>*/}
              {/*  <div style={{paddingTop: '6px'}} className="text-center text-black titillium-semibold font-18x">{title}</div>*/}
              {/*</div>*/}- {matchMakingTemplate.title} -
            </div>
          )}
          {/*<br />*/}

          {/*{teamPlayers === 2 && <div>*/}
          {gameResult && (
            <div
              className={`titillium-semibold px-2 overflow-dotted ${
                gameResult === "draw" && "text-center"
              }`}
              style={{
                fontWeight: 700,
                fontSize: "28px",
                padding: "10px 0px 20px",
                display: "flex",
                justifyContent: "center",
                color: isTournamentMatch
                  ? `${theme.colors.yellow100}`
                  : "white",
              }}
            >
              <span className="px-2">
                {gameResult !== "draw"
                  ? `YOU ${gameResult?.toUpperCase()}`
                  : gameResult?.toUpperCase()}
              </span>
            </div>
          )}
          <div className="px-3 position-relative">
            <PlayersView
              players={allPlayers}
              gameResultScores={gameResultScores}
              teamView={matchType}
              singlePlayer={false}
              showGlow={gameResult === "win"}
              gameResult={gameResult}
              showFriends={showFriendsPopUp}
              showingResult={gameResult ? true : false}
              apiPlayers={apiPlayers}
            />

            {!(playersInMatch && playersInMatch > 2) && (
              <img
                style={{
                  mixBlendMode: "screen",
                  position: "absolute",
                  right: "49%",
                  top: gameResultScores ? "8%" : "0%",
                }}
                src="/images/newDesign/Frame.png"
              />
            )}
            <img
              style={{
                position: "absolute",
                right: "43.6%",
                top:
                  playersInMatch && playersInMatch > 2
                    ? gameResultScores
                      ? "38%"
                      : "38%"
                    : gameResultScores
                    ? "67%"
                    : "63%",
              }}
              src="/images/newDesign/VS.png"
              height="35px"
            />
            {/*{!!gameResultScores.length &&*/}
            {/*  <div*/}
            {/*    className={`text-white titillium-semibold px-2 overflow-dotted position-absolute ${styles.winLoseText}`}*/}
            {/*    style={{right: '44%', top: '71%'}}>*/}
            {/*    <span className="px-2">-</span>*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
          {/*</div>}*/}
          {showInvite && (
            <InviteModal
              usersArray={(userFriends && userFriends) || []}
              onFriendInvite={onFriendInvite}
              open={showInvite}
              setOpen={setShowInvite}
            />
          )}
          {showBotsConfirmation && (
            <GenericPopupModal
              // title={t("SAVE_DATA")}
              description={t("PLAY_NOW_OR_ADD_MORE")}
              showInfoIcon={true}
              firstButtonText={t("PLAY_NOW")}
              secondButtonText={t("ADD_MORE")}
              secondButtonCallback={() => {
                setShowBotsConfirmation(!showBotsConfirmation);
              }}
              firstButtonCallback={() => {
                setShowBotsConfirmation(!showBotsConfirmation);
                startMatch();
                //(window as any).userSessionTokenChanged = false;
                //setShowSessionOutModal(false);
              }}
            />
          )}
        </div>
        {/*<br/>*/}
        {!gameResult &&
          teamPlayers &&
          matchMakingTemplate.isSelectable &&
          isHost && (
            <div className="d-flex align-items-center justify-content-center my-2 pt-4">
              <PrimaryButton
                className="font-24px titillium-semibold text-black w-100 mx-2"
                padding="6px 40px"
                background={
                  arrayWithoutNullEntries.length >= minPlayersInMatch!
                    ? "linear-gradient(180deg, #A3CD3A 0%, #5E8400 100%)"
                    : theme.colors.grey400
                }
                borderRadius="50px"
                disabled={arrayWithoutNullEntries.length < minPlayersInMatch!}
                color={"white"}
                onClick={
                  arrayWithoutNullEntries.length !== playersInMatch
                    ? showAddPlayerDialog
                    : startMatch
                }
              >
                {t("PLAY")}
              </PrimaryButton>
            </div>
          )}
        {!gameResult &&
          !matchPlayAgain &&
          teamPlayers &&
          !matchMakingTemplate.isSelectable && (
            <>
              <div className="text-center titillium-web font-16px text-white">
                {t("FINDING_OPPONENT")}
              </div>
              <div className="text-center titillium-web font-16px text-yellow pt-1">
                {" "}
                {`${addLeadingZero(timeElapsed)} sec`}{" "}
              </div>
            </>
          )}
        <br />
        {!gameResult &&
          !matchPlayAgain &&
          teamPlayers &&
          !matchMakingTemplate.isSelectable && (
            <img
              className="cursor-pointer"
              src="/images/cancel_matchmaking.png"
              onClick={() => {
                cancelMatchMaking();

                // Analytics
                !isTournamentMatch &&
                  SendAnalyticsToFirebase(
                    AnalyticsEventsName.ON_MP_WAITING_EXIT
                  );
              }}
            />
          )}

        {gameResult && (
          <div className="d-flex flex-column" style={{ width: "360px" }}>
            <div className="d-flex align-items-center justify-content-center my-2">
              <PrimaryButton
                className="font-18px titillium-semibold text-white w-100 mx-2"
                padding="8px 10px"
                backgroundColor={
                  players &&
                  players[0]?._id !== session._id &&
                  !showJoinGameButton
                    ? theme.colors.grey400
                    : theme.colors.yellow
                }
                borderRadius="50px"
                disabled={
                  players[0]?._id !== session._id && !showJoinGameButton
                }
                onClick={() => {
                  if (showJoinGameButton) {
                    setGameMusicStatus(true);
                    acceptGameInviteAgain();
                  } else {
                    isTournamentMatch &&
                      SendAnalyticsToFirebase(
                        AnalyticsEventsName.TOURNAMENT_PLAY_AGAIN_PRESSED(
                          selectedTournament.name
                        )
                      );
                    onPlayAgain();
                    dispatch(
                      MultiGamePlayAction.SetMatchObject({ players: [] })
                    );
                    setGameMusicStatus(true);
                    setMatchPlayAgain(true);
                    showToggle && teamUp && setMatchType("team");
                  }

                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.ON_REMATCH);
                }}
              >
                {playAgainStatus
                  ? playAgainStatus
                  : showJoinGameButton
                  ? t("JOIN")
                  : t("PLAY_AGAIN")}
              </PrimaryButton>

              <PrimaryButton
                className="font-18px titillium-semibold text-black w-100 "
                padding="8px 10px"
                backgroundColor={theme.colors.grey600}
                borderRadius="50px"
                color="black"
                onClick={() => {
                  onMoreGamesClick();
                }}
              >
                {!isTournamentMatch ? t("PLAY_MORE_GAMES") : t("CHAT")}
              </PrimaryButton>
            </div>
            {/* <br/>
        <div className="d-flex align-items-center justify-content-between my-2">
          {[1, 2, 3, 4, 5, 6].map((n, i) => {
            return <img key={i} src={"/images/emoji_" + n + ".png"} height="42px" width="42px"/>
          })}
        </div> */}
          </div>
        )}

        {gameResult && isTournamentMatch && (
          <div className="position-relative text-white d-flex justify-content-center align-items-center">
            {currentUser && (
              <div
                style={{
                  padding: "10px",
                  width: "85%",
                  backgroundColor: "#2f035b",
                  borderRadius: "6px",
                }}
                className="text-center text-white titillium-web font-18x position-absolute"
              >
                {/*className={`card px-1 py-3 my-1 text-white ${styles.transparentBgBlue} `}>*/}
                <div className="row align-items-center">
                  <div className="col-2">
                    <div
                      // className={styles.verticalAlignSpan + " h-100"}>{(item._id ? item._id : "") === session._id ? leaderBoard?.currentUser.userRank : index + 1}</div>
                      className={
                        styles.verticalAlignSpan + " h-100 itillium-semibold"
                      }
                    >
                      {currentUser?.rank || 0}{" "}
                      <sup> {getPositionSupText(currentUser?.rank || 0)} </sup>
                    </div>
                  </div>
                  <div className="col-7 ">
                    <div className="row">
                      <div className="col-6 col-sm-4">
                        <CircularImage
                          src={[
                            getProfileImage(session),
                            "/images/profile_image_placeholder.png",
                          ]}
                          height="46px"
                          width="46px"
                        />
                      </div>
                      <div className="col-6 col-sm-8 d-flex align-items-center">
                        {/* <div className={styles.verticalAlignSpan + " h-100"}> */}
                        {session.name}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div className={styles.verticalAlignSpan + " h-100"}>
                      {currentUser?.wins || 0}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Link
              to={{
                pathname: `/tournament-preplay/${selectedTournament._id}`,
                state: 1,
              }}
              onClick={() => {
                cancelMatchMaking(false, true);
                SendAnalyticsToFirebase(
                  AnalyticsEventsName.TOURNAMENT_VIEW_LEADERBOARD(
                    selectedTournament.name
                  )
                );
              }}
            >
              <span
                className="font-16px titillium-semibold position-absolute text-center w-100 px-3 text-white"
                style={{ bottom: "10px" }}
              >
                {" "}
                VIEW LEADERBOARD
              </span>
            </Link>
            <div
              style={{
                backgroundColor: "#430676",
                borderRadius: "10px",
                minHeight: "137px",
                width: "340px",
                marginTop: "30px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
