// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, ILoginPage } from "@Interfaces";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { VerificationScreen } from "@Services/API/VerificationScreens/VerificationScreen";
// #endregion Interface Imports

const INITIAL_STATE: any = {
  exclusiveFeature: false,
};

export const ExclusiveFeaturesReducer = (
  state = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case ActionConsts.ExclusiveFeatures.SetExclusiveFeature: {
      return Object.assign({}, state, { exclusiveFeature: action.payload });
    }

    default:
      return state;
  }
};
