// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";

export const ExclusiveFeaturesActions = {
  SetExclusiveFeature: (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.ExclusiveFeatures.SetExclusiveFeature,
    });
  },
};
