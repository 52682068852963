import React, {useState, useEffect, useRef} from 'react';
import { TournamentActions } from '@Actions/Tournament';
import { useDispatch } from "react-redux";


const updateTime = (countDownDate: number, setTime: Function, timeExits: any, renderSeconds: boolean = true, dispatch :  any, tournamentId?: string) => {
    timeExits.current = setInterval(() => {

        // Get today's date and time
        calculateTime(countDownDate, setTime, timeExits, renderSeconds, dispatch, tournamentId)
    }, renderSeconds ? 1000 : 60000);
}

const calculateTime = async (countDownDate: number, setTime: Function, timeExits: any, renderSeconds: boolean = true, dispatch: any, tournamentId?: string) => {
    const now = new Date().getTime();

    // Find the distance between now and the countdown date
    const difference = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);


    setTime(`${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''} ${seconds > 0 && renderSeconds ? `${seconds}s` : ''}`)

    // If the countdown is finished, write some text
    if (difference < 0) {
        if (tournamentId) await dispatch(TournamentActions.TournamentExpire({tournamentId: tournamentId}));

        clearInterval(timeExits.current);
        setTime('Expired')
    }
}



const Counter: React.FunctionComponent<{endTime: string, tournamentId?: string, renderSeconds?:boolean, timerText?: string}> = ({endTime, tournamentId, renderSeconds, timerText}) => {
    const dispatch = useDispatch();
    const [time, setTime] = useState("");

    const timeExits = useRef()
    const countDownDate = new Date(endTime ?? "oct 25, 2022 12:37:25").getTime();
    if (!time?.includes('Expired') && !timeExits.current) {
        updateTime(countDownDate, (val: string) => setTime(val != 'Expired' ? `${time} ${val}` :  'Expired'), timeExits, renderSeconds, dispatch, tournamentId)
    }
    useEffect(()=>{
        calculateTime(countDownDate, (val: string) => setTime(val != 'Expired' ? `${time} ${val}` :  'Expired'), timeExits, renderSeconds, dispatch, tournamentId)

    },[])

    return(
            <span className='ml-1'>{time !== "Expired" ? `${timerText ? timerText : ""} ${time}` : `${timerText && timerText === "Ends in" ? '' : time}`}</span>
    )

}

export default Counter;
