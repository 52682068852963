import React from "react";
import styled from "styled-components";

import { IStaticImg } from "./StaticImage";

const Container = styled.img<IStaticImg.IProps>``;

export const StaticImage: React.FunctionComponent<IStaticImg.IProps> = (props) => {
    return (
        <Container {...props} />
    );
};
