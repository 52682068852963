import React from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { useDispatch} from "react-redux"
export const PremiumFeatureModal: React.FunctionComponent<{
  description?: string;
  detail?:string;
  width?: string;
  title?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  firstButtonCallback?: () => void;
  secondButtonCallback?: () => void;
  setPremiumFeature: (a: boolean) => void;
  setShowPaywall: (a: boolean) => void;
}> = (props) => {
  const dispatch = useDispatch();
  const description = props.description ? props.description : "";
  const detail = props.detail ? props.detail : "";
  let infoImage = "/images/ico_alert.png";
  
  const viewPort = document.getElementById("view-port-container");
  return ReactDOM.createPortal(
    <div
      onClick={() => {
        dispatch(
          ExclusiveFeaturesActions.SetExclusiveFeature(false)
        )
        // props.setPremiumFeature(false);
        props.setShowPaywall(false);
      }}
    >
      <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalContainer
            headerProps={{ backgroundColor: "#430676" }}
            width={props.width}
            backgroundColor="#430676"
            headerChild={
              <img
                src={infoImage}
                style={{ color: "#430676", width: "50px", height: "50px" }}
              />
            }
          >
            <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
              {/* @ts-ignore */}
              {props.title.length > 0 && (
                <ParagraphText
                  className="text-center font-24px"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  {props.title}
                </ParagraphText>
              )}
              {description.length > 0 && (
                <ParagraphText
                  className="text-center font-16px"
                  style={{ color: "white" }}
                >
                  {description}
                </ParagraphText>
              )}
              <ParagraphText
                  className="text-center font-18px"
                  style={{ color: "#FFC700" }}
                >
                {detail}
                </ParagraphText>
              <div className="d-flex flex-column flex- align-items-center justify-content-center">
                <div
                  style={{
                    borderRadius: "27px",
                    width: "70%",
                    height: "32px",
                    backgroundColor: "#FFC700",
                    padding: "20px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    // fontFamily:'Titillium Web'
                  }}
                  className="cursor-pointer d-flex align-items-center justify-content-center"
                  onClick={() => {
                    props.firstButtonCallback && props.firstButtonCallback();
                  }}
                >
                  {props.firstButtonText}
                </div>
                <div
                  style={{
                    borderRadius: "27px",
                    padding: "5px",
                    color: "white",
                    paddingTop: "12px",
                  }}
                  className="cursor-pointer d-flex align-items-center justify-content-center"
                  onClick={() => {
                    props.secondButtonCallback && props.secondButtonCallback();
                  }}
                >
                  {props.secondButtonText}
                </div>
              </div>
            </div>
          </ModalContainer>
        </div>
      </AnimatePopup>
    </div>,
    viewPort ? viewPort : document.body
  );
};
