// #region Global Imports
import React from "react";
import styled from "styled-components";
import {borderRadius} from "@material-ui/system";
// #endregion Global Imports

// #region Local Imports
// import { IMessageEditorInput } from "./MessageEditorInput";
// #endregion Local Imports

const _ModalContainer = styled.div<{
  width?: string, backgroundColor?: string, borderRadius?: string, margin?: string, maxHeight?: string, overflowY?: string, alignItems?: string
}>`
  width: ${({width}) => {
    return width ? width : "100%"
  }};
  border-radius: ${({borderRadius}) => {
    return borderRadius ? borderRadius : '28px'
  }};
  background: ${({backgroundColor}) => {
    return backgroundColor ? backgroundColor : "white"
  }};
  padding: 20px 25px;
  margin: ${({margin}) => {
    return margin ? margin : '20px'
  }};
  position: relative;
  display: flex;
  align-items: ${({alignItems}) => {
    return alignItems ? alignItems : 'center'
  }};
  justify-content: center;
  max-height: ${({maxHeight}) => {
    return maxHeight ? maxHeight : '100%'
  }};
  overflow-y: ${({overflowY}) => {
    return overflowY ? overflowY : 'unset'
  }};
`;

export const HeaderCircle = styled.div<{ top?: string, width?: string, height?: string, backgroundColor?: string }>`
  position: absolute;
  border-radius: 50%;
  padding: 5px;
  background: ${({backgroundColor}) => {
    return backgroundColor ? backgroundColor : "white"
  }};
  top: ${({top}) => {
    return top ? top : "-36px"
  }};
  width: ${({width}) => {
    return width ? width : "75px"
  }};
  height: ${({height}) => {
    return height ? height : "75px"
  }};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer: React.FunctionComponent<{
  headerChild?: any, headerProps?: any, width?: string, backgroundColor?: string, borderRadius?: string, margin?: string, maxHeight?: string, overflowY?: string, alignItems?: string, onClick?: (e: any) => void
}> =
  ({
     children, headerChild, headerProps, width, backgroundColor, borderRadius,
     margin, maxHeight, overflowY, alignItems, onClick
   }) => {
    return <_ModalContainer width={width} backgroundColor={backgroundColor} borderRadius={borderRadius} margin={margin}
                            maxHeight={maxHeight} overflowY={overflowY} alignItems={alignItems} onClick={onClick}>
      {headerChild && <HeaderCircle backgroundColor={backgroundColor} {...headerProps}>
        {headerChild}
      </HeaderCircle>}
      <br/>
      {children}
    </_ModalContainer>;
  };
