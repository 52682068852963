import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";
import styles from "./style.module.scss";

export const WarningModal: React.FunctionComponent<{
  onYesClick: () => void;
  onNoClick: () => void;
  warningTextElem: React.ReactNode;
  width?: string;
  backgroundColor?: string;
}> = ({ onYesClick, onNoClick, warningTextElem, width, backgroundColor }) => {
  const viewPort = document.getElementById("view-port-container");
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
      <ModalContainer
        backgroundColor={backgroundColor}
        headerChild={<img src="/images/warning_white.png" width={43} />}
        width={width}
      >
        <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
          {warningTextElem}
          <div
            className={`d-flex align-items-center text-white ${styles.justifyContentSpaceEvenly}`}
          >
            <div
              className="cursor-pointer bg-orange d-flex align-items-center justify-content-center text-white"
              style={{ borderRadius: "27px", width: "70px", height: "32px" }}
              onClick={() => {
                onNoClick();
              }}
            >
              {t("CANCEL")}
            </div>
            <div
              style={{
                borderRadius: "27px",
                width: "70px",
                height: "32px",
                border: "1px solid #A4D007",
              }}
              className="cursor-pointer d-flex align-items-center justify-content-center"
              onClick={() => {
                onYesClick();
                localStorage.removeItem("fromChat");
              }}
            >
              {t("YES_QUIT")}
            </div>
          </div>
        </div>
      </ModalContainer>
    </AnimatePopup>,
    viewPort ? viewPort : document.body
  );
};
