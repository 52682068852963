// #region Local Imports
import { ActionConsts } from "@Definitions";
// #endregion Local Imports

// #region Interface Imports
import { IAction, ILeaderBoardPage, LEADER_BOARD } from "@Interfaces";
// #endregion Interface Imports

const INITIAL_STATE: ILeaderBoardPage.IStateProps = {
    leaderBoard: {
        currentUser: <LEADER_BOARD>{},
        data: []
    }
};

export const LeaderBoardReducer = (
    state = INITIAL_STATE,
    action: IAction<ILeaderBoardPage.Actions.IMapLeaderBoard>
) => {
    switch (action.type) {
        case ActionConsts.LeaderBoard.GetLeaderBoardError: {
            return Object.assign({}, state, {
                leaderBoard: []
            });
        }
        case ActionConsts.LeaderBoard.GetLeaderBoardSuccess: {
            const { leaderBoard } = action.payload!;
            return Object.assign({}, state, {
                leaderBoard: leaderBoard
            });
        }
        default:
            return state;
    }
}
