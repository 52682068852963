import { BackgroundImage } from "@Styled/BackgroundImage";
import React from "react";
import styled, { keyframes } from "styled-components";

const slideInFromTop = keyframes`
    0% { transform: translate3d(0, -600px, 0) }
    100% { transform: translate3d(0, 0, 0) }
`

const PositionedModalContainer = styled.div<{ border?: string, borderRadius?: string, containerProps?: { right?: string, top?: string, backgroundColor?: string, left?: string } }>`
    border: ${({ border }) => { return (border ? '2.5px solid ' + border : '0px') + ';' }}
    z-index: 100;
    background: ${({ containerProps }) => containerProps && containerProps.backgroundColor ? containerProps.backgroundColor : "white"};
    position: absolute;
    right: ${({ containerProps }) => { return (containerProps && containerProps.right ? containerProps.right : '-15px') }};
    top: ${({ containerProps }) => { return (containerProps && containerProps.top ? containerProps.top : '-90px') }};
    margin: auto;
    border-radius: ${({ borderRadius }) => { return (borderRadius ? borderRadius : '6px') }};
    padding: 10px;
    min-width: 115px;
    animation-name: ${slideInFromTop};
    transform: translate3d(0, 0, 0);
    animation-duration: .3s;
    z-index: 1050;
    left:${({ containerProps }) => containerProps && containerProps.left ? containerProps.left : "unset"};
`;

export const PositionedModal: React.FunctionComponent<{ border?: string, borderRadius?: string, containerProps?: { right?: string, top?: string, backgroundColor?: string, left?: string } }>
    = ({ children, ...rest }) => {
        return <React.Fragment>
            <div className="modal-overlay" />
            <PositionedModalContainer {...rest}>
                {children}
            </PositionedModalContainer>
        </React.Fragment>
    }